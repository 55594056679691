import { getSrc, ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';

interface AddSeoHeadContentSEO {
  favouriteIcon: ImageDataLike;
  scripts: any[];
  metaData: any[];
  language: string;
}

interface CustomMetaTags {
  attributes: {
    name: string;
    value: string;
  }[];
}

export function addSeoHeadContent(seo: Partial<AddSeoHeadContentSEO> = {}, customMetaTags: CustomMetaTags[]) {
  const { metaData = [], scripts, favouriteIcon } = seo;

  let metaTags = [];
  let scriptTags = [];
  let title;
  const languageCode = getLanguageCode(seo?.language);
  const htmlAttributes = languageCode !== null ? { lang: languageCode } : null;
  const favouriteIconSrc = favouriteIcon ? getSrc(favouriteIcon) : undefined;

  // Build <meta> tags data
  metaTags = [...metaData, ...(customMetaTags || [])].map(tag => {
    const metaObj: Record<string, any> = {};
    tag.attributes.forEach((attribute: any) => {
      if (attribute.name.toString().includes('og:')) {
        metaObj.property = attribute.name;
      } else {
        metaObj.name = attribute.name;
      }
      metaObj.content = attribute.value.toString();
    });
    if (metaObj.name === 'title') {
      title = metaObj.content;
    }
    return metaObj;
  });

  scriptTags = createScriptHeadTags(scripts) || [];

  return (
    <Helmet
      meta={metaTags}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      htmlAttributes={htmlAttributes}
    >
      {title && <title>{title}</title>}
      {favouriteIconSrc && <link rel="shortcut icon" href={`https:${favouriteIconSrc.split('?')[0]}`} />}
      {scriptTags}
      <body data-body="true"></body>
    </Helmet>
  );
}

export function addDynamicBodyContent(content: any) {
  const { scripts } = content;
  createScriptBodyTags(scripts);
}

function createScriptHeadTags(scripts?: any[]) {
  const scriptTags = scripts?.map((script: any, index) => {
    const key = `script${index}`;
    let theScript;
    let runType;

    switch (script.__typename) {
      case 'ContentfulSeoInlineScript':
        if (script.scriptLocation === 'body') {
          // do nothing - we will add this when body is available.
          return false;
        } else {
          theScript = script.script.script;
        }
        return (
          <script key={key} charSet="utf-8">
            {theScript}
          </script>
        );

      case 'ContentfulSeoPageChangeScript':
        // do nothing - we will add this when body is available.
        return false;

      case 'ContentfulSeoExternalScript':
        runType = script.scriptLocation === 'body' ? 'defer' : script.runType;
        switch (runType) {
          case 'async':
            return <script key={key} charSet="utf-8" src={script.src}></script>;

          case 'defer':
            // do nothing - we will add this when body is available.
            return false;

          default:
            // add nothing
            return false;
        }

      default:
        return false;
    }
  });
  return scriptTags;
}

function getLanguageCode(lang: any) {
  let langCode = null;

  // The language will be of the form xxxxxxxxx yy where yy is the code we want to extract.
  // So we want everything right of the last space.
  if (lang !== 'no_content') {
    langCode = lang?.slice(lang.lastIndexOf(' ') + 1);
  }
  return langCode !== '' ? langCode : null;
}

function createScriptBodyTags(scripts: any[]) {
  const body = document.body;
  let topTag = document.getElementById('___gatsby');

  // Reversed because we are using insertBefore() below
  scripts.reverse().forEach(script => {
    let theScript;
    let runType;
    let scriptTag;
    const tagExists = script && document.getElementById(script.contentful_id) !== null;

    if (!tagExists && script) {
      switch (script.__typename) {
        case 'ContentfulDynamicHeightScript':
          theScript = script.script.script;
          scriptTag = makeHTMLScriptTag([{ name: 'id', value: script.contentful_id }], theScript);
          topTag = body?.insertBefore(scriptTag, topTag);
          break;

        case 'ContentfulSeoInlineScript':
          theScript = script.script.script;
          if (script.scriptLocation === 'body') {
            // insert <script charset='utf-8'>{theScript}</script>
            scriptTag = makeHTMLScriptTag([{ name: 'id', value: script.contentful_id }], theScript);
            topTag = body?.insertBefore(scriptTag, topTag);
          }
          break;

        case 'ContentfulSeoPageChangeScript':
          // insert <script charset='utf-8'>window.onSeoPageChange={theScript}</script>
          scriptTag = makeHTMLScriptTag([{ name: 'id', value: script.contentful_id }], script.script.script);
          topTag = body?.insertBefore(scriptTag, topTag);
          break;

        case 'ContentfulSeoExternalScript':
          runType = script.scriptLocation === 'body' ? 'defer' : script.runType;
          if (runType === 'defer') {
            // make <script charSet="utf-8" src={script.src}></script>
            scriptTag = makeHTMLScriptTag([
              { name: 'id', value: script.contentful_id },
              { name: 'src', value: script.src },
            ]);
            topTag = body?.insertBefore(scriptTag, topTag);
          } else {
            // do nothing - already added in head.
          }
          break;

        case 'ContentfulSeoInteractionScript':
          // make <script charSet="utf-8" src={script.src}></script>
          theScript = script.script.script;
          scriptTag = makeHTMLScriptTag([{ name: 'id', value: script.contentful_id }], theScript);
          // topTag = body?.insertBefore(scriptTag, topTag);
          break;

        default:
        // do nothing
      }
    }
  });
}

function makeHTMLScriptTag(attributes: any[], code = null) {
  const tag = document.createElement('script');
  tag.setAttribute('charSet', 'utf-8');
  attributes.forEach(itemPair => {
    tag.setAttribute(itemPair.name, itemPair.value);
  });
  if (code) {
    const textNode = document.createTextNode(code);
    tag.appendChild(textNode);
  }
  return tag;
}
