import styled from 'styled-components';
import Container from '@/components/Global/Container';
import { rem } from 'polished';

const SWBasketStyles = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${rem('10px')};
`;

export const SWBasketContainer = styled(Container)``;

// BASKET HEADER
export const ContainerHeaderStyles = (width: number) => ({
  backgroundColor: 'primary2.default',
  pt: width <= 768 ? rem('32px') : rem('52px'),
  pb: width <= 768 ? rem('32px') : rem('44px'),
});

export const SWBasketHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > h3,
  > p {
    color: var(--mineShaft);
  }

  p {
    width: auto;
    text-align: right;
  }

  > h3 {
    letter-spacing: ${rem('-0.3px')};
    margin: 0 auto;
    @media (min-width: ${rem('992px')}) {
      margin: 0 auto;
    }
  }
`;

// BASKET SUMMARY
export const SWBasketSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${rem('40px')};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    align-items: flex-start;
  }
`;

export const SWBasketSummaryInner = styled.div`
  padding: ${rem('30px')} 0;
  width: 100%;

  > h2 {
    color: var(--mineShaft);
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: ${rem('30px')};
    }
  }
`;

// EMPTY BASKET
export const EmptyBasket = styled.div`
  display: flex;
  background: #ffffff;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: ${rem('6px')};
  padding: ${rem('40px')} ${rem('20px')};
  margin: ${rem('40px')} ${rem('0px')};

  > h3,
  > p {
    margin: ${rem('3px')} 0;
    color: var(--mineShaft);
  }
`;

// BASKET TOTAL
export const SWBasketTotal = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${rem('30px')} 0;
  border-top: ${rem('1px')} solid #33333380;
  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    flex-direction: column;
  }
`;

export const SWBakestTotalPrices = styled.div`
  display: flex;
  > * {
    margin-right: ${rem('10px')};
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    width: 100%;
    justify-content: flex-end;
    align-self: flex-end;

    [class*='HeadingPrice'] h4 {
      font-size: ${rem('24px')};
    }
  }
`;

// BASKET ACTION BUTTONS

export const WrapperBasketButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rem('30px')} 0;

  #basket-button-complete-purchase {
    width: 100%;
    max-width: ${rem('380px')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const BasketButtonsInner = styled.div`
  display: flex;
  flex-direction: column;
  a,
  button {
    margin-bottom: ${rem('10px')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
    a,
    button {
      margin-right: ${rem('16px')};
      margin-bottom: 0;
    }
  }
`;

export default SWBasketStyles;
