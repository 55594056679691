import styled from '@emotion/styled';
import { rem } from 'polished';
import { Flex } from '@chakra-ui/react';

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

// QuantityInput styles

export const IconButtonWrapper = styled(Flex)`
  z-index: 1;

  button {
    border-radius: ${rem('50px')};
    width: ${rem('36px')};
    height: ${rem('36px')};
  }

  svg {
    width: ${rem('24px')};
    height: ${rem('24px')};
  }
  [class*='IconButtonstyle__IconButton-sc'] {
    background: transparent;

    [class*='Iconstyle__Icon-sc'] * {
      stroke: #007c92;
    }
  }
`;

export const InputWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;

  [class*='TextInputstyle__TextInput-sc'] {
    color: var(--vodafone-color-mineShaft);
    font-size: ${rem('20px')};
    height: ${rem('50px')};
    line-height: ${rem('23px')};
    padding: ${rem('12px')} ${rem('4px')};
    text-align: center;
    width: ${rem('72px')};
    margin: 0 ${rem('12px')};
  }
`;
