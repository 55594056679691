import Heading from '@vfuk/core-heading';
import React from 'react';
import { PHPriceDescription, ProductHeaderContainer } from './SWComparisonTable.styles';

interface Product {
  id: string;
  name: string;
  price: string | number;
}

export const ProductHeader: React.FC<Product> = ({ id, name, price }) => {
  return (
    <ProductHeaderContainer>
      <Heading size={1} text={name} weight={4} />
      <Heading size={2} text={typeof price !== 'string' ? price.toString() : price} noMargin />
      <PHPriceDescription>Paid per user per month (ex Vat)</PHPriceDescription>
    </ProductHeaderContainer>
  );
};
