import { useState, useEffect } from 'react';
import { CheckboxList, CheckboxListProps } from '@vfuk/core-checkbox-list';
import { TextAreaInputWithLabel, TextAreaInputWithLabelProps } from '@vfuk/core-text-area-input-with-label';
import { TextInputWithLabel, TextInputWithLabelProps } from '@vfuk/core-text-input-with-label';
import { InputContainer, InputLabel } from './Form.styles';
import { SelectInput, SelectInputProps } from '@vfuk/core-select-input';

import {
  FormEventChange,
  FormEvents,
  FormItem,
  FormProps,
  IFormInput,
  IFormState,
  IValidationRules,
  IValidationType,
  PartialFormItem,
} from './Form.types';
import RadioButtonList from '@vfuk/core-radio-button-list/dist/RadioButtonList';

const Form = ({
  formInputs,
  formValidation,
  formSubmit,
  onInputChange,
  onInputBlur,
  shouldHaveFullWidthOnMobile = true,
}: FormProps) => {
  const initialIFormState = formInputs.reduce((acc, input) => {
    acc[input.label] = {
      value: input.customValue || '',
      status: input.disabled ? 'disabled' : undefined,
      statusMessage: '',
      required: input?.requiredField || false,
      valid: false,
    };
    return acc;
  }, {} as IFormState);

  const [form, setForm] = useState<IFormState>(initialIFormState);

  const handleForm = (e: FormEvents, values: PartialFormItem, customName?: string) => {
    const { name } = e.target;

    setForm(prev => ({
      ...prev,
      [name as keyof IFormState]: {
        ...prev[name as keyof IFormState],
        ...values,
      },
    }));
  };

  const handleInputChange = (e: FormEvents, customValue?: string | boolean, customName?: string) => {
    const { value } = e.target;
    if (e.type == 'change' && value != undefined && value != '') {
      handleValidChange(e, true);
    }
    if (onInputChange) onInputChange(e);
    handleForm(e, { value: customValue ?? value });

  };

  const handleStatusChange = (e: FormEvents, status: 'success' | 'error', statusMessage = '') => {
    handleForm(e, { status, statusMessage });
  };

  const handleValidChange = (e: FormEvents, valid: boolean) => {
    handleForm(e, { valid });
  };

  const lenghtValidation = (value: string, size: number, e: FormEvents, setStatus?: boolean) => {
    if (value.length > size) {
      if (setStatus) handleStatusChange(e, 'success');

      handleValidChange(e, true);
    } else {
      if (setStatus) handleStatusChange(e, 'error', `${e.target.name} should have more than ${size} characters.`);
      handleValidChange(e, false);
    }
  };

  const emailValidation = (email: string, e: FormEvents, setStatus?: boolean) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const isValidEmail = regex.test(email);

    if (isValidEmail) {
      if (setStatus) handleStatusChange(e, 'success');
      handleValidChange(e, true);
    } else {
      if (setStatus) handleStatusChange(e, 'error', 'Invalid email format.');
      handleValidChange(e, false);
    }
  };

  const phoneValidation = (phone: string, code: string | undefined, e: FormEvents, setStatus?: boolean) => {
    let isValidPhone = false;

    if (code) {
      const codeNumber = code.replace('+', '');
      const regex = new RegExp(`^\\+${codeNumber}( )?[1-9]( )?[0-9]{6,9}$`);

      isValidPhone = regex.test(phone);
    }

    if (isValidPhone) {
      if (setStatus) handleStatusChange(e, 'success');
      handleValidChange(e, true);
    } else {
      if (setStatus) handleStatusChange(e, 'error', 'Invalid phone number format.');
      handleValidChange(e, false);
    }
  };

  const checkboxValidation = (checked: boolean, formItem: FormItem, e: FormEvents) => {
    if (formItem.required) {
      if (checked === true) {
        handleValidChange(e, true);
      } else {
        handleValidChange(e, false);
      }
    } else {
      handleValidChange(e, true);
    }
  };

  const handleInputBlur = (e: FormEvents, validationType: IValidationType, validationRules: IValidationRules) => {
    handleValidation(e, validationType, validationRules, true);
    if (onInputBlur) onInputBlur(e);
  };

  const handleValidation = (
    e: FormEvents,
    validationType: IValidationType,
    validationRules: IValidationRules,
    setStatus: boolean = true
  ) => {
    const { validationSize = 3, phoneValidationCode } = validationRules;
    const { value, name } = e.target;
    const { checked } = e.target as FormEventChange<HTMLInputElement>['target'];

    switch (validationType) {
      case 'length':
        lenghtValidation(value, validationSize, e, setStatus);
        break;
      case 'email':
        emailValidation(value, e, setStatus);
        break;
      case 'phone':
        phoneValidation(value, phoneValidationCode, e, setStatus);
        break;
      case 'checkbox':
        checkboxValidation(checked, form[name], e);
        break;
      default:
        if (form[name]?.required) {
          if (value !== '') {
            handleValidChange(e, true);
          } else {
            handleValidChange(e, false);
          }
        } else {
          handleValidChange(e, true);
        }
        break;
    }
  };

  const handleFormValidation = () => {
    if (form) {
      const requiredItems = Object.values(form).filter(item => item.required);

      if (requiredItems.length == 0) {
        return true;
      }
      const allValuesFilled = requiredItems.every(item => item.valid);

      return allValuesFilled;
    }

    return false;
  };

  const renderInput = (input: IFormInput) => {
    const targetFormInput = form[input.label];
    const commonProps = {
      key: `${input.label}-input`,
      id: `${input.label}Input`,
      state: input.disabled ? 'disabled' : form[input.label]?.status,
      fieldWrapper: {
        helpText: input.helpText,
        label: `${input.label} ${targetFormInput?.required ? '*' : ''} `,
        stateText: targetFormInput?.statusMessage,
        width: (input.fullWidth && 'full') as TextInputWithLabelProps['fieldWrapper']['width'],
      },
    };

    switch (input.inputType) {
      case 'inputField': {
        const textInputProps: TextInputWithLabelProps = {
          ...commonProps,
          textInput: {
            id: `${input.label}Text`,
            name: input.label,
            placeholder: input.placeholder,
            value: targetFormInput?.value as string,
            onChange: e => handleInputChange(e, undefined),
            onBlur: e =>
              handleInputBlur(e, input.validationType, {
                validationSize: input.lengthValidationSize,
                phoneValidationCode: input.phoneValidationCode,
              }),
          },
        };

        return <TextInputWithLabel {...textInputProps} />;
      }
      case 'select': {
        const selectInputProps: SelectInputProps = {
          ...commonProps,
          id: `${input.label}Dropdown`,
          options: input.options || [],
          name: input.label,
          placeholder: input.placeholder,
          value: form[input.label].value as string,
          onChange: handleInputChange,
          onBlur: e =>
            handleInputBlur(e, input.validationType, {
              validationSize: input.lengthValidationSize,
              phoneValidationCode: input.phoneValidationCode,
            }),
        };

        return (
          <>
            <InputLabel labelColor={input.labelColor} labelType={input.labelType}>
              {input.label}
            </InputLabel>
            <SelectInput {...selectInputProps} />
          </>
        );
      }
      case 'inputArea': {
        const textareaInputProps: TextAreaInputWithLabelProps = {
          ...commonProps,
          state: commonProps.state as TextAreaInputWithLabelProps['state'],
          textAreaInput: {
            id: `${input.label}Text`,
            rows: 3,
            name: input.label,
            placeholder: input.placeholder,
            value: form[input.label].value as string,
            onChange: handleInputChange,
            onBlur: e => handleInputBlur(e, input.validationType, { validationSize: input.lengthValidationSize }),
          },
        };

        return <TextAreaInputWithLabel {...textareaInputProps} />;
      }
      case 'checkbox': {
        const checkboxOptions = input.checkboxOptions?.map(option => {
          return { ...option, checked: form[input.label].value == option.value };
        });
        const checkboxProps: CheckboxListProps = {
          fieldWrapper: { label: '', showLabel: false },
          orientation: checkboxOptions ? 'horizontal' : 'vertical',
          checkboxes: {
            groupName: input.label,
            id: `${input.label}Checkbox`,
            onChange: (e: FormEventChange<HTMLInputElement>) => {
              const sameValue = form[input.label].value == e.currentTarget.value;
              if (sameValue) {
                handleInputChange(e, 'false');
                handleValidation(e, 'checkbox', {});
                return;
              }
              handleInputChange(e, checkboxOptions ? e.currentTarget.value : e.currentTarget.checked);
              handleValidation(e, 'checkbox', {});
            },
            items: checkboxOptions || [
              {
                label: input.label,
                value: 'agree',
                checked: form[input.label].value as boolean,
              },
            ],
          },
        };
        return <CheckboxList {...checkboxProps} />;
      }
      case 'radioList': {
        return (
          <RadioButtonList
            {...commonProps}
            state={commonProps.state as 'error' | 'disabled' | undefined}
            orientation="horizontal"
            radioButtons={{
              groupName: input.name,
              id: input.name + input.label,
              onChange: e => {
                handleInputChange(e, undefined);

              },
              items: input.radioOptions || [],
              checked: form[input.name]?.value as string,
            }}
          />
        );
      }
    }
  };

  useEffect(() => {
    if (formValidation) {
      formValidation(handleFormValidation());
    }

    if (formSubmit) {
      formSubmit(form);
    }
  }, [form]);

  useEffect(() => {
    formInputs.forEach(input => {
      setForm(prevForm => ({
        ...prevForm,
        [input.label]: {
          ...prevForm[input.label],
          hidden: input.hidden,
          ...(input.customValue !== undefined ? { value: input.customValue } : {}),
          status: input.disabled ? 'disabled' : prevForm[input.label]?.status,
          required: input.requiredField,
        },
      }));
      if (input.customValue !== undefined) {
        handleValidation(
          {
            target: {
              value: typeof input.customValue == 'string' && input.customValue,
              name: input.label,
              checked: typeof input.customValue == 'boolean' && input.customValue,
            },
          } as FormEvents,
          input.validationType,
          {
            phoneValidationCode: input.phoneValidationCode,
            validationSize: input.lengthValidationSize,
          },
          false
        );
      }
    });
  }, [formInputs]);

  return (
    <>
      {formInputs.map(input => (
        <InputContainer
          fullWidth={input.fullWidth}
          labelColor={input.labelColor}
          labelType={input.labelType}
          customWidth={input.customWidth}
          disabled={input.disabled}
          hidden={input.hidden}

          fullWidthOnMobile={shouldHaveFullWidthOnMobile}
        >
          {renderInput(input)}
        </InputContainer>
      ))}
    </>
  );
};

export default Form;
