import { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { isServer } from '@/helpers/isServer';
import { persistor, store } from '@/store';

type ReduxWrapperProps = {
  children: React.ReactNode | React.ReactNode[];
};

const ReduxWrapper: FC<ReduxWrapperProps> = ({ children }) => {
  return isServer ? (
    <Provider store={store} data-testid="main-layout">
      {children}
    </Provider>
  ) : (
    <Provider store={store} data-testid="main-layout">
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default ReduxWrapper;
