import { rem } from 'polished';
import styled from 'styled-components';

type SectionSWFeatureOverviewCardWrapperProps = {
  backgroundImageSrc?: string;
  backgroundColor?: string;
};

export const SectionSWFeatureOverviewCardWrapper = styled.div<SectionSWFeatureOverviewCardWrapperProps>`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ backgroundImageSrc, backgroundColor, theme }) =>
    backgroundImageSrc
      ? `background-image: url(${backgroundImageSrc});`
      : `background: ${backgroundColor || theme.color.primary2.default};`}

  h2[class*='Headingstyle__Heading'] {
    color: ${({ backgroundImageSrc }: any) => (backgroundImageSrc ? 'white' : `var(--mineShaft)`)};
    letter-spacing: ${rem('-0.4px')};
    margin-bottom: 0;
    padding: ${rem('50px')} 0 ${rem('70px')} 0;
    text-align: center;
    font-family: 'VodafoneLight';
    font-style: normal;
    font-weight: 400;
    font-size: ${rem('40px')};
    line-height: ${rem('44px')};
    text-align: center;
  }
  h3[class*='Headingstyle__Heading'] {
    font-style: normal;
    font-weight: 400;
    font-size: ${rem('28px')};
    line-height: ${rem('32px')};
  }
  h3[class*='Headingstyle__Heading'],
  p {
    color: ${({ backgroundImageSrc }: any) => (backgroundImageSrc ? 'white' : `black`)};
    font-family: 'vodafoneRegular';
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: ${rem('18px')};
    line-height: ${rem('21px')};
  }
`;

export const SectionFeatureOverviewWrapper = styled.section`
  height: inherit;
  width: 80%;
  margin: 0 auto ${rem('50px')};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: ${rem('980px')};
    margin: 0 auto ${rem('50px')};
  }
  svg {
    width: ${rem('50px')};
    height: ${rem('50px')};
  }
  h3[class*='Headingstyle__Heading'] {
    margin-bottom: ${rem('8px')};
  }
`;

export const FeatureOverviewCardsWrapper = styled.div`
  display: grid;
  row-gap: ${rem('20px')};
  padding: 0 ${rem('15px')};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${rem('20px')};
    row-gap: ${rem('20px')};
    justify-items: center;
    max-width: ${rem('1180px')};
    padding: 0 ${rem('16px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0;
  }
`;

export const SWFeatureOverviewCard = styled.div``;
