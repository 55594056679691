import styled from 'styled-components';

export const FilterFlyoutWrapper = styled.div`
  [class*='FilterFlyoutstyle__FilterFlyout'] {
    width: 100%;
    position: absolute;
    top: 0rem;
    background: var(--white);
    height: 100%;
    z-index: -1;
    padding-right: 1.9rem;
    [class*='FilterFlyoutstyle__Sections'] {
      padding: 3rem 1.1rem 1rem 0;
      bottom: -2.5rem;
      [class*='FilterSectionstyle__FilterSection'] {
        padding-bottom: 0;
        [class*='CollapsibleContainerHeaderstyle__CollapsibleContainerHeader'],
        [class*='FieldWrapperstyle__InputWrapper'] {
          padding-left: 0.188rem;
        }
        [class*='CollapsibleContainerHeaderstyle__CollapsibleContainerHeader'] {
          padding-right: 0.188rem;
          [class*='CollapsibleContainerHeaderstyle__Children'] {
            font-size: 1rem;
            line-height: 0.938rem;
            color: var(--mineShaft);
          }
        }
        [class*='Checkboxstyle__CheckboxLabel'] {
          margin: 0.5rem 0;
          display: inline-flex;
        }
        [class*='Radiostyle__RadioWrapper'],
        [class*='ListItemstyle__ListItem'] {
          [class*='Checkboxstyle__CheckboxLabel'],
          [class*='Radiostyle__LabelText'] {
            font-size: 1rem;
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                theme,
              }) => theme.breakpoints.lg - 1}px) {
              font-size: 0.875rem;
            }
            color: var(--mineShaft);
            [class*='Checkboxstyle__Checkbox'] {
              width: 0.813rem;
              height: 0.813rem;
            }
          }
        }
        [class*='Radiostyle__RadioWrapper'] {
          [class*='Radiostyle__LabelText'] {
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                theme,
              }) => theme.breakpoints.lg - 1}px) {
              font-size: 0.875rem;
            }
            ::before,
            ::after {
              width: 0.813rem;
              height: 0.813rem;
              left: 0;
            }
          }
        }
      }
    }
    [class*='FilterFlyoutstyle__Footer'] {
      background: var(--white);
      width: 100%;
      margin-bottom: 1rem;
    }
    [class*='FilterFlyoutstyle__Header'],
    [class*='FilterSectionstyle__FilterSection'] {
      border: 0;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    [class*='FilterFlyoutstyle__FilterFlyout'] {
      border-top: 3.75rem var(--white) solid;
      padding-right: 1.6rem;
      [class*='FilterFlyoutstyle__Sections'] {
        padding: 0 1.1rem 1rem 0;
      }
    }
  }
`;
