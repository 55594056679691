import { Flex, Stack } from '@chakra-ui/react';
import { rem } from 'polished';
import styled from '@emotion/styled';

import { TButtonWrapper } from './InfoAndCta.types';

export const InfoAndCtaWrapper = styled(Flex)``;
export const InfoAndCtaInner = styled(Stack)``;

export const InputWrapper = styled(Flex)`
  align-items: center;
  position: relative;

  [class*='TextInputstyle__TextInput-sc'] {
    color: var(--vodafone-color-mineShaft);
    font-size: ${rem('20px')};
    height: ${rem('50px')};
    line-height: ${rem('23px')};
    padding: ${rem('12px')} ${rem('4px')};
    text-align: center;
    width: 100%;
  }
`;

export const IconButtonWrapper = styled(Flex)`
  position: absolute;
  z-index: 1;

  [class*='IconButtonstyle__IconButton-sc'] {
    background: transparent;

    [class*='Iconstyle__Icon-sc'] * {
      stroke: #007c92;
    }

    svg {
      width: ${rem('24px')};
      height: ${rem('24px')};
    }
  }
`;

export const ButtonWrapper = styled.div<TButtonWrapper>`
  display: block;
  width: 100%;
  color: var(--white);

  [class*='Buttonstyle__Button-sc'] {
    background: ${({ inBasket, buttonsAction, theme, buttonBackgroundColor }) => {
      if (buttonBackgroundColor) return buttonBackgroundColor;

      if (inBasket && buttonsAction === 'updateBasketWithQty') {
        return '#007c92';
      }
      if (!inBasket) {
        return theme.color.primary1.default;
      }
      return theme.color.primary1.default;
    }};
    color: ${({ buttonTextColor }) => buttonTextColor || 'white'};
    font-size: ${rem('20px')};
    height: ${rem('50px')};
    letter-spacing: 0;
    line-height: ${rem('22px')};
    min-width: ${({ buttonsAction }) => {
      if (buttonsAction === 'addToBasketWithQty' || buttonsAction === 'updateBasketWithQty') {
        return rem('130px');
      }
      return rem('192px');
    }};
    padding: ${rem('16px')} 0;
    width: 100%;
  }
`;
