import ButtonWithIcon from '@vfuk/core-button-with-icon';
import { Filter, FilterAndSortProps } from '@vfuk/core-filter-and-sort/dist/FilterAndSort.types';
import FilterFlyout from '@vfuk/core-filter-flyout';
import Modal from '@vfuk/core-modal';
import React, { FC, useState } from 'react';

import * as Styles from './SWFilterFlyout.styles';
import { SWFilterFlyoutProps } from './SWFilterFlyout.types';

const SWFilterFlyout: FC<SWFilterFlyoutProps> = ({ ...props }: SWFilterFlyoutProps) => {
  const filtersData = props.filters.map(
    ({ filterId, filterTitle, filterType, filterGroupName, subText, checked, items }) => ({
      ...(subText && { subText }),
      id: filterId,
      title: filterTitle,
      type: filterType,
      ...(['radio', 'checkbox'].includes(filterType) && { options: { groupName: filterGroupName } }),
      ...('radio' === filterType && { checked }),
      items,
    })
  ) as FilterAndSortProps['filters'];

  const [filters, setFilters] = useState<FilterAndSortProps['filters']>(filtersData);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnChange = (id: string, updatedFilter: Filter) => {
    const updatedFilters = filters.map(filter => {
      if (id === filter.id) return { ...updatedFilter };
      return filter;
    });
    setFilters(updatedFilters);
  };

  const handleOnClear = () => {
    const updatedFilters = filters.map(filter => ({
      ...filter,
      ...('toggle' === filter.type && { isActive: false }),
      ...('radio' === filter.type && { checked: undefined }),
      ...('checkbox' === filter.type && {
        items: filter.items.map(item => ({ ...item, checked: undefined })),
      }),
    })) as Filter[];

    setFilters(updatedFilters);
  };

  return (
    <>
      <ButtonWithIcon
        icon={{
          name: 'filter',
          justify: 'left',
        }}
        text="Filter"
        appearance="alt1"
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <Modal
        isOpen={isOpen}
        onCloseCb={() => {
          setIsOpen(false);
        }}
        type="flyout"
        side="left"
        srName="Filters"
      >
        <Styles.FilterFlyoutWrapper>
          <FilterFlyout
            title=""
            subText=""
            filters={filters}
            onChange={handleOnChange}
            onClose={() => {
              setIsOpen(false);
            }}
            onClear={handleOnClear}
          />
        </Styles.FilterFlyoutWrapper>
      </Modal>
    </>
  );
};

export default SWFilterFlyout;
