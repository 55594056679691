import { FC, useEffect, useState } from 'react';
import { ButtonWrapper, ContentWrapper, RegisterModalWrapper, RegisterTitle, Spacer } from './RegisterModal.styles';
import StepperWithIcon from '../../maac-ui/StepperWithIcon/StepperWithIcon';
import Icon from '@vfuk/core-icon';
import { ContactDetails } from './RegisterForms/ContactDetails';
import { CompanyDetails } from './RegisterForms/CompanyDetails';
import Button from '@vfuk/core-button';
import SuccessPage from './SuccessPage';
import { FormStateProvider } from './RegisterForms/FormContext';
import { ButtonProps } from '@source-web/button';
import { IFormProps } from './RegisterForms/RegisterForms.types';


export type RegisterModalProps = {
  id: string;
  name?: string;
  __typename: 'ContentfulNewAccountRegistrationFlow';
};

const RegisterModal: FC<RegisterModalProps> = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [contactFormValid, setContactFormValid] = useState<boolean>(false);
  const [companyFormValid, setCompanyFormValid] = useState<boolean>(false);

  const handleFormSubmit = () => {
    setCurrentStep(2);
  };
  if (currentStep == 2) {
    return <SuccessPage />;
  }

  const validCheck = (isValid: boolean, formType: 'contact' | 'company' | 'billing') => {
    if (formType == 'contact') {
      setContactFormValid(isValid);
    } else if (formType == 'company') {
      setCompanyFormValid(isValid);
    }
  };

  const getButtonStatus = () => {
    if (currentStep == 0) {
      return (contactFormValid ? undefined : 'disabled') as ButtonProps['state'];
    } else {
      return (companyFormValid ? undefined : 'disabled') as ButtonProps['state'];
    }
  };

  return (
    <FormStateProvider>
      <RegisterModalWrapper>
        <ContentWrapper>
          <RegisterTitle>New Account Registration</RegisterTitle>
          <Spacer h={50} />
          <StepperWithIcon
            steps={[
              { icon: { group: 'system', name: 'business' }, label: 'Contact details' },
              { icon: { group: 'system', name: 'bag' }, label: 'Company details' },
            ]}
            activeStep={currentStep}
            onStepClick={stepNumber => {
              setCurrentStep(stepNumber);
            }}
          />
          {currentStep == 0 ? (
            <ContactDetails validCallback={validCheck} />
          ) : (
            <CompanyDetails validCallback={validCheck} />
          )}
          <ButtonWrapper>
            <Button
              appearance="primary"
              width="full"
              state={getButtonStatus()}
              text={currentStep == 0 ? 'Continue' : 'Register'}
              onClick={
                currentStep == 0
                  ? () => {
                      setCurrentStep(1);
                    }
                  : () => {
                      handleFormSubmit();
                    }
              }
            />
          </ButtonWrapper>
        </ContentWrapper>
      </RegisterModalWrapper>
    </FormStateProvider>
  );
};

export default RegisterModal;
