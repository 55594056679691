// FormContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { IFormInput } from '@/components/pageBuilder/Elements/Form/Form.types';
import { contactDetailsFormInputs } from './FormConfigs/ContactFormConfig';
import { companyDetailsFormConfig, billingDetailsForm } from './FormConfigs/CompanyFormConfig';

interface FormStateContextProps {
  contactForm: IFormInput[];
  companyForm: IFormInput[];
  billingForm: IFormInput[];
  setContactForm: (formInputs: IFormInput[]) => void;
  setCompanyForm: (formInputs: IFormInput[]) => void;
  setBillingForm: (formInputs: IFormInput[]) => void;
}

export const FormStateContext = createContext<FormStateContextProps | undefined>(undefined);

export const useFormState = () => {
  const context = useContext(FormStateContext);
  if (!context) {
    throw new Error('useFormState must be used within a FormStateProvider');
  }
  return context;
};

export const FormStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [contactForm, setContactForm] = useState<IFormInput[]>(contactDetailsFormInputs);
  const [companyForm, setCompanyForm] = useState<IFormInput[]>(companyDetailsFormConfig);
  const [billingForm, setBillingForm] = useState<IFormInput[]>(billingDetailsForm);

  return (
    <FormStateContext.Provider
      value={{ contactForm, companyForm, billingForm, setContactForm, setCompanyForm, setBillingForm }}
    >
      {children}
    </FormStateContext.Provider>
  );
};
