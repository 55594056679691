const typography = {
  fonts: {
    body: 'Vodafone, sans-serif',
    heading: 'Vodafone, sans-serif',
  },
  fontSizes: {
    7: '1.75rem',
    10: '2.5rem',
  },
};

export default typography;
