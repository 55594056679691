import { useTranslation } from 'react-i18next';
import { withPrefix } from 'gatsby';
import { useMemo } from 'react';

import { useAppSelector, setCurrentStep, useAppDispatch, UserBasketItemProdBundle } from '@/store';
import usingOpcoUrl from '@/helpers/prefixHelper';
import { OPCOOptions } from '@/types/Services.types';

import BasketSummary from '../../Elements/BasketSummary';
import { Unit } from '../../Elements/BasketSummary/BasketSummary.types';
import { SectionBasketSummaryProps } from './SectionBasketSummary.types';

const OPCO = process.env.GATSBY_OPCO;

const SectionBasketSummary = ({ basketSummaryUrl, hasNavigationButton = false }: SectionBasketSummaryProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const basketURL = t('basketURL');

  const { currentStep, maxSteps } = useAppSelector(({ userConfigureBundle }) => userConfigureBundle);

  const { data: userBasket } = useAppSelector(({ userBasket }) => userBasket);
  const { user } = useAppSelector(({ userSession }) => userSession);

  const isOpen = useMemo(() => {
    return Boolean(user && userBasket.items.length > 0);
  }, [user, userBasket]);

  const currencySymbol = useMemo(() => {
    if (userBasket?.items.length) {
      const [item] = userBasket.items as UserBasketItemProdBundle[];
      return item.detailAndPricing?.currencySymbol as Unit;
    }
    return undefined;
  }, [userBasket]);

  const handleNextClickButton = () => {
    const nextStep = currentStep + 1;
    if (nextStep <= maxSteps) {
      dispatch(setCurrentStep(nextStep));
    }
  };

  return (
    <BasketSummary
      isOpen={isOpen}
      title={t('totalCost')}
      priceSuffix={t('priceSuffix')}
      monthlyPricePrefix={t('monthly')}
      upfrontPricePrefix={t('upfront')}
      price={{
        monthly: userBasket.total?.monthly,
        upfront: OPCO !== OPCOOptions.PORTUGAL && userBasket.total?.upfront,
      }}
      buttons={{
        summaryButton: {
          href: basketSummaryUrl ? usingOpcoUrl(withPrefix(basketSummaryUrl)) : usingOpcoUrl(withPrefix(basketURL)),
          text: currentStep < maxSteps ? t('skipToBasket') : t('goToBasket'),
        },
        secondaryButton:
          hasNavigationButton && currentStep < maxSteps
            ? {
                text: t('nextOption'),
                onClick: handleNextClickButton,
              }
            : undefined,
      }}
      unit={currencySymbol}
    />
  );
};

export default SectionBasketSummary;
