import { rem } from 'polished';
import styled from 'styled-components';

export const ShortBannerContainer = styled.div`
  height: ${rem('108px')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(225deg, #f40000 0%, #660000 100%);
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 60%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 90%;
  }
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

export const Title = styled.p<{ textColor: string }>`
  font-size: ${rem('28px')};
  color: ${props => props.textColor};
  margin: 0;
  z-index: 3;
  text-align: center;
  p {
    font-size: ${rem('28px')};
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('18px')};
    p {
      font-size: ${rem('18px')};
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: ${rem('220px')};
  z-index: 3;
  margin: ${rem('10px')} 0;
  [class*='Buttonstyle__Button-sc'] {
    width: 100%;
    padding: ${rem('16px')} 0;
    font-size: ${rem('20px')};
    line-height: ${rem('24px')};
    height: ${rem('50px')};
  }
`;
