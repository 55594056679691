import { FC, useState } from 'react';
import Button from '@vfuk/core-button';
import {
  AbsoluteIcon,
  RedCheckmarkWrapper,
  SuccessPageButtonWrapper,
  SuccessPageSubtitle,
  SuccessPageTitle,
  SuccessPageWrapper,
} from './SuccessPage.styles';
import { CheckmarkTexture, RedCheckmark } from './svgs/RedCheckmark';
import { Spacer } from './RegisterModal.styles';

const SuccessPage: FC<any> = () => {
  return (
    <SuccessPageWrapper>
      <RedCheckmarkImage />
      <Spacer h={60} />
      <SuccessPageTitle>Thanks for your registration</SuccessPageTitle>
      <Spacer h={40} />
      <SuccessPageSubtitle>
        Thank you for your registration. An email has been sent to you with the confirmation
      </SuccessPageSubtitle>
      <Spacer h={100} />
      <SuccessPageButtonWrapper>
        <Button appearance="primary" width="full" text={'Login to continue with your purchase'} onClick={() => {}} />
      </SuccessPageButtonWrapper>
    </SuccessPageWrapper>
  );
};

const RedCheckmarkImage = () => {
  return (
    <RedCheckmarkWrapper>
      <AbsoluteIcon>
        <CheckmarkTexture />
      </AbsoluteIcon>
      <AbsoluteIcon x={15} y={1}>
        <RedCheckmark />
      </AbsoluteIcon>
    </RedCheckmarkWrapper>
  );
};

export default SuccessPage;
