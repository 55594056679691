import { Box, Flex, Heading } from '@chakra-ui/react';
import Image from '@vfuk/core-image';

import { rem } from 'polished';
import styled from '@emotion/styled';

export const OptionWrapper = styled(Flex)``;
export const OptionHeader = styled(Box)``;
export const OptionHeading = styled(Heading)``;
export const ButtonsWrapper = styled(Flex)``;

export const CtaButton = styled(ButtonsWrapper)`
  width: 100%;
`;

export const contentOptionStyles = {
  align: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  boxShadow: `0 ${rem('2px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.16)`,
  p: 5,
  w: { base: rem('345px'), md: rem('244px'), lg: rem('260px') },
  h: rem('324px'),
  borderRadius: '6px',
};

export const boxOptionStyles = {
  height: rem('48px'),
  mb: { base: rem('50px'), md: 'auto' },
  mt: { base: rem('6px') },
  display: 'flex',
};

export const iconTitleOptionStyles = (hasBackgroundIcon: boolean, hasIcon?: boolean) => ({
  fontSize: { base: 'xl' },
  lineHeight: rem('22px'),
  alignSelf: 'center',
  mb: 0,
  ml: (hasIcon && { base: rem('10px') }) || (hasBackgroundIcon && { base: rem('58px') }) || 0,
});

export const IconImage = styled(Image)``;

export const IconImageWrapper = styled.div`
  flex-shrink: 0;
`;

export const OptionText = styled(Heading)`
  p {
    font-size: ${rem('18px')};
    line-height: ${rem('22px')};
    font-weight: normal;
  }
`;

export const titleOptionStyles = {
  mb: 0,
  sx: { p: { m: 0 } },
  display: 'flex',
  flexBasis: '50%',
};
