import { rem } from 'polished';
import styled from 'styled-components';

type FeatureCardWrapperProps = {
  pill?: string;
  gridLayout?: boolean;
};

type FeatureCardProductImageWrapperProps = {
  gridLayout?: boolean;
};

export const FeatureCardImagesAndPriceWrapper = styled.div`
  position: relative;
  z-index: 2;

  [class*='Spacingstyle__Spacing-sc'] {
    left: 6;
  }

  [class*='Spacingstyle__Spacing-sc']:nth-last-child() {
    left: 0;
  }

  img {
    max-width: fit-content;
  }
`;

export const FeatureCardProductImageWrapper = styled.div<FeatureCardProductImageWrapperProps>`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: flex;
    justify-content: space-around;
    ${({ gridLayout }) => {
      if (!gridLayout) {
        return `
        flex-direction: column; 
        justify-content: flex-start; 
        padding-top: ${rem('70px')};
        `;
      } else {
        return `margin-top: ${rem('24px')};`;
      }
    }}
  }
`;

export const SubHeadWrap = styled.div<FeatureCardProductImageWrapperProps>`
  p {
    margin: 0;
  }
  ul {
    padding-left: ${rem('16px')};
  }
`;

export const HeadingWrap = styled.div<FeatureCardProductImageWrapperProps>`
  font-weight: 700;
  font-size: 1.35rem;
  margin: ${rem('10px')} 0 ${rem('5px')} 0;
`;

export const FeatureCardWrapper = styled.div<FeatureCardWrapperProps>`
  width: $rem('700px');
  display: flex;
  border: 1rem solid #ffffff;
  border-radius: ${rem('6px')};
  ${({ gridLayout }) => {
    if (gridLayout) {
      return `flex-direction: row-reverse;`;
    }
  }}
  @media (max-width: 1025px) {
    display: flex;
    ${({ gridLayout }) => {
      if (gridLayout) {
        return `flex-direction: column;`;
      }
    }}
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    :nth-child(1) {
      ${({ gridLayout }) => {
        if (gridLayout) {
          return `

            display: grid;
            height: ${rem('520px')};
            width: ${rem('500px')};
            grid-column: 1;
            grid-row: 1;
            justify-content: center;
            grid-template-columns: 1fr;
            padding: 0;
          `;
        }
      }}


      [class*='Interactionstyle__BlankInteraction-sc-e1xkgc-2 bJpqKG LinkCardstyle__LinkCard-sc-w87i5y-0 jKdbvF'] {
        text-align: center;
        h2 {
          margin: 0;
        }

        p {
          margin: 4px;
        }
      }


      #primaryButton {
        z-index: 4;
        position: absolute;
        right: 0;
        left: 0;
        bottom: ${rem('-20px')};
      }

    }

    :nth-child(2) {
      ${({ gridLayout }) => {
        if (gridLayout) {
          return `
                height: ${rem('250px')};
                width: ${rem('680px')};
                grid-column: 2;
                grid-row: 1;
                img {
                  right: ${rem('100px')};
                  z-index: -1;
                  bottom: ${rem('44px')};
                  position: relative;
                }
          `;
        }
      }}
    }

    :nth-child(3) {
      ${({ gridLayout }) => {
        if (gridLayout) {
          return `
                height: ${rem('260px')};
                width: ${rem('680px')};
                grid-column: 2;
                grid-row: 1;
                margin-top: ${rem('260px')};
                margin-left: ${rem('10px')};
            
                img {
                  position: relative;
                  z-index: -1;
                  right: ${rem('80px')};
                  top: ${rem('-24px')};
                }
          `;
        }
      }}
    }
  }

  &.propositionCard {
    width: auto;
    background-color: var (--mineShaft);
    [class*='Interactionstyle__BlankInteraction'] {
      [class*='Containerstyle__Container'] {
        height: 100%;
        [class*='Containerstyle__ContainerContent'] {
          [class*='LinkCardContent'] {
            [class*='CardHeaderstyle__CardHeader'] {
              height: $rem('192px');
              [class*='ImageWrapper'] {
                height: 100%;
                width: 100%;

                img {
                  height: 100%;
                }
              }
            }
            [class*='CardBodystyle__CardBody'] {
              padding: 0;
              [class*='CardBodystyle__ContentWrapper'] {
                [class*='CardBodystyle__ContentWrapperHeader'] {
                  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                    margin: 1rem 1rem -0.625rem;
                  }
                  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                    margin: 1.5rem 1.5rem 0;
                  }
                  h2 {
                    text-decoration: none;
                    font-weight: 700;
                    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                      font-size: 1.625rem;
                      line-height: 1.813rem;
                    }
                    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                      font-size: 1.25rem;
                      line-height: 1.438rem;
                    }
                  }
                  [class*='Iconstyle__Icon-sc'] svg {
                    display: none;
                  }
                }
                [class*='Paragraphstyle__Paragraph'] {
                  p {
                    margin: 0;
                  }

                  ul {
                    padding-right: 1rem;

                    li {
                      p {
                        padding: 1.5rem 0 0;
                        line-height: 1.1rem;
                      }
                    }

                    + p {
                      display: none;
                    }
                    padding-left: 2rem;
                    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                      padding-left: 2.4rem;
                    }
                  }
                  p {
                    margin: 0;
                    font-size: ${rem('22px')};
                    line-height: ${rem('18px')};
                    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                      padding: 1rem 0.938rem 2.063rem;
                    }
                    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                      padding: 1.5rem 1.5rem 2.875rem;
                    }
                    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
                      line-height: 1.5rem;
                      padding: 1.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  background: var(--white);
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 30%);
  max-width: 100%;
  width: 100%;
  border: 0.8rem solid var(--white);

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-inline: ${rem('10px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    margin-inline: ${rem('12px')};
  }

  ${FeatureCardImagesAndPriceWrapper},
  [class*='LinkCardContent'] [class*='CardHeader'] [class*='ImageWrapper'] {
    height: auto;
    flex-basis: 100%;
    background: brown;
    border-radius: ${rem('6px')};
  }

  [class*='PriceTagstyles__PriceTagWrapper'] {
    position: absolute;
    z-index: -1;
    margin-left: -3.4rem;
    margin-top: ${rem('95px')};
  }

  :nth-child(1) [class*='PriceTagstyles__PriceTagWrapper'] {
    margin-top: ${rem('140px')};
    margin-left: -2rem;
  }

  [class*='Interactionstyle__BlankInteraction'][class*='LinkCardstyle__LinkCard'] {
    width: auto;
  }

  [class*='Interactionstyle__BlankInteraction'][class*='Containerstyle__Container'] {
    box-shadow: unset;
  }

  [class*='Interactionstyle__BlankInteraction'][class*='LinkCardstyle__LinkCard'],
  [class*='Interactionstyle__BlankInteraction'][class*='Containerstyle__Container'],
  [class*='Containerstyle__ContainerContent'] {
    height: auto;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      height: 87%;
    }
  }

  [class*='LinkCardContent'] {
    [class*='CardHeader'] {
      display: none;

      [class*='PillWrapper'] {
        display: flex;
        position: absolute;
        top: ${rem('351px')};
        left: ${rem('33px')};

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          left: ${rem('22px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
          left: ${rem('35px')};
        }

        > span {
          background: none;
          border: 0.063rem var(--mineShaft) solid;
          border-radius: ${rem('19.5px')};
          color: var(--mineShaft);
          font-size: ${rem('1px')};
          font-weight: 400;
          letter-spacing: ${rem('0.9px')};
          padding: ${rem('10px')} ${rem('16px')};
        }
      }
    }

    [class*='CardBodystyle__CardBody'] {
      cursor: default;
      padding-block: ${rem('19px')};
      padding-inline: ${rem('33px')} ${rem('24px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        padding-block: ${rem('18px')};
        padding-inline: ${rem('23px')} ${rem('20px')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        padding-block: ${rem('20px')};
        padding-inline: ${rem('34px')} ${rem('20px')};
      }

      [class*='CardBodystyle__ContentWrapperHeader'] {
        align-items: center;
        width: 100%;
        max-width: fit-content;

        [class*='Icon'] {
          svg {
            display: none;
          }
        }

        h2[size='2'] {
          color: var(--mineShaft);
          font-size: ${rem('28px')};
          letter-spacing: -${rem('0.3px')};
          line-height: ${rem('32px')};
          text-decoration: none;
          font-weight: 700;
        }
      }

      p {
        color: var(--mineShaft);
        text-decoration: none;
        font-size: ${rem('17px')};
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: $rem('0px');
        line-height: ${rem('24px')};
        padding: none;

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          font-size: ${rem('17px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
          font-size: ${rem('17px')};
        }
      }

      p:first-child {
        text-align: justify;
      }
    }
  }
`;

export const ShortenedHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
`;

export const ShortenedHeaderText = styled.div`
  font-weight: 700;
  font-size: ${rem('18px')};
  letter-spacing: -0.01875rem !important;
  line-height: 2.0625rem !important;
  margin: 0 !important;
  text-decoration: none !important;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('28px')};
  }
`;
