import IconAndTextSlot from '@vfuk/core-card-builder/dist/components/slots/IconAndTextSlot';
import LoadingSpinner from '@vfuk/core-loading-spinner';
import { FC } from 'react';

import { SWPricingContentIconWithTitleProps } from '@/components/pageBuilder/Sections/SWPricingContent/SWPricingContent.types';

import InfoAndCta from '../../CardElements/InfoAndCta';
import { CardHeading, CTAWrapper, FCWrapper, LoadingWrapper } from '../../SWPricingCard.styles';
import { CardTypeProps } from '../../SWPricingCard.types';

import {
  IncludesCardHeader,
  IncludesCardTitle,
  IncludesCardTitleWrapper,
  IncludesTitle,
  IncludesWrapper,
  PriceDetailsWrapper,
} from './SWIncludesCard.styles';

const SWIncludesCard: FC<CardTypeProps> = ({
  item,
  mainItem,
  itemStepName,
  shouldChangeTheme,
  mergedProduct,
  includesCards,
}) => {
  const { name, detailAndPricing, dxlInformation } = item;
  return (
    <>
      {dxlInformation ? (
        <CardHeading px={5}>
          <IncludesCardHeader direction="row" spacing={5}>
            <IncludesCardTitleWrapper>
              <IncludesCardTitle as="h3" color={shouldChangeTheme ? 'white' : 'mineShaft'}>
                {name || ''}
              </IncludesCardTitle>
            </IncludesCardTitleWrapper>
            <IncludesCardTitleWrapper>
              <PriceDetailsWrapper>
                <h6>
                  <small>{detailAndPricing?.currencySymbol}</small>
                  {dxlInformation?.price[0].price || 0}
                </h6>
                <h5>{detailAndPricing?.thirdEntry}</h5>
              </PriceDetailsWrapper>
            </IncludesCardTitleWrapper>
          </IncludesCardHeader>
        </CardHeading>
      ) : (
        <LoadingWrapper>
          <LoadingSpinner appearance="primary" size={5} />
        </LoadingWrapper>
      )}
      <FCWrapper>
        <IncludesWrapper>
          <IncludesTitle fontSize={{ base: 'md', md: 'lg' }} marginRight={5} marginLeft={5} mt={{ base: 5, md: 0 }}>
            Includes
          </IncludesTitle>
          {includesCards?.map((card: SWPricingContentIconWithTitleProps) => (
            <IconAndTextSlot
              key={`includesCards-${card.id}`}
              appearance="primary"
              icon={{ name: `${card.iconName}` }}
              heading={{ text: `${card.name}` }}
              text={''}
            />
          )) || []}
        </IncludesWrapper>
      </FCWrapper>
      <CTAWrapper>
        {item?.dxlInformation && (
          <InfoAndCta
            {...{ mainItem, itemStepName }}
            item={{ ...item, buttonsAction: 'updateBasketWithQty' }}
            buttonStyling={{
              backgroundColor: undefined,
              textColor: undefined,
            }}
            useSimplePrice={mergedProduct}
          />
        )}
      </CTAWrapper>
    </>
  );
};

export default SWIncludesCard;
