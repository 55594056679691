import { rem } from 'polished';
import styled from 'styled-components';

export const PriceTagWrapper = styled.div`
  padding: ${rem('18px')} 0;
  text-align: center;
  background: var(--white);
  width: ${rem('125px')};
  height: ${rem('125px')};
  border-radius: 50%;
  display: inline-block;
  color: var(--red);
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-top: ${rem('130px')};
    margin-left: ${rem('10px')};
  }
  .currencySymbol,
  .price {
    font-weight: 700;
  }
  p {
    margin: ${rem('2px')} 0 0 0;
  }
  .currencySymbol {
    font-size: ${rem('28px')};
  }
  .price {
    font-size: ${rem('46px')};
    line-height: ${rem('25px')};
  }
  .vat {
    font-size: ${rem('5px')};
    line-height: ${rem('16px')};
    width: ${rem('68px')};
    margin: 0 auto;
    font-weight: 400;
    p {
      line-height: ${rem('15px')};
    }
  }
  .startingAt {
    font-weight: 400;
    font-size: ${rem('16px')};
    line-height: ${rem('15px')};
    margin: 0 0 ${rem('8px')} 0;
  }
`;
