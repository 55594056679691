import { SelectInputProps } from '@vfuk/core-select-input';
export const generateYears = (startYear: number, endYear: number) => {
  let years: SelectInputProps['options'] = [];
  for (let year = endYear; year >= startYear; year--) {
    years.push({ text: year.toString(), value: year.toString() });
  }
  return years;
};

export const generateDays = (month: number, year: number) => {
  const daysInMonth = new Date(year, month, 0).getDate();
  let days: SelectInputProps['options'] = [];
  for (let day = 1; day <= daysInMonth; day++) {
    days.push({ text: day.toString(), value: day.toString() });
  }
  return days;
};

export const months: SelectInputProps['options'] = [
  { text: 'January', value: '1' },
  { text: 'February', value: '2' },
  { text: 'March', value: '3' },
  { text: 'April', value: '4' },
  { text: 'May', value: '5' },
  { text: 'June', value: '6' },
  { text: 'July', value: '7' },
  { text: 'August', value: '8' },
  { text: 'September', value: '9' },
  { text: 'October', value: '10' },
  { text: 'November', value: '11' },
  { text: 'December', value: '12' },
];
