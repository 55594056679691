import { graphql, useStaticQuery } from 'gatsby';

import { SWFooterProps } from '@/components/Global/SWFooter/SWFooter.types';

export const useSWFooter: () => SWFooterProps = () => {
  const {
    allContentfulSectionSwFooter: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSectionSwFooter {
        nodes {
          id
          name
          social {
            id
            srText
            url
            icon
          }
          legalLinks {
            id
            srText
            text
            url
          }
          linkGroups {
            id
            name
            openOnMD
            groups {
              id
              headingText
              links {
                id
                srText
                text
                url
              }
            }
          }
          companyInfo
          copyrightText
          icons {
            id
            contentful_id
            name
            title
            iconName
            iconGroup
            iconSize
            color
            redirectUrl
          }
        }
      }
    }
  `);

  return nodes[0] || [];
};
