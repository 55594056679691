
import React, { useEffect } from 'react';
import Form from '@/components/pageBuilder/Elements/Form';
import { ContentWrapper, Title } from './RegisterForms.styles';
import { useFormHandler } from './useFormHandler';
import { IFormProps } from './RegisterForms.types';

export const ContactDetails = (props: IFormProps) => {
  const { form, handleInputChange, handleInputBlur } = useFormHandler('contact');
  return (
    <ContentWrapper>
      <Title>Contact details</Title>
      <Form
        formInputs={form}
        formSubmit={() => {}}
        formValidation={isValid => {
          props.validCallback(isValid, 'contact');
        }}
        onInputChange={handleInputChange}
        onInputBlur={handleInputBlur}
        shouldHaveFullWidthOnMobile={false}
      />
    </ContentWrapper>
  );
};
