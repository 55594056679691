import Button from '@vfuk/core-button';
import CardSandwichSlot from '@vfuk/core-card-sandwich-slot';
import ContainerWithLabel from '@vfuk/core-container-with-label';
import Image from '@vfuk/core-image';
import LinkWithIcon from '@vfuk/core-link-with-icon';
import LoadingSpinner from '@vfuk/core-loading-spinner';
import axios from 'axios';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { lowestEditionPrice } from '@/helpers/findPrices';
import formatCurrency from '@/helpers/formatCurrency';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { retrieveProduct } from '@/services/product.services';
import { ContentfulInformation, CurrencySymbols, MultipleProducts, Product } from '@/types/Product.types';

import { ShortenedHeader } from '../SWFeatureCard/ShortenedHeader';
import { Content, ContentText, ImgWrapper, InfoAndCta, LoadingWrapper, Wrapper } from './SWProductStrip.styles';
import { SWProductStripProps } from './SWProductStrip.types';
import usingOpcoUrl from '@/helpers/prefixHelper';
import { TError } from '@/services/error.types';

const SWProductStrip = (props: SWProductStripProps) => {
  const { id, __typename, name, thumbnailIcon, description, slug } = props;

  const { width } = useWindowSize();
  const thumbnailIconSrc = getSrc(thumbnailIcon) ?? getImage(thumbnailIcon)?.placeholder?.fallback ?? '';
  const isBundle = __typename === 'ContentfulMarketplaceProductBundle' && props.isBundle;

  const [productData, setProductData] = useState<Product['dxlInformation']>();
  const [contentfulData, setContentfulData] = useState<ContentfulInformation>();
  const { setErrorInfo, toggleModal } = useModalContext();

  const productId = useMemo(() => {
    const { productId } = props as Product;
    const { products } = props as MultipleProducts;

    if (productId) {
      return productId;
    } else if (products && products.length > 0) {
      const [product] = products;
      return product.productId;
    }

    return undefined;
  }, [props]);

  const getCurrencySymbol = (): CurrencySymbols => {
    return '€';
  };

  const getProductPrice = () => {
    return lowestEditionPrice(productData, contentfulData);
  };

  const getHeading = () => {
    let heading;
    if (__typename === 'ContentfulMarketplaceMultipleProducts') {
      heading = props.name;
    } else {
      heading = productData?.name ?? '';
    }

    if (heading.length > 35 && width <= 400) {
      return <ShortenedHeader heading={productData?.name ?? ''} maxSize={35} hoverText={'...'} />;
    } else {
      return heading;
    }
  };

  useEffect(() => {
    if (productId) {
      (async () => {
        try {
          const res = await retrieveProduct(productId);
          if (!axios.isAxiosError(res) && res.status === 200) {
            setProductData(res.data.dxlInformation);
            setContentfulData(res.data.contentfulInformation);
          } else if (axios.isAxiosError(res)) {
            setErrorInfo({
              statusType: res.response?.data?.error,
              statusCode: res.response?.data?.code || res.response?.status,
              requestUrl: 'catalogues',
            });
            toggleModal(true, 'Error');
          }
        } catch (err) {
          const error = err as TError;
          isEnvDevelopment() && console.log(error);
        }
      })();
    }
  }, [productId]);

  return (
    <Wrapper key={id} data-selector="product strip">
      <ContainerWithLabel {...(isBundle && { label: 'Bundle' })}>
        <ImgWrapper>
          <Image
            alt={thumbnailIcon?.title ?? ''}
            sm={{
              src: thumbnailIconSrc,
              width: 'auto',
            }}
          />
        </ImgWrapper>
        <Content>
          {getHeading() ? (
            <LinkWithIcon
              appearance="primary"
              text={getHeading() as string}
              icon={{
                name: 'chevron-right',
                justify: 'right',
              }}
              href={getProductPrice() === 0 ? '' : usingOpcoUrl(slug)}
              size={1}
            />
          ) : (
            <LoadingSpinner appearance="primary" size={5} />
          )}
          <ContentText>{description?.description || ''}</ContentText>
        </Content>
        <InfoAndCta>
          {getHeading() ? (
            <CardSandwichSlot
              {...{
                primaryPrice: {
                  unit: getCurrencySymbol(),
                  text: {
                    main: `${formatCurrency(getProductPrice())}`,
                    mainSuffix: '/ month (ex VAT)',
                    prefix: 'Starting at',
                  },
                },
              }}
            />
          ) : (
            <LoadingWrapper>
              <LoadingSpinner appearance="primary" size={5} />
            </LoadingWrapper>
          )}
          <Button
            {...(getProductPrice() === 0 && { state: 'disabled' })}
            text={getProductPrice() === 0 ? 'Not Available' : 'View Details'}
            appearance={getProductPrice() === 0 ? 'secondary' : 'primary'}
            href={usingOpcoUrl(slug) || ''}
            {...(name && {
              dataSelectorPrefix: name.toLowerCase(),
            })}
          />
        </InfoAndCta>
      </ContainerWithLabel>
    </Wrapper>
  );
};

export default SWProductStrip;
