import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceProps } from '@vfuk/core-basket-flyout-template/dist/BasketFlyoutTemplate.types';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { Product, ProductBundle } from '@/types/Product.types';

type CommonProductFields = Pick<Product, 'name' | 'slug' | 'productId' | 'dxlInformation' | 'id' | 'thumbnailIcon'>;

export type UserBasketItemBase = {
  quantity?: number;
  configTab?: 'Bundle' | 'Service Extra' | 'Add-on';
  mainProduct?: UserBasketItemProd | UserBasketItemProdBundle;
};

export type UserBasketItemProd = CommonProductFields &
  UserBasketItemBase &
  Pick<Product, '__typename' | 'buttonsAction'>;

export type UserBasketItemProdBundle = CommonProductFields &
  Pick<
    ProductBundle,
    | 'productsFreeOfCharge'
    | 'buttonsAction'
    | 'bundleIncludes'
    | 'detailAndPricing'
    | 'editionIds'
    | '__typename'
    | 'msftProduct'
    | 'selectedEdition'
  > &
  UserBasketItemBase;

export type UserBasketItem = UserBasketItemProd | UserBasketItemProdBundle;

export type UserBasketProps = {
  items: UserBasketItem[];
  total?: PriceProps;
};

export type UserBasketSliceProps = {
  data: UserBasketProps;
  isBasketSummaryOpen: boolean;
  isLoading: boolean;
  error: null;
};

const reduceBasketValue = (items: UserBasketProps['items']) => {
  return items
    .reduce((prevValue, currValue) => {
      if (currValue?.quantity) {
        if (
          currValue.__typename === 'ContentfulMarketplaceProductBundle' &&
          currValue.msftProduct &&
          currValue.slug.includes('apphelp-microsoft-365-migration')
        ) {
          if (currValue.quantity <= 5) {
            return prevValue + (currValue.dxlInformation?.price.find(price => price.name === 'Cost2')?.price || 0);
          } else {
            return (
              prevValue +
              (currValue.dxlInformation?.price.find(price => price.name === 'Cost2')?.price || 0) +
              (currValue.dxlInformation?.price.find(price => price.name === 'Cost1')?.price || 0) *
                (currValue.quantity - 5)
            );
          }
        }
        const hasTypePrice = currValue.dxlInformation?.price.find(
          price => price.name === 'Cost1' || price.name === 'Cost2'
        );
        const currPrice = hasTypePrice
          ? currValue.dxlInformation?.price.find(price => price.name === 'Cost1')?.price || 0
          : currValue.dxlInformation?.price[0].price || 0;
        return prevValue + currPrice * currValue.quantity;
      } else {
        return 0;
      }
    }, 0)
    .toFixed(2);
};

const withMailBox = (items: UserBasketProps['items'], option = true) => {
  if (option) {
    return items.filter(item => {
      const foundMailBox = item.dxlInformation?.price.find(price => price.unit === 'MAILBOX');
      const foundSession = item.dxlInformation?.price.find(price => price.unit === 'SESSION');
      if (foundMailBox || foundSession) {
        return item;
      }
    });
  } else {
    return items.filter(item => {
      const foundMailBox = item.dxlInformation?.price.find(price => price.unit === 'MAILBOX');
      const foundSession = item.dxlInformation?.price.find(price => price.unit === 'SESSION');
      if (!foundMailBox && !foundSession) {
        return item;
      }
    });
  }
};

const sumBasket = (items: UserBasketProps['items']) => {
  const upfront = {
    gross: reduceBasketValue(withMailBox(items)),
  };
  const monthly = {
    gross: reduceBasketValue(withMailBox(items, false)),
  };
  const total = {
    upfront,
    monthly,
  } as UserBasketProps['total'];
  return total;
};

export const isInBasket = (store: UserBasketProps, item: UserBasketItem) => {
  const { items } = store;
  return !!items.find(basketItem => basketItem.productId === item.productId);
};

export const itemQuantityInBasket = (store: UserBasketProps, item: UserBasketItem) => {
  const { items } = store;
  const selectedItem = items.find(basketItem => basketItem.productId === item.productId);

  if (selectedItem) {
    return selectedItem.quantity;
  }

  return 0;
};

const userBasketSlice = createSlice({
  name: 'userBasket',
  initialState: {
    data: {
      items: [],
    },
    isBasketSummaryOpen: false,
    isLoading: false,
    error: null,
  } as UserBasketSliceProps,
  reducers: {
    addToBasket: (state, action: PayloadAction<UserBasketItem>) => {
      const items = [...state.data.items, action.payload];
      if (
        action.payload.mainProduct &&
        state.data.items.find(item => item.productId === action.payload.mainProduct?.productId)
      ) {
        state.data.items.push(action.payload);
        state.data.total = sumBasket(items);
      } else if (!action.payload.mainProduct) {
        state.data.items.push(action.payload);
        state.data.total = sumBasket(items);
      }
    },
    removeFromBasket: (state, action: PayloadAction<UserBasketItem | UserBasketItem['productId']>) => {
      const prod: UserBasketItem | undefined = state.data.items.find(item => {
        if (typeof action.payload !== 'string' && item.productId === action.payload.productId) {
          return item;
        } else if (typeof action.payload === 'string' && item.productId === action.payload) {
          return item;
        }
      });
      const withMainProd = !!prod?.mainProduct;
      if (prod && withMainProd) {
        const items = state.data.items.filter(item => {
          if (typeof action.payload !== 'string' && item.productId !== action.payload.productId) {
            return item;
          } else if (typeof action.payload === 'string' && item.productId !== action.payload) {
            return item;
          }
        });
        state.data.items = items;
        state.data.total = sumBasket(items);
      } else if (prod && !withMainProd) {
        const items = state.data.items.filter(item => {
          if (
            typeof action.payload !== 'string' &&
            item.productId !== action.payload.productId &&
            item.mainProduct &&
            item.mainProduct?.productId !== action.payload.productId
          ) {
            return item;
          }
          if (typeof action.payload !== 'string' && item.productId !== action.payload.productId && !item.mainProduct) {
            return item;
          } else if (typeof action.payload === 'string' && item.productId !== action.payload && !item.mainProduct) {
            return item;
          } else if (
            typeof action.payload === 'string' &&
            item.productId !== action.payload &&
            item.mainProduct &&
            item.mainProduct?.productId !== action.payload
          ) {
            return item;
          }
        });
        state.data.items = items;
        state.data.total = sumBasket(items);
      }
    },
    updateUserBasket: (state, action: PayloadAction<UserBasketItem>) => {
      const items = state.data.items.map(item => {
        if (item.productId === action.payload.productId) {
          return action.payload;
        }
        return item;
      });
      state.data.items = items;
      state.data.total = sumBasket(items);
    },
    deleteBasket: state => {
      state.data.items = [];
      state.data.total = undefined;
    },
    setBasketSummaryOpen: (state, action: PayloadAction<boolean>) => {
      state.isBasketSummaryOpen = action.payload;
    },
  },
});

export const { addToBasket, removeFromBasket, updateUserBasket, deleteBasket, setBasketSummaryOpen } =
  userBasketSlice.actions;

export const userBasketReducer = userBasketSlice.reducer;

const persistConfig = {
  key: `vf_${userBasketSlice.name}`,
  storage: storageSession,
};

export const userBasketPersistReducer = persistReducer<UserBasketSliceProps>(persistConfig, userBasketReducer);
