import { TextStackStyles } from '@source-web/text-stack';

import styled, { css } from 'styled-components';
import { advancedSpacing, respondTo, spacing } from '@source-web/mixins';

export const PricesWrapper = styled.div`
  ${spacing('margin-right', 3)};
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${respondTo.md(css`
    ${spacing('margin-right', 6)};
  `)}

  & > ${TextStackStyles.TextStack} {
    position: relative;
    ${advancedSpacing('padding', [0, 2])};

    ${respondTo.md(css`
      ${advancedSpacing('padding', [0, 3])};
    `)}

    &::after {
      content: '';
      display: block;
      height: 75%;
      width: ${({ theme }) => theme.border.width[1]};
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: ${({ theme }) => theme.color.monochrome6.default};
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }
`;
