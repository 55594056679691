import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Heading from '@vfuk/core-heading';
import { FC } from 'react';

import { NotFoundPageWrapper } from '@/components/Global/NotFoundPageWrapper';
import { usePage404Data } from '@/hooks/usePage404Data';
import MainLayout from '@/layouts/MainLayout';

const NotFoundPage: FC = () => {
  const heading404 = '404 - Page not found';
  const body404 = `Sorry, the page you have requested does not exist`;
  const data = usePage404Data();

  return (
    <div data-testid="notFoundPage">
      <MainLayout>
        <NotFoundPageWrapper>
          <Heading level={1} text={data?.page404?.heading || heading404} />
          {data?.page404?.body?.raw ? documentToReactComponents(JSON.parse(String(data?.page404?.body?.raw))) : body404}
        </NotFoundPageWrapper>
      </MainLayout>
    </div>
  );
};

export default NotFoundPage;
