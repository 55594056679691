import { ACTIONS, Actions, InitialState } from './types';

export const reducer = (state: InitialState, action: Actions) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_MODAL: {
      return {
        ...state,
        [`is${action.payload.modalType || ''}ModalOpen`]: action.payload.isModalOpen,
      };
    }
    case ACTIONS.SET_ERROR_INFO: {
      return {
        ...state,
        errorInfo: action.payload.errorInfo,
      };
    }
    default: {
      return state;
    }
  }
};
