import axios, { AxiosError } from 'axios';

import { UserBasketItem, UserSessionProps } from '@/store';
import { ContentfulInformation, DxlInformation, DxlInformationPrice, Feature, Product } from '@/types/Product.types';

import { api } from './auth.services';

export type ProductOrderBasketProduct = {
  productUuid: DxlInformation['uuid'];
  qty: UserBasketItem['quantity'];
  unit: DxlInformationPrice['unit'];
};

export type ProductOrderBasket = Pick<UserSessionProps, 'organizationId' | 'userId'> & {
  products: ProductOrderBasketProduct[];
};

export type ProductOrderProps = {
  basket: ProductOrderBasket;
};

type RetrieveProductProps = Pick<Product, 'id' | 'dxlInformation'> & {
  contentfulInformation?: ContentfulInformation;
};

export const retrieveProduct = async (productId: string) => {
  console.log('Starting a request to retrieve products: ', `/product/${productId}`);
  const res = await api.get<RetrieveProductProps>(`/product/${productId}`).catch<AxiosError>(error => error);

  if (!axios.isAxiosError(res)) {
    console.log(`/product/${productId}`, ' The request was successfully completed with the following status: ', res.request?.status);
  } else {
    console.log(`/product/${productId}`, ' The request was completed unsuccessfully with the following status: ', res.request?.status, res?.code);
  }

  return res;
};

export const productOrder = async (productOrder: ProductOrderProps, csrfToken: string) => {
  const res = await api
    .post('/product-order', productOrder, {
      headers: { Authorization: csrfToken },
    })
    .catch<AxiosError>(error => error);
  return res;
};

export const retrieveProductFeatures = async (productId: string) => {
  const res = await api.get<Feature[]>(`/product/${productId}/features`).catch<AxiosError>(error => error);
  return res;
};
