import { Flex, Heading, UnorderedList } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const IncludesWrapper = styled(Flex)``;

export const IncludesHeading = styled(Heading)`
  && {
    margin: 0;
    font-size: ${rem('17px')};
    letter-spacing: ${rem('0.6px')};
    line-height: ${rem('18px')};
  }
`;

export const IncludesList = styled(UnorderedList)``;
