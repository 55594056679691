import { rem } from 'polished';
import styled from 'styled-components';

export const GroupedTextFieldset = styled.fieldset`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextInputWrapper = styled.div`
  width: ${rem('44px')};
  height: ${rem('44px')};
  margin-right: ${rem('10px')};
  &:last-of-type {
    margin-right: 0;
  }
`;
