import { TranslationOptions } from '../type';

export default {
  selectedLang: 'English',
  Lang: 'Choose your language',
  AddToBasket: 'Add To Basket',
  UpdateBasket: 'Update Basket',
  RemoveFromBasket: 'Remove from Basket',
  ConfigureYourPack: 'Configure Your Pack',
  nif: 'NIF',
  account: 'Account',
  pricingRedSentence: `* For this product, only one Edition can be selected and purchased (this doesn't have effect on the licence
  quantity).`,
  priceSuffix: 'ex.VAT',
  pricingBlackSentence: '',
  and: '',
  termsAndConditions: '',
  privacySupplement: '',
  pricingRedSentenceTrendMicro:
    '*Apenas um tipo de edição do Trend Micro poderá ser subscrito (sem limite de número de licenças).',
  billingPlataform: {
    blocklist: {
      title: `Unfortunately, it's not possible to go any further`,
      description: 'Please contact your communications manager or call 16914.',
      buttonCTA: 'Back',
    },
    noAccounts: {
      title: 'No eligible Vodafone accounts',
      description:
        'No Vodafone accounts associated with your My Vodafone access have been found that allow you to proceed with this subscription. Please validate the associated accounts in your My Vodafone area. If you have any questions, please contact your communications manager or call 16914.',
      buttonCTA: 'Go to My Vodafone',
    },
  },
  totalCost: 'Total cost',
  monthly: 'Monthly',
  upfront: 'Upfront',
  currency: '£',
  skipToBasket: 'Skip to Basket',
  goToBasket: 'Go to Basket',
  goToCheckout: 'Go to Checkout',
  completePurchase: 'Complete purchase',
  nextOption: 'Next option',
  quantity: 'Quantity',
  yourOrderSummary: 'Your Order Summary',
  selectProduct: 'Select Product',
  checkoutLoading: 'Please wait for some time for check out ...',
  basketURL: '/marketplace/product-list/basket',
  emptyBasket: 'Your basket is currently empty!',
  emptyBasketSubtitle: 'Before proceeding to checkout you must add some products to your shopping cart.',
  seeDetails: 'See details',
} as TranslationOptions;
