import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Heading from '@vfuk/core-heading/dist/Heading';
import Icon from '@vfuk/core-icon';

import { AccordionHeader, Header, Wrapper } from './SWSectionFAQs.styles';
import { SWSectionFAQsProps } from './SWSectionFAQs.types';

const SWSectionFAQs = ({ heading, backgroundColor, accordions }: SWSectionFAQsProps) => {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Heading size={3} text={heading} />
      <Accordion allowMultiple>
        {accordions?.map(({ id, accordionHeading, headingIconName, headingIconGroup, accordionPanel }) => {
          return (
            <AccordionItem key={id}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton>
                    <AccordionHeader>
                      <Header>{accordionHeading}</Header>
                    </AccordionHeader>

                    <Box transition={'all 0.2s ease-out 0s'} transform={isExpanded ? 'rotate(180deg)' : 'none'}>
                      <Icon size={3} name="chevron-down" group="system" appearance="secondary" />
                    </Box>
                  </AccordionButton>
                  <AccordionPanel>{documentToReactComponents(JSON.parse(accordionPanel.raw))}</AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </Wrapper>
  );
};

export default SWSectionFAQs;
