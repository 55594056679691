module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-apollo@4.0.3_@apollo+client@3.9.5_gatsby@5.10.0/node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://graphql.contentful.com/content/v1/spaces/agqck2hr2zli/environments/maac-prod","headers":{"authorization":"Bearer __JV6d8SpmQAX0OwGJ8aokXkPKAfQaKiD9xBX4WH1ww"}},
    },{
      plugin: require('../../../node_modules/.pnpm/@chakra-ui+gatsby-plugin@3.1.3_@chakra-ui+react@2.8.2_gatsby@5.10.0/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":false,"isUsingColorMode":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugi_zpp32wgq6r44imdhxuryvjkgvu/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"minify":true,"displayName":true,"fileName":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.13.1_gatsby@5.10.0/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://businessmarketplace.vodafone.ie"},
    },{
      plugin: require('../plugins/og-url-generator/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://businessmarketplace.vodafone.ie"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.10.0_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/img_vodafone__icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7e1719cd72873eeb75c99049929a9f60"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.10.0_react-dom@18.2.0_react@18.2.0_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
