import { gql } from '@apollo/client';

export const PRODUCT_FIELDS = gql`
  fragment ProductFields on MarketplaceProduct {
    sys {
      id
    }
    __typename
    productId
    name
    slug
    thumbnailIcon {
      title
      url(transform: { resizeStrategy: SCALE, width: 122, height: 122 })
    }
    category {
      sys {
        id
      }
      __typename
      name
      slug
    }
    shortDescription
    includeOnly
    includeDescription
    description
  }
`;

export const PRODUCT_BUNDLE_FIELDS = gql`
  fragment ProductBundleFields on MarketplaceProductBundle {
    sys {
      id
    }
    __typename
    productId
    name
    slug
    thumbnailIcon {
      title
      url(transform: { resizeStrategy: SCALE, width: 122, height: 122 })
    }
    category {
      sys {
        id
      }
      __typename
      name
      slug
    }
    shortDescription
    includeOnly
    includeDescription
    description
    detailAndPricing {
      containerAppearance
      containerLabel {
        json
      }
      firstEntry
      firstEntryValue
      secondEntry
      secondEntryValue
      currencySymbol
      useCustomThirdEntryValue
      thirdEntry
      thirdEntryValue
      thirdEntryValueColor
      fourthEntry
      fourthEntryValue
    }
    enableToggleOption
    productPageUrl
    isFlippingCard
    solutionType
    productType
    toggleDescription
    bundleIncludesCollection {
      items {
        __typename
        name
        shortDescription
        thumbnailIcon {
          title
          url(transform: { resizeStrategy: SCALE, width: 34, height: 34 })
        }
      }
    }
    addOnCollection {
      items {
        __typename
        name
        shortDescription
        thumbnailIcon {
          title
          url(transform: { resizeStrategy: SCALE, width: 34, height: 34 })
        }
      }
    }
    showDivider
    customiseBundleText {
      json
    }
    configurationLink {
      sys {
        id
      }
      __typename
      slug
    }
  }
`;

export const GET_PRODUCT_INFO = gql`
  query GetProductInfo($productId: String!, $tags: [String]) {
    marketplaceProductCollection(
      where: { productId: $productId, contentfulMetadata: { tags: { id_contains_some: $tags } } }
      limit: 1
    ) {
      items {
        ...ProductFields
      }
    }
    marketplaceProductBundleCollection(
      where: { productId: $productId, contentfulMetadata: { tags: { id_contains_some: $tags } } }
      limit: 1
    ) {
      items {
        ...ProductBundleFields
      }
    }
  }
  ${PRODUCT_FIELDS}
  ${PRODUCT_BUNDLE_FIELDS}
`;

export const GET_BASKET_PRODUCTS = gql`
  query GetBasketProducts($productId: [String!]!, $tags: [String]) {
    marketplaceProductCollection(
      where: { productId_in: $productId, contentfulMetadata: { tags: { id_contains_some: $tags } } }
      limit: 26
    ) {
      items {
        ...ProductFields
      }
    }
    marketplaceProductBundleCollection(
      where: { productId_in: $productId, contentfulMetadata: { tags: { id_contains_some: $tags } } }
      limit: 26
    ) {
      items {
        ...ProductBundleFields
      }
    }
  }
  ${PRODUCT_FIELDS}
  ${PRODUCT_BUNDLE_FIELDS}
`;
