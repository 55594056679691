import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 3.125rem;
  }
`;

export const WrapHeading = styled.div`
  padding: ${rem('32px')} 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: ${rem('50px')} 0;
  }

  h2 {
    color: var(--mineShaft);
    font-size: ${rem('36px')};
    letter-spacing: ${rem('-0.2px')};
    line-height: ${rem('39px')};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      padding-inline: ${rem('42px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('40px')};
      letter-spacing: ${rem('-0.3px')};
      line-height: ${rem('48px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
      font-size: ${rem('40px')};
    }
  }
`;

export const NoHeading = styled.div`
  display: block;
  height: 1rem;
`;

export const WrapCards = styled.div`
  display: grid;
  row-gap: 1.25rem;
  padding: 0 0.938rem;
  
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    column-gap: 0px;
    row-gap: 1.25rem;
    justify-items: center;
    max-width: 73.75rem;
    padding: 0 1rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0;
  }
`;

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem('28px')} ${rem('59px')} 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: ${rem('50px')} 0 0 0;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: ${rem('70px')} 0 ${rem('20px')} 0;
  }

  [class*='Interactionstyle__Interaction'][class*='Buttonstyle__Button'] {
    color: var(--mineShaft);
    display: flex;
    font-size: ${rem('20px')};
    max-height: ${rem('51px')};
    padding-block: ${rem('19px')};
    width: ${rem('220px')};

    &:hover {
      color: ${({ theme }) => theme.color.primary2.default};
    }

    &::after {
      border-width: ${rem('1px')};
    }
  }
`;
