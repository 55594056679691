import Breadcrumbs from '@vfuk/core-breadcrumbs';
import { withPrefix } from 'gatsby';

import { BreadcrumbWrapper } from './SWBreadcrumb.styles';
import { SWBreadcrumbProps } from './SWBreadcrumb.types';
import usingOpcoUrl from '@/helpers/prefixHelper';

export const CONTENTFUL_SECTION_SW_BREADCRUMB = 'ContentfulSectionSwBreadcrumb';

const SWBreadcrumb = ({ content, backgroundColor, isBlackTheme = false }: SWBreadcrumbProps) => {
  const isExternalLink = (linkType: string): boolean => linkType === 'external' || linkType !== 'internal';
  const items = content.map(item => ({
    id: item.id,
    text: item.linkText,
    href: usingOpcoUrl(isExternalLink(item.linkType) ? item.url : withPrefix(item.url)),
  }));

  return (
    <BreadcrumbWrapper
      backgroundColor={isBlackTheme ? '#222222' : backgroundColor || 'white'}
      isBlackTheme={isBlackTheme}
    >
      <Breadcrumbs items={items} />
    </BreadcrumbWrapper>
  );
};

export default SWBreadcrumb;
