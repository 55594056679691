import styled from '@emotion/styled';
import { rem } from 'polished';

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  width: 100%;
  font-size: ${rem('24px')};
  color: var(--white);
  padding: 0 ${rem('8px')};
  margin: ${rem('10px')} 0;
`;
export const FormParagraph = styled.div<{ size: number }>`
  font-size: ${props => props.size}px;
  margin: 0 ${rem('10px')};
`;

