import styled from '@emotion/styled';
import { rem } from 'polished';



export const UploadBannerWrapper = styled.div`
  width: 100%;
  `;

export const UploadSession = styled.div`
  padding: ${rem('40px')} ${rem('16px')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: ${rem('30px')};
  text-align: center;
  width: 100%;

  input {
    width: ${rem('182px')};
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: ${rem('70px')} ${rem('30px')};
  }


  h3 {
    margin-bottom: 0;
    font-size: ${rem('34px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('46px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
      font-size: ${rem('58px')};
    }
  }
  
  p {
    i {
      font-family: "Vodafone Lt";
      font-size: ${rem('18px')};
      font-style: normal;
      font-weight: 400;
      color: #333;
    }
  }
  `;


export const ButtonWrapper = styled.div`
    max-width: ${rem('80px')};
    display: flex;
    justify-content: center;
  `;
