import { AxiosError } from 'axios';

import { api } from './auth.services';
import { IFormState } from '@/components/pageBuilder/Elements/Form/Form.types';

export type FormEmailProps = {
  ['Name']: string;
  ['Company name']: string;
  ['Email']: string;
  ['Phone number']: string;
  ['Comment']: string;
};

const formartLiveFormSubmit = (form: IFormState): FormEmailProps => {
  const formattedForm: FormEmailProps = {
    ['Name']: '',
    ['Company name']: '',
    ['Email']: '',
    ['Phone number']: '',
    ['Comment']: '',
  };

  for (const key in formattedForm) {
    formattedForm[key as keyof FormEmailProps] = form[key].value as string;
  }

  return formattedForm;
};

export const sendLiveFormEmail = async (form: IFormState) => {
  const data = formartLiveFormSubmit(form);
  const res = await api.post('/callback-form', data).catch<AxiosError>(error => error);
  return res;
};
