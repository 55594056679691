import Tooltip from '@vfuk/core-tooltip';
import { FC } from 'react';

import { ShortenedHeaderText, ShortenedHeaderWrapper } from './SWFeatureCard.styles';
import { ShortenedHeaderProps } from './SWFeatureCard.types';

export const ShortenedHeader: FC<ShortenedHeaderProps> = ({ heading, maxSize, hoverText }: ShortenedHeaderProps) => {
  return (
    <ShortenedHeaderWrapper>
      <ShortenedHeaderText>
        {heading.substring(0, heading.lastIndexOf(' ', maxSize))}
        <Tooltip id="shortHeader" text={heading} appearance="primary" size={2} zIndex={99}>
          {' ' + hoverText}
        </Tooltip>
      </ShortenedHeaderText>
    </ShortenedHeaderWrapper>
  );
};
