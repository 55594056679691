import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const ErrorModalWrapper = styled.div`
  h3[class*='Headingstyle__Heading-sc'] {
    margin-top: ${rem('80px')};
    line-height: ${rem('48px')};
    font-size: ${rem('36px')};
    color: ${({ theme }) => theme.color.primary2.default};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      margin: ${rem('100px')} auto ${rem('40px')};
      width: ${rem('314px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: ${rem('32px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: ${rem('40px')};
      margin-bottom: ${rem('45px')};
    }

    p {
      margin: 0;
      line-height: ${rem('48px')};

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
        letter-spacing: ${rem('-1px')};
      }

      b {
        font-weight: 700;
      }
    }
  }

  [class*='Interactionstyle__BlankInteraction-sc'] {
    [class*='Containerstyle__ContainerContent-sc'] {
      [class*='ArticleCardstyle__ArticleCard-sc'] {
        max-width: 100vw;
        [class*='CardHeaderstyle__CardHeader-sc'] {
          margin-top: ${rem('30px')};
        }
        [class*='CardHeaderstyle__ImageWrapper'] {
          width: ${rem('328px')};
        }

        [class*='CardBodystyle__CardBody-sc'] {
          position: relative;
          width: 100%;
          margin-top: ${rem('-30px')};
          padding: 0 ${rem('20px')};

          [class*='CardBodystyle__ContentWrapper-sc'] {
            text-align: center;
            width: ${rem('556px')};
            margin: 0 auto;

            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              width: ${rem('344px')};
            }

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                theme,
              }) => theme.breakpoints.lg - 1}px) {
              margin-bottom: ${rem('56px')};
            }

            p {
              margin: 0 auto ${rem('16px')};
              width: ${rem('382px')};
              font-size: ${rem('18px')};
              line-height: ${rem('24px')};

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                width: auto;
              }

              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                  theme,
                }) => theme.breakpoints.lg - 1}px) {
                font-size: ${rem('14px')};
                line-height: ${rem('16px')};
              }
            }

            h2 {
              font-weight: 700;
              @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                font-size: ${rem('24px')};
                line-height: ${rem('31px')};
              }
            }
          }

          [class*='CardBodystyle__ButtonWrapper-sc'] {
            margin-bottom: ${rem('70px')};
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
              margin: ${rem('20px')} 0 ${rem('70px')};
            }

            a {
              border-width: ${rem('1px')};
              font-size: ${rem('20px')};
              padding: ${rem('15px')} 0;
              height: ${rem('50px')};
              margin: 0 auto;
              width: ${rem('382px')};

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
`;

export const ArticleCardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  [class*='ArticleCardstyle__ArticleCard-sc'] {
    background: ${({ theme }) => theme.color.primary2.default};
    height: ${rem('560px')};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      height: ${rem('595px')};
    }
  }
`;

export const ErrorModalCloseButton = styled.button`
  border: none;
  font-weight: bold;
  position: relative;
  top: ${rem('60px')};
  z-index: 9999999;
  align-self: end;
  margin-right: ${rem('30px')};
`;
