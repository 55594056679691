import { AxiosError } from 'axios';

import { UserSessionProps } from '@/store';
import { OPCOOptions, UserServiceHeaderOptions, UserServiceHeaders } from '@/types/Services.types';

import { api } from './auth.services';

export const eligibility = async () => {
  const res = await api.get<Pick<UserSessionProps, 'accounts'>>(`/eligibility`).catch<AxiosError>(error => error);
  return res;
};

export const users = async (headerOptions: UserServiceHeaderOptions) => {
  const OPCO = process.env.GATSBY_OPCO;
  const { customerAccountId, companyName, barId, segment, customerId } = headerOptions;

  let headers: UserServiceHeaders = {};

  switch (OPCO) {
    case OPCOOptions.PORTUGAL:
      headers = {
        ...headers,
        'x-company-name': companyName,
        'x-customer-account-id': customerAccountId,
        'x-customer-id': customerId,
      };
      break;
    case OPCOOptions.IRELAND:
    case OPCOOptions.SHOWHOME:
    default:
      headers = {
        ...headers,
        'x-bar-id': barId,
        'x-segment': segment,
        'x-customer-id': customerId,
      };
      break;
  }

  const res = await api
    .get<Pick<UserSessionProps, 'accounts'>>(`/users`, {
      headers,
    })
    .catch<AxiosError>(error => error);
  return res;
};
