import { rem } from 'polished';
import { CookieConsentProps } from 'react-cookie-consent/dist/CookieConsent.props';

export const ButtonStyles: CookieConsentProps['style'] = {
  color: '#fff',
  background: '#F60000',
  fontSize: `${rem('11px')}`,
  padding: `${rem('12px')} ${rem('10px')}`,
  width: 'auto',
  minWidth: `${rem('104px')}`,
};

export const BannerStyles: CookieConsentProps['style'] = {
  color: '#fff',
  background: '#333',
  padding: `${rem('15px')} ${rem('30px')}`,
};
