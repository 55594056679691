import { IconButton } from '@source-web/icon-button';

import BasketItemDeleteStyles from './BasketItemDelete.styles';

interface BasketItemDeleteProps {
  handleDelete: () => void;
}

const BasketItemDelete = ({ handleDelete }: BasketItemDeleteProps) => {
  return (
    <BasketItemDeleteStyles>
      <IconButton srText="delete" icon={{ name: 'delete' }} appearance="alt1" size={4} onClick={handleDelete} />
    </BasketItemDeleteStyles>
  );
};

export default BasketItemDelete;
