import { graphql, useStaticQuery } from 'gatsby';

export const usePage404Data = () => {
  const { contentfulSiteConfig } = useStaticQuery<Queries.Page404Query>(graphql`
    query Page404 {
      contentfulSiteConfig {
        page404 {
          heading
          body {
            raw
          }
        }
      }
    }
  `);

  return contentfulSiteConfig;
};
