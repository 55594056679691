import { Box } from '@chakra-ui/react';
import { rem } from 'polished';
import styled from '@emotion/styled';

export const ThumbnailWrapper = styled(Box)`
  && {
    margin-right: ${rem('6px')};
    min-width: ${rem('34px')};
    min-height: ${rem('34px')};
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  max-width: ${rem('190px')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('14px')};
    max-width: ${rem('140px')};
  }
`;
