const handleUploadFile = async (file: File | undefined) => {

    if (typeof file === 'undefined') return

    const formData = new FormData()
    formData.append('file', file)

    await fetch(`${process.env.GATSBY_API_BASE_URL}/blocklist/bulk`,
        {
            method: 'POST',
            body: formData,
            headers: new Headers({
                "x-transaction-id": "vodafone-123"
            })
        }).then(() => location.reload())
}

export default handleUploadFile;