import { forwardRef, Grid, GridProps } from '@chakra-ui/react';
import { rem } from 'polished';

interface CustomGridProps {
  color?: string;
}

const BaseGrid = forwardRef<GridProps & CustomGridProps, 'div'>(({ children, ...props }, ref) => {
  return (
    <Grid
      ref={ref}
      gap={`0 ${rem('16px')}`}
      pb={4}
      pt={3}
      px={{ base: 5 }}
      templateColumns={{
        base: 'repeat(2, 1fr)',
      }}
      mb={0}
      {...props}
    >
      {children}
    </Grid>
  );
});

export default BaseGrid;
