import axios, { AxiosError } from 'axios';
import { withPrefix } from 'gatsby';

import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { UserSessionProps } from '@/store';

import { eligibility } from './user.services';
import usingOpcoUrl from '@/helpers/prefixHelper';

export type RedirectWithAuthCodeError = {
  status: string;
  message: string;
  error?: string;
};

export const api = axios.create({
  baseURL: process.env.GATSBY_API_BASE_URL,
  withCredentials: true,
  headers: {
    'Referrer-Policy': 'strict-origin-when-cross-origin',
    'Clear-Site-Data': '"cache","cookies","storage"',
    'Cross-Origin-Embedder-Policy': 'require-corp',
    'Cross-Origin-Opener-Policy': 'same-origin',
    'Cross-Origin-Resource-Policy': 'same-origin',
    'X-Content-Type-Options': 'nosniff',
    'X-Permitted-Cross-Domain-Policies': 'none',
    'X-Frame-Options': 'SAMEORIGIN',

    'Strict-Transport-Security': 'max-age=2592000; includeSubDomains',
    'Content-Security-Policy': 'default-src "self"; script-src "self"',

  },
});

export const redirectWithAuthCode = async (redirect_uri: string, auth_code: string) => {
  const uuid = sessionStorage.getItem('nonce');
  const res = await api
    .get<'Created'>(`/login`, {
      headers: {
        'x-auth-code': auth_code,
        'x-redirect-uri': redirect_uri,
        nonce: uuid ?? '',
      },
    })
    .catch<AxiosError<RedirectWithAuthCodeError>>(error => error);
  return res;
};

export const authRedirectWithEligibility = async (redirect_uri: string, auth_code: string) => {
  const res = await redirectWithAuthCode(redirect_uri, auth_code);

  if (!axios.isAxiosError(res) && (res.status === 201 || res.data === 'Created')) {
    // NOTE: Test if login process will work inside branch cloned-vhub-plus, then if it works, we can remove this
    // console.log({ res });

    const eligibilityRes = await eligibility();
    // NOTE: Test if login process will work inside branch cloned-vhub-plus, then if it works, we can remove this
    // console.log({ eligibilityRes });

    if (!axios.isAxiosError(eligibilityRes) && eligibilityRes.status === 200) {
      usingOpcoUrl(withPrefix('marketplace/multiple-billing-account'), true);
    }
  } else {
    isEnvDevelopment() && console.warn(res);
  }
};

export const logout = async () => {
  const res = await api.post<Pick<UserSessionProps, 'accounts'>>(`/logout`).catch<AxiosError>(error => error);
  return res;
};
