import React, { useState } from 'react';
import {
  StepperContainer,
  StepConnector,
  StepContainer,
  StepIcon,
  StepLabel,
  StepsHeader,
  IconWrapper,
} from './StepperWithIcon.styles';

import { StepperWithIconProps } from './StepperWithIcon.types';
import { Icon } from '@vfuk/core-icon';
import { CheckmarkIcon } from '../svgs/Icons';

const StepperWithIcon: React.FC<StepperWithIconProps> = ({ steps, activeStep, onStepClick }) => {
  return (
    <StepperContainer>
      <StepsHeader>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <StepContainer isActive={index === activeStep} onClick={() => onStepClick(index)}>
              <IconWrapper>
                <StepIcon isActive={index === activeStep} completed={activeStep > index}>
                  {activeStep > index ? <CheckmarkIcon /> : <Icon group={step.icon.group} name={step.icon.name} />}
                  {index < steps.length - 1 && <StepConnector isActive={index < activeStep} />}
                </StepIcon>
              </IconWrapper>
              <StepLabel>{step.label}</StepLabel>
            </StepContainer>
          </React.Fragment>
        ))}
      </StepsHeader>
    </StepperContainer>
  );
};

export default StepperWithIcon;
