import Container from '@/components/Global/Container';
import { rem } from 'polished';
import styled from 'styled-components';

const CompletedPurchaseStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${rem('30px')};
  min-height: calc(100vh - ${rem('58px')});

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-height: calc(100vh - ${rem('108px')});
  }
`;

export const CompletedPurchaseContainer = styled(Container)``;

export const CompletedPurchaseHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem('15px')};

  > span,
  > h3:last-child {
    margin-bottom: ${rem('15px')};
  }

  svg {
    width: ${rem('80px')};
    height: ${rem('80px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: ${rem('30px')};

    > span,
    > h3:last-child {
      margin-bottom: ${rem('30px')};
    }

    svg {
      width: ${rem('120px')};
      height: ${rem('120px')};
    }
  }
`;

export const CompletedPurchaseContent = styled.div`
  width: 100%;
  padding: ${rem('24px')} ${rem('32px')};
  background: #ffffff;
  border: ${rem('1px')} solid #a8acb8cc;
  border-radius: ${rem('16px')};
`;

export const CompletedPurchaseCallout = styled.div`
  display: flex;
  margin-bottom: ${rem('24px')};
  > * {
    &:first-of-type {
      margin-right: ${rem('12px')};
    }
    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

export const CalloutMessage = styled.div`
  p,
  h5 {
    font-family: 'VodafoneLight';
  }
  p {
    color: #282828;
    margin: 0;
  }
`;

export const CompletedPurchaseButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${rem('30px')} 0;

  #completed-purchase-vodafone {
    margin-bottom: ${rem('10px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
    #completed-purchase-vodafone {
      margin-right: ${rem('30px')};
      margin-bottom: 0;
    }

    #completed-purchase-vodafone,
    #completed-purchase-continue {
      min-width: ${rem('280px')};
    }
  }
`;

export default CompletedPurchaseStyles;
