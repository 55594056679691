import { Grid } from '@chakra-ui/react';
import Heading from '@vfuk/core-heading';
import _ from 'lodash';
import { rem } from 'polished';
import { FC, useMemo } from 'react';
import { useLocation } from '@reach/router';

import SWProductStrip from '@/components/pageBuilder/Elements/SWProductStrip';
import { SWProductStripProps } from '@/components/pageBuilder/Elements/SWProductStrip/SWProductStrip.types';

import { WrapSimpleGrid } from './SWProductList.styles';
import { SWProductListProps } from './SWProductList.types';
import {useSessionContext} from "@/contexts/UserSessionContext";

const SWProductList: FC<SWProductListProps> = ({ items = [] }: SWProductListProps) => {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const filter = params.get('filter');
    const { searchValue } = useSessionContext();

  const displayedItems = filter ? items?.filter(item => item.category.slug === filter) : items;

    const displayedItemsWithSearch = useMemo(() => {
        return searchValue.length ? displayedItems.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())) : displayedItems
    }, [searchValue]);

  const renderCards = (items: SWProductStripProps[]) => {
    if (items.length > 0) {
      return items
        .filter(
          item =>
            item.__typename === 'ContentfulMarketplaceMultipleProducts' ||
            (item.__typename === 'ContentfulMarketplaceProduct' && !item.includeOnly)
        )
        .map(card => <SWProductStrip key={card.id} {...card} />);
    }
    return <Heading level={3} justify="center" text="No Products Found" />;
  };

  return (
    <WrapSimpleGrid backgroundColor="#f4f4f4" p={`${rem('50px')} ${rem('16px')}`}>
      <Grid
        gap={{ base: rem('16px'), md: rem('20px') }}
        gridAutoRows="1fr"
        alignContent="center"
        maxWidth={{ base: rem('345px'), md: rem('1180px') }}
        margin="auto"
      >
        {renderCards(displayedItemsWithSearch.map(item => ({ ...item, monthly: { gross: _.random(100, true).toFixed(2) } })))}
      </Grid>
    </WrapSimpleGrid>
  );
};

export default SWProductList;
