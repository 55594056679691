import styled from '@emotion/styled';
import { rem } from 'polished';

import { ArticleCardWrapperProps } from './SWLoginModal.types';

export const LoginModalWrapper = styled.div`
  h3[class*='Headingstyle__Heading-sc'] {
    margin-top: ${rem('100px')};
    margin-bottom: ${rem('25px')};
    line-height: ${rem('48px')};
    color: ${({ theme }) => theme.color.primary2.default};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: ${rem('32px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      margin-bottom: ${rem('50px')};
    }

    p {
      font-size: ${rem('36px')};
      margin: 0;
      line-height: ${rem('48px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        font-size: ${rem('34px')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        font-size: ${rem('40px')};
      }
    }
  }

  [class*='Interactionstyle__BlankInteraction-sc'] {
    [class*='Containerstyle__ContainerContent-sc'] {
      [class*='ArticleCardstyle__ArticleCard-sc'] {
        [class*='CardBodystyle__CardBody-sc'] {
          position: absolute;
          width: 100%;
          bottom: ${rem('10px')};

          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            padding: 0 ${rem('48px')} ${rem('12px')};
          }

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
            bottom: ${rem('8px')};
            padding: 0 ${rem('20px')};
          }

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
            padding: 0 ${rem('50px')};
          }

          [class*='CardBodystyle__ContentWrapper-sc'] {
            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              margin-bottom: ${rem('30px')};
            }

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                theme,
              }) => theme.breakpoints.lg - 1}px) {
              margin-bottom: ${rem('56px')};
            }

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
              margin-bottom: ${rem('24px')};
            }

            p {
              font-size: ${rem('18px')};
              line-height: ${rem('24px')};

              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                  theme,
                }) => theme.breakpoints.lg - 1}px) {
                font-size: ${rem('14px')};
                line-height: ${rem('16px')};
              }
            }

            h2 {
              @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                font-size: ${rem('28px')};
                line-height: ${rem('31px')};
              }
            }
          }

          [class*='CardBodystyle__ButtonWrapper-sc'] {
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
              margin: ${rem('20px')} 0 ${rem('46px')};
            }

            button {
              border-width: ${rem('1px')};
              font-size: ${rem('20px')};
              padding: ${rem('15px')} 0;
              height: ${rem('50px')};
              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;

export const ArticleCardWrapper = styled.div<ArticleCardWrapperProps>`
  [class*='ArticleCardstyle__ArticleCard-sc'] {
    background: ${({ backgroundColor }) => backgroundColor};
    height: ${rem('426px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      height: ${rem('467px')};
    }
  }
  [class*='CardBodystyle__ButtonWrapper-sc'] {
    button {
      background-color: var(--white);
      color: #0d0d0d;
      padding: ${rem('12px')} ${rem('20px')};
      ${({ buttonStyle }) => {
        if (buttonStyle === 'secondary') {
          return 'border: solid 1px #0D0D0D;';
        }
      }};
    }
    button:hover {
      ${({ buttonStyle }) => {
        if (buttonStyle === 'primary') {
          return 'color: #0D0D0D; background: #d9d9d9; border-color: #d9d9d9;';
        } else if (buttonStyle === 'secondary') {
          return 'color: #ffffff; background: #313131; border-color: transparent;';
        }
      }};
    }
  }
`;
