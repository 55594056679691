import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const FlippingCardHeader = styled(Stack)``;

export const FlippingCardTitleWrapper = styled(Box)`
  && {
    min-height: ${rem('90px')};
  }
`;

export const FlippingCardTitle = styled(Heading)`
  && {
    font-weight: bold;
    font-size: ${rem('24px')};
    line-height: ${rem('30px')};
    margin-bottom: 0;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('28px')};
      line-height: ${rem('36px')};
    }
  }
`;

export const FCWrapper = styled.div<{ isFlipped: boolean }>`
  width: 100%;
  display: flex;
  min-height: ${rem('330px')};
  transform-style: preserve-3d;
  transition: transform 0.6s;
  flex-direction: column;
  align-items: center;
  ${({ isFlipped }) => {
    if (isFlipped) {
      return 'transform: rotateY(90deg);';
    }
  }};

  [class*='DetailsItem'] {
    :nth-of-type(1n + 3) {
      margin-block: ${rem('8px')} 0;
    }
  }

  [class*='Interactionstyle'] {
    width: 90%;
    border-radius: ${rem('6px')};

    &[id='VisitProductButton'] {
      margin-bottom: ${rem('20px')};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
`;

export const FlippedWrapper = styled.div<{ isFlipped: boolean }>`
  width: 100%;
  flex-direction: column;
  background-color: #333;
  color: var(--white);
  display: flex;
  align-items: center;
  padding: ${rem('20px')} 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
  [class*='Interactionstyle__Button-sc'] {
    &[id='FlipButton'] {
      color: var(--white);
      border-color: var(--white);
    }
  }

  ${({ isFlipped }) => {
    if (isFlipped) {
      return 'transform: rotateY(-90deg);';
    }
  }};
`;

export const FlippedDescription = styled(Text)`
  && {
    color: white;
    line-height: ${rem('24px')};
  }
`;

export const FlippedShortDescription = styled(Text)`
  && {
    line-height: ${rem('18px')};
  }
`;
