import Button from '@vfuk/core-button';
import Image from '@vfuk/core-image';
import LoadingSpinner from '@vfuk/core-loading-spinner';
import SelectInput from '@vfuk/core-select-input';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { startCase } from 'lodash';
import { FC, useState } from 'react';

import { DxlInformationPeriod } from '@/types/Product.types';

import DetailsAndPricing from '../../CardElements/DetailsAndPricing';
import InfoAndCta from '../../CardElements/InfoAndCta';
import { CardHeading, CTAWrapper, ImageWrap, LoadingWrapper } from '../../SWPricingCard.styles';
import { CardTypeProps } from '../../SWPricingCard.types';
import {
  ExpandableDescription,
  ExpandableWrapper,
  ExpandingCardHeader,
  ExpandingCardTitle,
  ExpandingCardTitleWrapper,
  FCWrapper,
  SelectWrapper,
} from './SWExpandingCard.styles';
import { DetailsAndPricingProps } from '../../CardElements/DetailsAndPricing/DetailsAndPricing.types';

const SWExpandingCard: FC<CardTypeProps> = ({ item, mainItem, itemStepName, shouldChangeTheme, mergedProduct }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedEdition, setSelectedEdition] = useState<DetailsAndPricingProps['selectedEdition']>();

  const { description, thumbnailIcon, detailAndPricing, dxlInformation } = item;
  const thumbnailIconSrc = getSrc(thumbnailIcon) ?? getImage(thumbnailIcon)?.placeholder?.fallback ?? '';

  const handleIsExpanded = () => setIsExpanded(!isExpanded);

  const options = item?.dxlInformation?.periods?.map((period: DxlInformationPeriod) => ({
    text: startCase(`${period.type} contract`.toLowerCase()),
    value: period.uuid,
  }));

  return (
    <>
      {dxlInformation ? (
        <CardHeading px={5}>
          <ExpandingCardHeader direction="row" spacing={5}>
            {thumbnailIconSrc && (
              <ImageWrap>
                <Image
                  alt={thumbnailIcon?.title ?? ''}
                  sm={{
                    src: thumbnailIconSrc,
                    width: '48px',
                    height: '48px',
                  }}
                  md={{
                    src: thumbnailIconSrc,
                    width: '64px',
                    height: '64px',
                  }}
                />
              </ImageWrap>
            )}
            <ExpandingCardTitleWrapper>
              <ExpandingCardTitle as="h2" color={shouldChangeTheme ? 'white' : 'mineShaft'}>
                {dxlInformation?.name}
              </ExpandingCardTitle>
            </ExpandingCardTitleWrapper>
          </ExpandingCardHeader>
        </CardHeading>
      ) : (
        <LoadingWrapper>
          <LoadingSpinner appearance="primary" size={5} />
        </LoadingWrapper>
      )}

      {dxlInformation && detailAndPricing && (
        <DetailsAndPricing
          itemStepName={itemStepName}
          item={item}
          color={shouldChangeTheme ? 'white' : undefined}
          useSimplePrice={mergedProduct}
          {...{ selectedEdition, setSelectedEdition }}
        />
      )}
      <SelectWrapper>
        {!!selectedEdition && dxlInformation?.periods && dxlInformation?.periods.length > 0 && (
          <SelectInput options={options || []} onChange={event => setSelectedEdition(event.target.value)} />
        )}
      </SelectWrapper>
      <FCWrapper isExpanded={isExpanded}>
        <ExpandableWrapper isExpanded={isExpanded}>
          <ExpandableDescription
            fontSize={{ base: 'md', md: 'lg' }}
            marginRight={5}
            marginLeft={5}
            mt={{ base: 5, md: 0 }}
          >
            {description?.description}
          </ExpandableDescription>
          <Button text={`Show ${isExpanded ? 'Less' : 'More'}`} onClick={handleIsExpanded} id={'ExpandButton'} />
        </ExpandableWrapper>
      </FCWrapper>
      <CTAWrapper>
        {item?.dxlInformation && (
          <InfoAndCta
            {...{ mainItem, itemStepName }}
            item={{ ...item, buttonsAction: 'updateBasketWithQty' }}
            buttonStyling={{
              backgroundColor: undefined,
              textColor: undefined,
            }}
            useSimplePrice={mergedProduct}
          />
        )}
      </CTAWrapper>
    </>
  );
};

export default SWExpandingCard;
