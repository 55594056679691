import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

export type UserConfigureBundleSliceProps = {
  currentStep: number;
  maxSteps: number;
};

const initialState: UserConfigureBundleSliceProps = {
  currentStep: 1,
  maxSteps: 1,
};

const userConfigureBundleSlice = createSlice({
  name: 'userConfigureBundle',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<UserConfigureBundleSliceProps['currentStep']>) => {
      state.currentStep = action.payload;
    },
    setMaxSteps: (state, action: PayloadAction<UserConfigureBundleSliceProps['maxSteps']>) => {
      state.maxSteps = action.payload;
    },
    resetStep: state => {
      state.currentStep = 1;
      state.maxSteps = 1;
    },
  },
});

const persistConfig = {
  key: `vf_${userConfigureBundleSlice.name}`,
  version: 1,
  storage: storageSession,
};

export const { setCurrentStep, setMaxSteps, resetStep } = userConfigureBundleSlice.actions;
export const UserConfigureBundleReducer = userConfigureBundleSlice.reducer;

export const UserConfigureBundlePersistReducer = persistReducer<UserConfigureBundleSliceProps>(
  persistConfig,
  UserConfigureBundleReducer
);
