import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const ButtonsWrapper = styled(Flex)``;

export const CancelButton = styled(ButtonsWrapper)`
  [class*='Buttonstyle__Button-sc'],
  [class*='ButtonWithIconstyle__ButtonWithIcon-sc'] {
    cursor: pointer;
    font-size: ${rem('20px')};
    min-width: auto;
    width: ${rem('50px')};
    height: ${rem('50px')};

    [class*='Iconstyle__Icon'] {
      margin-right: 0;

      @media only screen and (min-width: ${rem('768px')}) {
        margin-right: ${rem('4px')};
      }

      svg {
        width: ${rem('20px')};
        height: ${rem('20px')};
      }
    }

    @media only screen and (max-width: ${rem('1440px')}) {
      font-size: 0;
    }

    @media only screen and (min-width: ${rem('1441px')}) {
      width: ${rem('180px')};
    }
  }
`;

export const titleBusinessNeedsStyles = {
  justify: 'center',
  mt: { base: rem('24px'), md: 0 },
  px: { md: rem('68px') },
  w: 'full',
  sx: {
    h2: {
      fontSize: { base: '4xl', md: 10 },
      lineHeight: { base: rem('48px'), md: rem('46px'), lg: rem('48px') },
      mb: 0,
      whiteSpace: { base: 'normal', lg: 'pre-wrap' },
    },
    h3: {
      fontSize: { base: '2xl', md: rem('29px') },
      letterSpacing: { base: 0, md: 'tight' },
      lineHeight: rem('48px'),
      mb: 0,
      mt: { base: 5, md: rem('38px') },
    },
    p: {
      m: { base: `0 0 ${rem('12px')}`, md: `0 0 ${rem('25px')}` },
      '&:last-child': {
        m: 0,
      },
    },
    'b, strong': {
      letterSpacing: 'wide',
    },
  },
};
