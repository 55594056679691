import { Helmet } from 'react-helmet';

type IMetadata = {
  name?: HTMLMetaElement['name'];
  rel?: HTMLLinkElement['rel'];
  type?: string;
  content: string;
  metadataTag: 'meta' | 'title' | 'link';
};

type ISEOProps = {
  metadata: IMetadata[];
};

export const SEO = ({ metadata }: ISEOProps) => (
  <Helmet>
    {metadata.map(({ metadataTag, ...data }, key) => {
      switch (metadataTag) {
        case 'title': {
          const { content } = data;
          return <title key={`${metadataTag.toString()}-${key}`}>{content}</title>;
        }
        case 'link': {
          const { content, rel, type = '' } = data;
          return <link key={`${metadataTag.toString()}-${key}`} rel={rel} href={content} type={type} />;
        }
        case 'meta': {
          const { content, name } = data;

          if (data.name?.includes('og:')) {
            return <meta key={`${metadataTag.toString()}-${key}`} property={name} content={content} />;
          }
          return <meta key={`${metadataTag.toString()}-${key}`} name={name} content={content} />;
        }
        default:
          return <></>;
      }
    })}
  </Helmet>
);
