import { Link } from '@chakra-ui/react';
import { IconFacebook, IconInstagram, IconLinkedin, IconTwitter, IconYoutube } from '@vfuk/brand-lofi-icons';
import Footer from '@vfuk/core-footer';
import Paragraph from '@vfuk/core-paragraph';
import SimpleGrid from '@vfuk/core-simple-grid';
import { FC } from 'react';

import SWFooterIconAndTextItem from '@/components/pageBuilder/Elements/SWFooterIconAndTextItem/SWFooterIconAndTextItem';
import { useSWFooter } from '@/hooks/useSWFooter';

import {
  FooterWrapper,
  IconsWrapper,
  IconsWrapperSocial,
  LinksWrapper,
  LogoWrapper,
  LogoWrapperMobile,
  TitleWrapper,
} from './SWFooter.styles';
import { IconProps } from './SWFooter.types';

const SWFooter = () => {
  const { companyInfo, social, legalLinks, linkGroups, copyrightText, icons } = useSWFooter();

  return (
    <>
      {legalLinks && (
        <FooterWrapper background="incomplete.default" data-testid="sw-footer">
          <Paragraph size={2} justify="center">
            {companyInfo}
          </Paragraph>
          <TitleWrapper>
            <SimpleGrid
              layout={{
                sm: [12],
                lg: [4, 8],
              }}
              spacing={3}
            >
              {icons && <Paragraph size={2}>contact us</Paragraph>}
              {social && <Paragraph size={2}>follow us</Paragraph>}
            </SimpleGrid>
          </TitleWrapper>
          <LinksWrapper>
            <SimpleGrid
              layout={{
                sm: [12],
                lg: [4, 7, 1],
              }}
              spacing={3}
            >
              {social && (
                <>
                  <Paragraph size={2}>follow us</Paragraph>
                  <IconsWrapperSocial>
                    {social.map(icon => (
                      <Link href={icon.url} key={icon.id} aria-label={icon.icon}>
                        {RenderSocialIcon(icon)}
                      </Link>
                    ))}
                    <LogoWrapperMobile>
                      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" width="51">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0c3.052 0 5.943.683 8.531 1.906-.345-.108-.885-.148-1.402-.148-4.163.014-8.752 1.794-12.102 4.568-3.434 2.85-6.121 7.644-6.109 12.624.024 7.531 5.742 12.222 11.237 12.204 6.79-.023 10.802-5.6 10.785-10.684-.016-5.084-2.78-8.759-8.871-10.242a2.093 2.093 0 0 1-.024-.326c-.013-3.84 2.88-7.224 6.515-7.983C35.323 5.127 40 12.017 40 20Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </LogoWrapperMobile>
                  </IconsWrapperSocial>
                </>
              )}
              {icons && (
                <>
                  <Paragraph size={2}>contact us</Paragraph>
                  <IconsWrapper>
                    {icons.map((icon, index) => (
                      <SWFooterIconAndTextItem key={icon.title} {...icon} />
                    ))}
                  </IconsWrapper>
                </>
              )}
              <LogoWrapper>
                <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" width="51">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0c3.052 0 5.943.683 8.531 1.906-.345-.108-.885-.148-1.402-.148-4.163.014-8.752 1.794-12.102 4.568-3.434 2.85-6.121 7.644-6.109 12.624.024 7.531 5.742 12.222 11.237 12.204 6.79-.023 10.802-5.6 10.785-10.684-.016-5.084-2.78-8.759-8.871-10.242a2.093 2.093 0 0 1-.024-.326c-.013-3.84 2.88-7.224 6.515-7.983C35.323 5.127 40 12.017 40 20Z"
                    fill="#fff"
                  ></path>
                </svg>
              </LogoWrapper>
            </SimpleGrid>
          </LinksWrapper>
          <Footer
            legalLinks={legalLinks}
            linkGroups={linkGroups}
            copyrightText={copyrightText}
            partnerLogo={{
              image:
                'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Apple_logo_grey.svg/640px-Apple_logo_grey.svg.png',
            }}
          />
        </FooterWrapper>
      )}
    </>
  );
};

export const RenderSocialIcon: FC<IconProps> = icon => {
  switch (icon.icon) {
    case 'facebook':
      return <IconFacebook inverse />;
    case 'twitter':
      return <IconTwitter inverse />;
    case 'instagram':
      return <IconInstagram inverse />;
    case 'youtube':
      return <IconYoutube inverse />;
    case 'linkedin':
      return <IconLinkedin inverse />;
    default:
      return <></>;
  }
};

export default SWFooter;
