import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import en from '@/languages/EN/en';
import pt from '@/languages/PT/pt';

export const availableLangs = ['en', 'pt'];

const resources = {
  en: {
    translation: en,
  },
  pt: {
    translation: pt,
  },
};

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({ resources, fallbackLng: 'en', interpolation: { escapeValue: false } });

export default i18n;
