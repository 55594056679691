import { Box, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { CardWrapperProps } from './SWRelatedProducts.types';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const WrapCards = styled(Grid)`
  row-gap: ${rem('20px')};
  padding: 0 ${rem('15px')};
  margin: 0 auto;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: ${rem('20px')};
    justify-items: center;
    max-width: ${rem('1180px')};
    padding: 0 ${rem('16px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0;
  }
`;

export const CardWrapperElement = styled.div<CardWrapperProps>`
  display: block;
  [class*='Containerstyle__Container'][class*='Interactionstyle__BlankInteraction'] {
    background-color: ${({ cardsBackgroundColor }) => cardsBackgroundColor || `#f8f8f8`};

    [class*='CardPillAndIconSlotstyle__CardPillAndIconSlot'] {
      [class*='Pillstyle__Pill'] {
        background-color: ${({ cardsBackgroundColor }) => cardsBackgroundColor || `#f8f8f8`};
      }
    }
  }
`;

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rem('32px')} 0 ${rem('70px')};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    margin: ${rem('50px')} 0;
  }

  a {
    color: ${({ theme }) => theme.color.primary2.default};
    font-size: ${rem('20px')};
    width: 100%;
    max-width: ${rem('230px')};
    height: ${rem('51px')};
  }
`;

export const HeaderWrapper = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    padding: ${rem('14px')} ${rem('26px')};
  }
  [class*='Headingstyle__Heading'] {
    &:last-child {
      margin-bottom: revert;
    }
    color: var(--mineShaft);
    font-size: ${rem('40px')};
    line-height: ${rem('48px')};
    margin: ${rem('40px')} 0 ${rem('48px')};
  }
`;
