import FiftyFiftyBanner from '@vfuk/core-fifty-fifty-banner';
import { getImage } from 'gatsby-plugin-image';
import omit from 'lodash/omit';
import { FC } from 'react';
import { ContentContainer, GSButton, GuidedSellingBannerWrapper } from './styles/SWGuidedSellingBanner.styles';
import { SWGuidedSellingBannerProps } from './types/SWGuidedSellingBanner.types';

const SWSectionGuidedSellingBanner: FC<SWGuidedSellingBannerProps> = (data: SWGuidedSellingBannerProps) => {
  const {
    backgroundImage,
    backgroundOverrideClass,
    textColor,
    button,
    subtitle,
    buttonColor,
    title,
    backgroundImageMobileView,

    customButtonZIndex,
  } = data;

  const backgroundImageSrc = getImage(backgroundImage);
  const backgroundImageMobileViewSrc = getImage(backgroundImageMobileView);
  // Ignoring types that are not in the default of the FiftyFiftyBanner component
  const props = omit(data, [
    'id',
    'backgroundColor',
    'customClass',
    'heading',
    'name',
    'richTextBody',
    'primaryButton',
  ]);

  return (
    <ContentContainer>
      <GuidedSellingBannerWrapper
        {...(backgroundOverrideClass && { className: backgroundOverrideClass })}
        backgroundColor={textColor}
        data-selector="fiftyFifty banner"
        bodyPosition="right"
        backgroundImage={backgroundImageSrc}
        backgroundImageMobileView={backgroundImageMobileViewSrc}
        primaryButton={buttonColor}
      >
        <FiftyFiftyBanner
          {...props}
          heading={
            (title && {
              text: title,
              level: 2,
            }) ||
            undefined
          }
          text={subtitle}
          background={backgroundImageMobileViewSrc || backgroundImageSrc}
          backgroundImage={{
            sm: {
              src: backgroundImageMobileViewSrc,
            },
            md: {
              src: backgroundImageSrc,
            },
          }}
          primaryButton={
            button ? { text: button, href: 'https://gr-app3-dev.app-stage.digitalretail.vodafone.com/' } : null
          }
        />
        {!button && <GSButton id="gs-app" customIndex={customButtonZIndex} />}
      </GuidedSellingBannerWrapper>
    </ContentContainer>
  );
};

export default SWSectionGuidedSellingBanner;
