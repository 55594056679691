import { useModalContext } from '@/hooks/modal/useModal';
import { retrieveProduct } from '@/services/product.services';
import { addToBasket, clearPostLoginEvent, setPostLoginEvent, useAppDispatch, useAppSelector } from '@/store';
import axios from 'axios';
import { FC, useEffect } from 'react';
import usingOpcoUrl from './prefixHelper';
import { withPrefix } from 'gatsby';

export interface AddToBasketEvent extends Event {
  detail: [
    {
      productId: string;
      qty: number;
    }
  ];
}
export const GlobalEvents: FC = () => {
  const dispatch = useAppDispatch();

  const { user, postLoginEvent } = useAppSelector(({ userSession }) => userSession);

  const { toggleModal, setErrorInfo } = useModalContext();

  async function handleAddToBasketEvent(event: Event) {
    let redirectPath;

    if (!user?.userId) {
      toggleModal(true, 'Login');
      dispatch(setPostLoginEvent({ detail: (event as AddToBasketEvent).detail }));
      return;
    }

    const customEvent = event as AddToBasketEvent;

    const productsPromises = customEvent.detail.map(async product => {
      const res = await retrieveProduct(product.productId);

      if (!axios.isAxiosError(res) && res.status === 200 && res.data && res.data.id) {
        if (
          res.data.contentfulInformation?.msftProduct &&
          !res.data.contentfulInformation?.slug.includes('apphelp-microsoft-365-migration')
        ) {
          redirectPath = withPrefix(`${res.data.contentfulInformation.slug}/step-0`);
        }

        const entry = {
          ...res.data.contentfulInformation,
          dxlInformation: res.data.dxlInformation,
          __typename: 'ContentfulMarketplaceProductBundle',
          id: res.data.id,

          thumbnailIcon: res.data.contentfulInformation
            ? 'https:' + res.data.contentfulInformation.thumbnailIcon.file.url

            : null,
        } as any; //Any type due to some, non required for the basket, missing data in the response

        dispatch(
          addToBasket({
            ...entry,
            quantity: product.qty,
          })
        );
      } else if (axios.isAxiosError(res)) {
        setErrorInfo({

          statusType: res.response?.data?.error,
          statusCode: res.response?.data?.code || res.response?.status,
          requestUrl: 'catalogues',
        });
        toggleModal(true, 'Error');
      }
    });

    await Promise.all(productsPromises);

    dispatch(clearPostLoginEvent());
    const finishEvent: CustomEvent = new CustomEvent('AddToBasketFinished');
    document.dispatchEvent(finishEvent);

    if (redirectPath) {
      usingOpcoUrl(redirectPath, true);
    }
  }

  useEffect(() => {
    document.addEventListener('AddToBasketEvent', handleAddToBasketEvent);
    return () => document.removeEventListener('AddToBasketEvent', handleAddToBasketEvent);
  }, []);

  useEffect(() => {
    if (user?.userId && postLoginEvent) {
      const event: CustomEvent = new CustomEvent('AddToBasketEvent', postLoginEvent);
      document.dispatchEvent(event);
    }
  }, [user?.userId]);

  return <></>;
};
