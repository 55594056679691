import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { rem } from 'polished';

const Container = forwardRef<FlexProps, 'div'>(({ children, ...props }, ref) => (
  <Flex ref={ref} direction="column" align="center" w="full" {...props}>
    <Flex
      direction="column"
      alignContent="center"
      w="full"
      maxW={{
        base: rem('1180px'),
      }}
      px={4}
    >
      {children}
    </Flex>
  </Flex>
));

export default Container;
