import { createContext, useCallback, useContext, useReducer } from 'react';

import { reducer } from './reducer';
import { ACTIONS, ContextTypes, InitialState, ModalTypes } from './types';

export const initialState: InitialState = {
  isModalOpen: false,
  isLoginModalOpen: false,
  isErrorModalOpen: false,
  isLoadingCheckoutModalOpen: false,
  isMsDomainModalOpen: false,
  errorInfo: {
    statusType: null,
    statusCode: null,
    requestUrl: null,
  },
};

export const ModalContext = createContext<ContextTypes>({
  ...initialState,
  toggleModal: action => action,
  setErrorInfo: action => action,
});

export const useModalContext = () => useContext(ModalContext);

export const useModal = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleModal = useCallback((isModalOpen: InitialState['isModalOpen'], modalType: ModalTypes) => {
    dispatch({
      type: ACTIONS.TOGGLE_MODAL,
      payload: {
        isModalOpen,
        modalType,
      },
    });
  }, []);

  const setErrorInfo = useCallback((errorInfo: InitialState['errorInfo']) => {
    dispatch({
      type: ACTIONS.SET_ERROR_INFO,
      payload: {
        errorInfo,
      },
    });
  }, []);

  return {
    ...state,
    toggleModal,
    setErrorInfo,
  };
};
