import Container from '@/components/Global/Container';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const StepWrapper = styled(Container)``;
export const HeadingWrapper = styled(Box)``;
export const HeadingText = styled(Box)``;

export const NavigationWrapper = styled(Flex)``;

export const ButtonsWrapper = styled(Flex)`
  [class*='Buttonstyle__Button-sc'],
  [class*='ButtonWithIconstyle__ButtonWithIcon-sc'] {
    border: ${rem('1px')} solid;
    font-size: ${rem('20px')};
    min-width: auto;
    width: ${rem('164px')};
    height: ${rem('50px')};

    &:hover {
      border: ${rem('1px')} solid;
    }

    @media only screen and (min-width: ${({ theme }) => rem(theme.breakpoints.md)}) {
      width: ${rem('126px')};
    }

    @media only screen and (min-width: ${rem('992px')}) {
      width: ${rem('181px')};
    }

    [class*='Iconstyle__Icon'] {
      svg {
        width: ${rem('20px')};
        height: ${rem('20px')};
      }
    }
  }
`;

export const CancelButton = styled(Box)`
  [class*='Buttonstyle__Button-sc'],
  [class*='ButtonWithIconstyle__ButtonWithIcon-sc'] {
    background: #f4f4f4;
  }
`;

export const NextButtonWrapper = styled(Box)``;

export const StepperWrapper = styled.div<{ stepsLength: number }>`
  [class*='FunctionalStepperstyle__StepContainer-sc'] {
    &:after {
      top: ${rem('24px')};
      left: calc(50% + ${rem('24px')});
      width: calc(100% - ${rem('16px')});
    }

    &:first-of-type {
      justify-content: flex-start;

      @media only screen and (min-width: ${rem('480px')}) {
        justify-content: center;
      }

      &:after {
        left: ${rem('48px')};

        @media only screen and (min-width: ${rem('480px')}) {
          width: calc(100% - ${rem('48px')});
          left: calc(50% + ${rem('24px')});
        }
      }
    }
    &:last-child {
      justify-content: flex-end;

      @media only screen and (min-width: ${rem('480px')}) {
        justify-content: center;
      }
    }
    &:nth-last-of-type(2) {
      &:after {
        width: ${({ stepsLength }) => {
          if (stepsLength > 2) {
            return `calc(100% - ${rem('16px')})`;
          } else {
            return `calc(100% + ${rem('75px')})`;
          }
        }};
        @media only screen and (min-width: ${rem('480px')}) {
          width: calc(100% - ${rem('48px')});
        }
      }
    }
  }

  [class*='FunctionalStepperstyle__Step-sc'] {
    width: ${rem('48px')};
    height: ${rem('48px')};
  }

  [class*='FunctionalStepperstyle__TextWrapper-sc'] {
    width: ${rem('75px')};

    @media only screen and (min-width: ${({ theme }) => rem(theme.breakpoints.md)}) {
      line-height: ${rem('20px')};
      margin-top: ${rem('6px')};
      width: ${rem('116px')};
    }
  }

  [class*='FunctionalStepperstyle__StepperMobileControlsContainer-sc'] {
    margin-top: ${rem('7px')};

    [class*='Headingstyle__Heading-sc'] {
      color: ${({ theme }) => theme.colors?.mineShaft};
      line-height: ${rem('20px')};
    }
  }
`;

export const StepperBox = styled(Box)``;

export const HeadingTitleProps = {
  color: 'mineShaft',
  fontSize: { base: 7, md: 10 },
  fontWeight: 'bold',
  letterSpacing: 'wide',
  lineHeight: { base: 8, md: rem('46px') },
  mb: '0',
};

export const HeadingSubtitleProps = {
  color: 'mineShaft',
  fontSize: { base: 'xl', md: '2xl' },
  lineHeight: { base: 7, md: rem('30px') },
  mt: { md: 2 },
  mb: 0,
};
