import Button from '@vfuk/core-button';
import Heading from '@vfuk/core-heading';
import { FC } from 'react';

import { addLinkTestId } from '../../../../helpers/addTestId';
import SWFeatureCard from '../../Elements/SWFeatureCard';
import { Container, NoHeading, WrapButton, WrapCards, WrapHeading, Wrapper } from './SWSectionFeatureCard.styles';
import { SWSectionFeatureCardProps } from './SWSectionFeatureCard.types';
import usingOpcoUrl from '@/helpers/prefixHelper';

const SWSectionFeatureCard: FC<SWSectionFeatureCardProps> = ({
  featureCards,
  heading,
  backgroundColor,
  primaryButton,
  gridLayout,
}: SWSectionFeatureCardProps) => {
  const isGridLayout = gridLayout === null || gridLayout === true;

  return (
    <Wrapper backgroundColor={backgroundColor || `#F4F4F4`}>
      <Container>
        {heading ? (
          <WrapHeading>
            <Heading
              level={2}
              justify={'center'}
              text={heading}
              data-testid={addLinkTestId('productFeatureSectionHeading', heading)}
            />
          </WrapHeading>
        ) : (
          <NoHeading />
        )}
        <WrapCards>
          {featureCards.map((card, index) => (
            <SWFeatureCard key={card.id} gridLayout={isGridLayout} {...card} />
          ))}
        </WrapCards>
        {primaryButton && (
          <WrapButton>
            <Button text={primaryButton.label} appearance="alt1" href={usingOpcoUrl(primaryButton.url)} />
          </WrapButton>
        )}
      </Container>
    </Wrapper>
  );
};

export default SWSectionFeatureCard;
