import { BottomTray } from '@source-web/bottom-tray';
import { Heading } from '@source-web/heading';
import { MatchMedia } from '@source-web/match-media';

import BasketPrices from './BasketPrices';
import { BasketSummaryProps } from './BasketSummary.types';

import {
  BasketContainer,
  BasketSummaryHeading,
  BasketSummaryRoot,
  BasketSummaryTemplateContainer,
  BasketSummaryWrapper,
} from './BasketSummary.styles';
import BasketNavigation from './BasketNavigation';

const BasketSummary = ({ isOpen = false, title, buttons, ...props }: BasketSummaryProps) => {
  const basketRootID = 'basket-summary-tray';
  return (
    <>
      <BasketSummaryRoot id={basketRootID} />
      <BottomTray isOpen={isOpen} srName="BasketSummaryElement" isClosable={false} rootElement={basketRootID}>
        <BasketSummaryWrapper>
          <BasketSummaryTemplateContainer>
            <BasketContainer>
              <BasketSummaryHeading>
                <MatchMedia breakpoint="sm">
                  <Heading appearance="primary" text={title} size={1} weight={3} noMargin />
                </MatchMedia>
                <MatchMedia breakpoint="md" andAbove>
                  <Heading appearance="primary" text={title} level={2} size={2} weight={3} noMargin />
                </MatchMedia>
              </BasketSummaryHeading>
              <BasketPrices {...props} />
            </BasketContainer>
            <BasketNavigation {...buttons} />
          </BasketSummaryTemplateContainer>
        </BasketSummaryWrapper>
      </BottomTray>
    </>
  );
};

export default BasketSummary;
