import styled from '@emotion/styled';
import { rem } from 'polished';

export const ProductIconWrapper = styled.div`
  display: flex;
  gap: ${rem('6px')};
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
    line-height: ${rem('12px')};
  }
`;

export const ProductGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  min-height: ${rem('160px')};
  grid-gap: ${rem('4px')};
`;

export const Subtitle = styled.h3`
  font-size: ${rem('18px')};
  font-weight: bolder;
  margin: ${rem('12px')} 0;
`;
