import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.primary2.default};
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${rem('16px')} 0;
`;

export const Container = styled.div`
  display: flex;
  max-width: ${rem('1180px')};
  padding: ${rem('40px')} ${rem('16px')} ${rem('32px')};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    flex-direction: column;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-wrap: wrap;
    padding: ${rem('42px')} ${rem('16px')} ${rem('35px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    padding: ${rem('40px')} ${rem('16px')} ${rem('70px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding: ${rem('40px')} 0 ${rem('71px')};
  }
`;

export const WrapImage = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: ${rem('31px')};
    padding-left: ${rem('10px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    padding-top: ${rem('34px')};
    padding-left: ${rem('26px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding-top: ${rem('38px')};
    padding-left: ${rem('34px')};
  }

  picture {
    display: block;
    width: ${rem('300px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
        theme.breakpoints.lg - 2}px) {
      width: 100%;
      max-width: ${rem('316px')};
      min-width: ${rem('242px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
      width: ${rem('410px')};
    }

    img {
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 2}px) and (max-width: ${({ theme }) =>
          theme.breakpoints.lg - 2}px) {
        &:nth-of-type(1) {
          display: none;
        }
      }
    }
  }
`;
