import { ContentfulInformation, DxlInformation, DxlInformationPrice, Product } from '@/types/Product.types';

import formatCurrency from './formatCurrency';

type PickPriceProps = Required<Pick<Product, 'dxlInformation'>>['dxlInformation']['price'] | undefined;

export const retrievePrice = (price: PickPriceProps, type: DxlInformationPrice['name']) =>
  price?.find(item => item.name === type)?.price ?? 0;

export const retrievePriceByTypeOrFirstOne = (price: PickPriceProps, type: DxlInformationPrice['name']) => {
  const priceByType = price?.find(item => item.name === type)?.price;
  if (!priceByType) {
    return price?.length && price.length > 0 ? price[0].price : 0;
  }
  return priceByType;
};

export const pickCostOne = (price: PickPriceProps) => price?.find(item => item?.name === 'Cost1');
export const pickCostTwo = (price: PickPriceProps) => price?.find(item => item?.name === 'Cost2');

export const costOnePrice = (price: PickPriceProps) => formatCurrency(price ? pickCostOne(price)?.price : 0);
export const costTwoPrice = (price: PickPriceProps) => formatCurrency(price ? pickCostTwo(price)?.price : 0);

export const costOneUnit = (price: PickPriceProps) => price && pickCostOne(price)?.unit;
export const costTwoUnit = (price: PickPriceProps) => price && pickCostTwo(price)?.unit;

export const lowestEditionPrice = (
  data: DxlInformation | undefined,
  contentfulData: ContentfulInformation | undefined
) => {
  let editionsPrices;
  if (contentfulData) {
    const contentfulEditions = contentfulData.editionIds;

    if (contentfulEditions) {
      const contentfulEditionsTrimmed = contentfulEditions.map(edition => edition.trim());
      const filteredEditions = data?.editions?.filter(edition => contentfulEditionsTrimmed.includes(edition.id));
      editionsPrices = filteredEditions?.map(edition => edition?.price?.filter(item => item.name === 'Cost1')[0]);
    }
  }

  if (!editionsPrices) {
    editionsPrices = data?.editions?.map(edition => edition?.price?.filter(item => item.name === 'Cost1')[0]);
  }

  if (editionsPrices) {
    const prices = editionsPrices.filter(item => item && item.price > 0).map(item => item.price);
    if (prices.length > 0) {
      return Math.min(...prices);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};
