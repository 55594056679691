import { rem } from 'polished';
import styled from 'styled-components';

import { TButtonWrapper } from './SWHeroBannerWithSubHeader.types';

type TPossibleTextPositions = 'flex-end' | 'flex-start' | 'center';

export const HeroBannerContainer = styled.div<{ textPosition: TPossibleTextPositions }>`
  height: ${rem('330px')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ textPosition }) => textPosition};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(225deg, #f40000 0%, #660000 100%);
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const BannerImage = styled.img<{ textPosition: TPossibleTextPositions }>`
  position: absolute;
  height: ${rem('230px')};
  object-fit: contain;
  width: 30%;
  ${({ textPosition }) => {
    if (textPosition === 'center') {
      return 'display: none;';
    }
    if (textPosition === 'flex-end') {
      return 'left: 15%;';
    }
    if (textPosition === 'flex-start') {
      return 'right: 15%;';
    }
  }};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 40%;
    ${({ textPosition }) => {
      if (textPosition === 'center') {
        return 'display: none;';
      }
      if (textPosition === 'flex-end') {
        return 'left: 10%;';
      }
      if (textPosition === 'flex-start') {
        return 'right: 10%;';
      }
    }};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: relative;
    height: ${rem('180px')};
    margin-top: ${rem('30px')};
    width: 85%;
    left: 0;
    right: 0;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ContentWrapper = styled.div<{ textColor: 'white' | 'black'; textPosition: TPossibleTextPositions }>`
  display: flex;
  flex-direction: column;
  color: ${({ textColor }) => textColor};
  justify-content: center;
  line-height: ${rem('24px')};
  height: 100%;
  ${({ textPosition }) => {
    if (textPosition === 'center') {
      return 'align-items: center; text-align: center;';
    }
  }};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    text-align: center;
    align-items: center;
  }
`;
export const Header = styled.p`
  font-size: ${rem('40px')};
  max-width: ${rem('400px')};
  z-index: 3;
`;
export const Description = styled.p`
  font-size: ${rem('18px')};
  width: ${rem('300px')};
  z-index: 3;
`;

export const ButtonWrapper = styled.div<TButtonWrapper>`
  width: ${rem('220px')};
  z-index: 3;
  margin: ${rem('10px')} 0;
  [class*='Buttonstyle__Button-sc'] {
    width: 100%;
    padding: ${rem('16px')} 0;

    ${({ appearance }) => {
      if (appearance === 'secondary') {
        return 'background: #666;';
      }
    }};
    font-size: ${rem('20px')};
    line-height: ${rem('24px')};
    height: ${rem('50px')};
  }
`;
