import styled from '@emotion/styled';
import { rem } from 'polished';

export const PortfolioDisplayHeaderWrapper = styled.div`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.lg - 1}px) {
    max-width: ${rem('488px')};
  }
  h2 {
    line-height: ${rem('33px')};
    margin-bottom: ${rem('12px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('40px')};
      line-height: ${rem('48px')};
      margin-bottom: ${rem('16px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      margin-bottom: ${rem('7px')};
    }
  }

  h3 {
    margin-bottom: ${rem('8px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('10px')};
      line-height: ${rem('11px')};
      margin-bottom: ${rem('8px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      line-height: ${rem('21px')};
      margin-bottom: 0;
    }
  }

  [class*='Paragraphstyle__Paragraph'] {
    font-size: ${rem('18px')};
    line-height: ${rem('26px')};
    margin: ${rem('16px')} 0;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      min-height: ${rem('144px')};
      font-size: ${rem('16px')};
      line-height: ${rem('24px')};
      margin: ${rem('12px')} 0 0;
    }
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${rem('450px')};

  picture {
    width: auto;
    margin-right: ${rem('22px')};
    margin-bottom: ${rem('10px')};
    > img {
      height: ${rem('40px')};
    }
  }
`;
