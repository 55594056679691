import Button from '@vfuk/core-button';
import Heading from '@vfuk/core-heading';
import { withPrefix } from 'gatsby';
import { FC } from 'react';

import { OPCOOptions } from '@/types/Services.types';

import { Product } from '../../../../types/Product.types';
import SWProductCard from '../../Elements/SWProductCard';
import { CardWrapperElement, HeaderWrapper, WrapButton, WrapCards, Wrapper } from './SWRelatedProducts.styles';
import { CardWrapperProps, SWRelatedProductsProps } from './SWRelatedProducts.types';
import usingOpcoUrl from '@/helpers/prefixHelper';
const OPCO = process.env.GATSBY_OPCO;

const CardWrapper: FC<CardWrapperProps> = ({ children, cardsBackgroundColor }) => {
  return <CardWrapperElement cardsBackgroundColor={cardsBackgroundColor}>{children}</CardWrapperElement>;
};

const SWRelatedProducts: FC<SWRelatedProductsProps> = ({
  items = [],
  name,
  showHeading,
  backgroundColor,
  cardsBackgroundColor,
}: SWRelatedProductsProps) => {
  const renderCards = (items: Product[]) => {
    if (items && items.length > 0) {
      return items.map(card => (
        <CardWrapper cardsBackgroundColor={cardsBackgroundColor} key={card.id}>
          <SWProductCard {...card} />
        </CardWrapper>
      ));
    }
    return (
      <CardWrapper>
        <Heading level={3} justify="center" text="No Products Found" />
      </CardWrapper>
    );
  };

  return (
    <Wrapper backgroundColor={backgroundColor || '#f4f4f4'}>
      {showHeading && (
        <HeaderWrapper>
          <Heading level={3} justify={'center'} text={name} />
        </HeaderWrapper>
      )}
      <WrapCards
        {...(items && items.length > 1 && { gridTemplateColumns: { md: 'repeat( auto-fit, minmax(320px, 1fr) )' } })}
      >
        {renderCards(items)}
      </WrapCards>
      <WrapButton>
        {OPCO !== OPCOOptions.PORTUGAL && (
          <Button
            text="See All Products"
            href={usingOpcoUrl(withPrefix('marketplace/product-list'))}
            appearance="secondary"
          />
        )}
      </WrapButton>
    </Wrapper>
  );
};

export default SWRelatedProducts;
