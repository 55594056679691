import { rem } from 'polished';
import styled from 'styled-components';

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${rem('500px')};
`;

export const StepsHeader = styled.div`
  display: flex;
  margin-bottom: ${rem('20px')};
  width: 100%;
`;

export const StepContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-weight: ${props => (props.isActive ? 'bold' : 'normal')};
  margin-right: ${rem('20px')};

  &:last-child {
    margin-right: 0;
  }
`;

export const StepIcon = styled.div<{ isActive: boolean; completed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem('48px')};
  height: ${rem('48px')};
  border-radius: 50%;
  background-color: ${props => (props.isActive ? '#333' : '#222')};
  margin-bottom: ${rem('8px')};

  svg {
    ${({ completed, isActive }) => {
      if (isActive) {
        return `stroke: #FFF`;
      } else if (completed) {
        return `stroke: #3C9A35;`;
      }
      return `stroke: #666666;`;
    }}
  }

  ${({ completed, isActive }) => {
    if (isActive) {
      return `border: 2px solid #007C92;`;
    } else if (completed) {
      return `border: 2px solid #3C9A35;`;
    }
    return `border: 2px solid #666666;`;
  }}
`;

export const StepLabel = styled.div`
  white-space: nowrap;
  font-size: ${rem('18px')};
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepConnector = styled.div<{ isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: ${rem('2px')};
  background-color: ${props => (props.isActive ? '#3C9A35' : '#666666')};
  top: 50%;
  transform: translateY(-50%) translateX(50%);
  z-index: -1;
`;
