import { FC, PropsWithChildren } from 'react';

import { ModalContext, useModal } from './useModal';

const ModalProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const modal = useModal();

  return <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>;
};

export default ModalProvider;
