import { extendTheme, ThemeConfig } from '@chakra-ui/react';

import foundations from './foundations';

const config: ThemeConfig = {
  cssVarPrefix: 'vodafone',
};

const theme = {
  ...foundations,
  config,
};

export default extendTheme(theme);
