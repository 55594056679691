import { rem } from 'polished';
import styled from 'styled-components';

export const IconAndTextWrapper = styled.div<{ redirectUrl: string | undefined; iconSize: number | undefined }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.redirectUrl ? 'pointer' : 'default')};
  margin-right: ${rem('10px')};
  > span {
    margin-right: ${rem('20px')};
    svg {
      width: ${props => (props.iconSize ? rem(`${props.iconSize}px`) : rem('20px'))};
      height: ${props => (props.iconSize ? rem(`${props.iconSize}px`) : rem('20px'))};
    }
  }

  [class*='Headingstyle__Heading-sc'] {
    font-size: ${rem('18px')};
    margin-top: ${rem('5px')};
  }

`;
