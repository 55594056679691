import { Box, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Stack } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContentfulRichText from '@source-web/contentful-rich-text';
import Icon from '@vfuk/core-icon';

import { RichText } from '@/types/Product.types';
import RadioAsCheckboxGroup from '../RadioAsCheckboxGroup';

import { SWConfigureBundleOptionSecondaryOption } from '../SWConfigureBundleOptions.types';
import { checkboxGroupStyles, tooltipBoxStyles, tooltipContentStyles } from './BundleSecondaryOption.styles';

interface IBundleSecondaryOption {
  tooltipText?: RichText;
  secondaryOptions: SWConfigureBundleOptionSecondaryOption[];
  selectedSecondaryOption: string;
  setSecondaryOption: (option: string) => void;
}

const BundleSecondaryOption = ({
  tooltipText,
  secondaryOptions,
  selectedSecondaryOption,
  setSecondaryOption,
}: IBundleSecondaryOption) => {
  return (
    <RadioAsCheckboxGroup
      direction={{ base: 'column', sm: 'row' }}
      onChange={setSecondaryOption}
      value={selectedSecondaryOption}
      name="secondary-options"
      {...checkboxGroupStyles}
      options={secondaryOptions.map(option => ({
        name: option.name,
        value: `${option.id}-${option.contentful_id}`,
        children: (
          <Stack direction="row" spacing={{ base: 1, lg: 2 }}>
            {documentToReactComponents(JSON.parse(option.optionTitle.raw))}

            {tooltipText && (
              <Popover placement="bottom-end">
                <PopoverTrigger>
                  <Box {...tooltipBoxStyles}>
                    <Icon name="info-circle" group="system" />
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody {...tooltipContentStyles}>
                    <ContentfulRichText document={JSON.parse(tooltipText.raw)} />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Stack>
        ),
      }))}
    />
  );
};

export default BundleSecondaryOption;
