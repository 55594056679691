import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';
import { rem } from 'polished';

export const RadioWrapper = styled(Box)`
  [class*='Radiostyle__RadioWrapper-sc'] {
    padding: 0;
    position: static;
  }

  [class*='Radiostyle__LabelText-sc'] {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;

    &:before,
    &:after {
      left: auto;
      top: auto;
      transform: none;
    }
    &:before {
      background: transparent;
    }
  }
`;

export const radioWrapperStyles = (optionId: string, selectedOption: string) => ({
  sx: {
    "[class*='Radiostyle__RadioWrapper-sc']": {
      boxSize: { base: rem('14px'), md: 5 },
      minH: 0,
      mt: { base: rem('1px'), md: rem('3px') },
    },
    "[class*='Radiostyle__LabelText-sc']": {
      boxSize: { base: rem('14px'), md: 5 },
      '&:before': {
        boxSize: { base: rem('14px'), md: 5 },
        ...(selectedOption === optionId && { borderColor: '#00B0CA' }),
      },
      '&:after': {
        boxSize: { base: 2, md: 3 },
        ...(selectedOption === optionId && { background: '#00B0CA' }),
      },
    },
  },
});

export const radioBoxStyles = {
  display: 'block',
  fontSize: { base: 'lg', md: '2xl' },
  lineHeight: { base: 5, md: 7 },
  pr: { md: 1 },
  sx: {
    p: {
      m: 0,
    },
    'b, strong': {
      letterSpacing: { base: rem('0.5px'), md: rem('0.7px') },
    },
  },
};

export const accordionPanelStyles = {
  pt: { base: rem('9px'), md: rem('22px') },
  pb: { base: 0, lg: 1 },
  ps: { base: rem('29px'), lg: rem('46px') },
  pe: 0,
};

export const contentBoxStyles = {
  sx: {
    h3: {
      fontSize: { base: 'lg', md: '2xl' },
      lineHeight: { base: 'shorter', md: 7 },
      mb: { base: rem('15px'), md: rem('29px') },
    },
    a: {
      letterSpacing: { base: rem('1px') },
      textDecoration: 'underline',
    },
  },
};

export const infoTextBoxImportantStyles = {
  boxSize: { base: rem('10px'), md: 3 },
  display: 'flex',
  fontSize: { base: rem('26px'), md: rem('34px') },
  lineHeight: { base: rem('18px'), md: 6 },
  transform: 'rotate(90deg)',
};

export const infoTextBoxStyles = {
  sx: {
    p: {
      fontSize: { base: 'sm', md: 'md' },
      lineHeight: { base: 4, md: rem('18px') },
    },
  },
};
