import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const NotFoundPageWrapper = styled(Box)`
  margin: ${rem('50px')} auto;
  width: 100%;
  max-width: ${rem('1180px')};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: 0 auto ${rem('30px')};
    padding: 0 ${rem('10px')};
  }

  h1[class*='Headingstyle__Heading-sc'] {
    font-size: ${rem('64px')};
    margin: ${rem('60px')} 0;
    font-family: VodafoneLight;
    font-weight: 400;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: ${rem('32px')};
      line-height: ${rem('40px')};
      margin: ${rem('25px')} 0 ${rem('35px')};
    }
  }
`;
