import styled from '@emotion/styled';
import { rem } from 'polished';

export const FCWrapper = styled.div<{ isFlipped: boolean }>`
  display: flex;
  box-shadow: ${rem('0px')} ${rem('2px')} ${rem('8px')} rgba(0, 0, 0, 0.16);
  margin: ${rem('20px')} 0;
  align-self: center;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  align-items: center;
  justify-content: center;
  height: ${rem('809px')};
  max-width: 100%;
  width: ${rem('380px')};
  background-color: #fff;
  border-radius: ${rem('6px')};
  flex-direction: column;

  ${({ isFlipped }) => isFlipped && 'transform: rotateY(180deg);'};

  [class*='Interactionstyle'] {
    &[id='FlipButton'] {
      width: 90%;
      bottom: ${rem('10px')};
      justify-self: flex-end;
    }
  }
  [class*='Headingstyle__Heading-sc'] {
    font-size: ${rem('22px')};
  }

  [class*='TextStackstyle__TextWrapper-sc'] {
    font-size: ${rem('16px')};
  }

  [class*='TextStackstyle__PriceContainer-sc'] {
    [class*='Spanstyle__Span-sc'] {
      font-size: ${rem('48px')};
    }
    [class*='TextStackstyle__TextWrapper-sc'] {
      font-size: ${rem('28px')};
    }
  }

  [class*='Buttonstyle__Button-sc'] {
    &[id='basketButtonAction'] {
      background: ${({ theme }) => theme.color.primary1.default};
      color: #fff;
      font-size: ${rem('20px')};
      height: ${rem('50px')};
      letter-spacing: 0;
      line-height: ${rem('22px')};
      padding: ${rem('16px')} 0;
      width: 100%;
    }
  }
`;

export const TopContainerDiv = styled.div`
  display: flex;
  height: fit-content;
  width: ${rem('300px')};
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  margin: ${rem('10px')} ${rem('0px')} ${rem('0px')} ${rem('10px')};

  [class*='Headingstyle__Heading-sc'] {
    font-size: ${rem('28px')} !important;
    flex-wrap: wrap;
    width: ${rem('100px')};
    padding: ${rem('0px')} ${rem('0px')} ${rem('0px')} ${rem('5px')} !important;
  }

  [class*='SlotWrapperstyle__SlotWrapper-sc'] {
    padding: ${rem('10px')} ${rem('0px')} ${rem('0px')} ${rem('5px')} !important;
    margin-bottom: ${rem('10px')};
  }
`;

export const ImageListContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${rem('0px')} ${rem('10px')};
  [class*='SlotWrapperstyle__SlotWrapper-sc'] {
    padding: ${rem('0px')} ${rem('12px')};
    margin-bottom: ${rem('10px')};
  }
`;

export const ImageAndTextListContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin: ${rem('20px')} ${rem('10px')};
  [class*='SlotWrapperstyle__SlotWrapper-sc'] {
    padding: ${rem('0px')} ${rem('5px')};
    margin-bottom: ${rem('10px')};
  }
`;

export const ImageAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${rem('5px')};
`;

export const IncludeContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  [class*='Headingstyle__Heading-sc'] {
    font-size: ${rem('18px')};
  }
`;

export const FlippedWrapper = styled.div<{ isFlipped: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: ${rem('6px')};
  ${({ isFlipped }) => isFlipped && 'transform: rotateY(-180deg);'};
`;

export const CenteredDiv = styled.div<{ flexDirection?: string }>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => props.flexDirection || 'column'};
`;

export const IncludesContainer = styled.div<{ flexDirection?: string; height?: string }>`
  display: flex;
  height: ${props => props.height || '100%'};
  width: 100%;
  background-color: #f2f2f2;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${props => props.flexDirection || 'column'};
`;

export const CardInfoContainer = styled.div<{ flexDirection?: string }>`
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: ${props => props.flexDirection || 'column'};
  [class*='SlotWrapperstyle__SlotWrapper-sc'] {
    padding: ${rem('10px')} ${rem('20px')};
    [class*='HeadingInfoSlotstyle__HeadingContainer-sc'] {
      width: ${rem('120px')};
    }
  }
`;
