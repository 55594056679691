import styled from '@emotion/styled';
import { rem } from 'polished';

export const FormSuccessWrapper = styled.div`
  padding: ${rem('50px')} ${rem('20px')};

  @media (max-height: 480px) {
    overflow: scroll;
  }
`;

const WrapperCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormSuccessContent = styled(WrapperCenter)``;

export const SuccessIcon = styled(WrapperCenter)`
  margin-bottom: ${rem('32px')};
  svg {
    width: ${rem('120px')};
    height: ${rem('120px')};
  }
`;

export const FormSuccessText = styled.div`
  margin-bottom: ${rem('32px')};
  p {
    font-family: 'VodafoneLight';
  }
`;
