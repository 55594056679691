import { SimpleGridStyles } from '@vfuk/core-simple-grid';
import { getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';
import styled from 'styled-components';

import { BusinessAppsBannerProps } from './SWBusinessAppsBanner.types';

export const BusinessAppsBannerWrapper = styled.div<BusinessAppsBannerProps>`
  background-size: cover;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${getSrc(backgroundImage)})` : 'none')};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    background-image: ${({ mobileBackgroundImage }) => {
      if (mobileBackgroundImage) {
        return `url(${getSrc(mobileBackgroundImage)})`;
      } else {
        return 'none';
      }
    }};
  }
`;

export const BusinessAppsBannerInner = styled.div`
  margin-bottom: ${rem('20px')};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: ${rem('50px')};
  }

  ${SimpleGridStyles.SimpleGrid} {
    padding: 0;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md + 1}px) {
      max-width: ${rem('1180px')};
      margin: 0 auto;
    }

    h2[class*='Headingstyle__Heading'] {
      color: ${({ theme }) => theme.color.primary2.default};
      font-size: ${rem('36px')};
      line-height: ${rem('41px')};
      font-weight: 100;
      margin: ${rem('32px')} ${rem('20px')} ${rem('20px')};
      @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        font-size: ${rem('40px')};
        line-height: ${rem('48px')};
        margin: ${rem('50px')} auto;
      }
    }
  }
`;

export const CardWrapper = styled.div`
  width: auto;
  margin: 0 auto;
  padding: 0 ${rem('16px')};
  text-align: center;
  background: transparent;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    width: ${rem('320px')};
    margin: ${rem('12px')} auto;
  }

  svg {
    width: ${rem('64px')};
    height: ${rem('64px')};
    stroke-width: ${rem('2px')};
    margin-bottom: ${rem('20px')};
  }

  h3 {
    font-size: ${rem('18px')};
    font-weight: 700;
    line-height: ${rem('20px')};
    font-family: 'Vodafone';
    color: ${({ theme }) => theme.color.primary2.default};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
        theme.breakpoints.lg}px) {
      font-size: ${rem('16px')};
      line-height: ${rem('19px')};
    }
  }
`;
