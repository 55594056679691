import { rem } from 'polished';
import styled from 'styled-components';

const BasketItemBundleStyles = styled.div`
  width: 100%;
  padding: ${rem('10px')} 0;
  border-top: ${rem('1px')} solid #4a4d4e26;

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    border-bottom: ${rem('1px')} solid #4a4d4e26;
  }
`;

export const BundleItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem('10px')};
  &:last-of-type {
    margin-bottom: 0;
  }
  > p {
    font-family: 'VodafoneLight';
    color: var(--mineShaft);
    margin-bottom: 0;
  }
`;

export const BundleItemThumb = styled.div`
  width: ${rem('28px')};
  height: ${rem('28px')};
  overflow: hidden;
  flex-shrink: 0;
  margin-right: ${rem('10px')};
`;

export default BasketItemBundleStyles;
