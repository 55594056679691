import { css, Global } from '@emotion/react';
import { PageProps } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { Fragment, useEffect, useRef } from 'react';

import { SEO } from '@/components/Global/SEO/SEO';
import { addDynamicBodyContent, addSeoHeadContent } from '@/helpers/DynamicContentHelpers';
import useDocumentPathname from '@/hooks/useDocumentPathname';
import MainLayout from '@/layouts/MainLayout';
import { ContentSectionProps } from '@/types/Contentful.types';

import makePageSections from './page-builder-helpers';

// NOTE: placing type here as quick fix
export type PageBuilderProps = {
  pageBuilderData: {
    isDefaultPage: boolean;
    isProtectedPage?: boolean;
    seoTitle: any;
    seoDescription: any;
    metadata: any;
    scripts: any;
    sections: ContentSectionProps[];
    oddColor: any;
    evenColor: any;
  };
  recaptchaToken: string;
};

const PageBuilder = ({ pageContext, location }: PageProps<any, PageBuilderProps>) => {
  const SiteConfig = () => {
    const {
      allContentfulSiteConfig: { nodes },
    } = useStaticQuery(graphql`
      {
        allContentfulSiteConfig {
          nodes {
            language
          }
        }
      }
    `);
    return nodes[0].language;
  };

  const { pageBuilderData, recaptchaToken } = pageContext;
  const { isDefaultPage, isProtectedPage, seoTitle, seoDescription, metadata, scripts, sections, oddColor, evenColor } =
    pageBuilderData;

  let odd = '',
    even = '';
  odd = oddColor.toLowerCase() === 'white' ? 'var(--white)' : 'var(--gallery)';
  even = evenColor.toLowerCase() === 'white' ? 'var(--white)' : 'var(--gallery)';

  const oddStyles = css`
    /* Vodafone Brand Colors */
    #scroll-root > div.section:nth-of-type(even) {
      background: ${odd};
    }
  `;
  const evenStyles = css`
    /* Vodafone Brand Colors */
    #scroll-root > div.section:nth-of-type(odd) {
      background: ${even};
    }
  `;

  const pageSections = makePageSections(sections, recaptchaToken, seoTitle);

  const pageBuilderSeo = {
    metaData: metadata,
    scripts,
    language: SiteConfig(),
  };
  const seoHead = useRef(addSeoHeadContent(pageBuilderSeo));
  const pathname = useDocumentPathname();

  useEffect(() => {
    if (window.seoPageChange) {
      try {
        window.seoPageChange();
      } catch (e) {
        console.error(e);
      }
    }
  }, [pathname]);

  useEffect(() => {
    addDynamicBodyContent(pageBuilderSeo);
  }, []);

  return (
    <>
      <MainLayout isDefaultPage={isDefaultPage} isProtectedPage={isProtectedPage}>
        {seoHead.current}
        <SEO
          metadata={[
            {
              content: seoTitle,
              metadataTag: 'title',
            },
            {
              name: 'description',
              content: seoDescription,
              metadataTag: 'meta',
            },
            {
              name: 'og:type',
              content: 'website',
              metadataTag: 'meta',
            },
            {
              name: 'og:title',
              content: seoTitle,
              metadataTag: 'meta',
            },
            {
              name: 'og:description',
              content: seoDescription,
              metadataTag: 'meta',
            },
            {
              name: 'twitter:title',
              content: seoTitle,
              metadataTag: 'meta',
            },
            {
              name: 'twitter:description',
              content: seoDescription,
              metadataTag: 'meta',
            },
          ]}
        />
        {pageSections.map((section, key) => (
          <Fragment key={key}>{section}</Fragment>
        ))}
        {oddColor !== 'N/A' && <Global styles={oddStyles} />}
        {evenColor !== 'N/A' && <Global styles={evenStyles} />}
      </MainLayout>
    </>
  );
};

export default PageBuilder;
