import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const ModalForm = styled(Modal)``;
export const FormModalOverlay = styled(ModalOverlay)``;
export const FormModalContent = styled(ModalContent)``;

export const SWFormButton = styled.div`
  position: fixed;
  top: 75%;
  left: 0;
  z-index: 4;
  [class*='Buttonstyle__Button-sc'] {
    height: auto;
    writing-mode: vertical-lr;
    min-width: auto;
    padding: ${rem('16px')} ${rem('8px')};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    [class*='Iconstyle__Icon-sc'] {
      margin-right: 0;
      margin-bottom: ${rem('8px')};
      svg {
        transform: rotate(90deg);
      }
    }
  }
`;
