import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    padding-top: ${rem('40px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: ${rem('410px')};
    padding-left: ${rem('35px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: ${rem('454px')};
    padding-left: ${rem('56px')};
  }

  h1[size='3'],
  h2[size='2'],
  p[size='2'],
  button {
    color: var(--mineShaft);
  }

  h1[size='3'],
  h2[size='2'] {
    letter-spacing: ${rem('-0.5px')};
    line-height: ${rem('36px')};
  }

  h1[size='3'] {
    font-size: ${rem('40px')};
    margin-block: ${rem('2px')} ${rem('3px')};
  }

  h2[size='2'] {
    font-size: ${rem('28px')};
    margin-bottom: ${rem('15px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: ${rem('19px')};
    }
  }

  [class*='Pillstyle__Pill-sc'] {
    border: ${rem('1px')} var(--DustyGray) solid;
    background: none;
    color: var(--mineShaft);
    display: inline-flex;
    font-size: ${rem('14px')};
    font-weight: 400;
    letter-spacing: ${rem('0.9px')};
    padding: ${rem('8px')} ${rem('15px')} ${rem('11px')} ${rem('17px')};
    margin-bottom: ${rem('16px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: ${rem('21px')};
    }
  }

  p[size='2'] {
    letter-spacing: ${rem('-0.25px')};
    line-height: ${rem('26px')};
    margin-bottom: ${rem('22px')};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      font-size: ${rem('18px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: ${rem('16px')};
      padding-right: ${rem('38px')};
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-right: ${rem('22px')};
  }

  button {
    border: none;
    border-radius: ${rem('5px')};
    font-size: ${rem('20px')};
    height: auto;
    min-width: auto;
    letter-spacing: ${rem('-1px')};
    line-height: ${rem('23px')};
    padding: 0;

    &:first-of-type {
      justify-content: flex-start;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        width: 75%;
      }
    }

    &:last-child {
      justify-content: flex-end;
    }

    > span[class*='Iconstyle__Icon-sc'] {
      margin-right: ${rem('6px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        margin-right: ${rem('12px')};
      }
    }

    svg {
      width: ${rem('30px')};
      height: ${rem('30px')};
      stroke: var(--mineShaft);
    }
  }
`;
