import { getOpcoUrl } from '@/helpers/prefixHelper';
import { useMatch } from '@reach/router';

export const useAllowedUris = () => {
  const selectAccountPage = getOpcoUrl('/marketplace/multiple-billing-account');
  const prodListPage = getOpcoUrl('/marketplace/product-list');
  const officeAnywherePage = getOpcoUrl('/marketplace/office-anywhere');
  const businessBoostPage = getOpcoUrl('/marketplace/business-boost');
  const basketPage = getOpcoUrl('/marketplace/product-list/basket');

  const microsoft365ForBusinessBundlePage = getOpcoUrl('/marketplace/product-list/microsoft-365-for-business/bundle');
  const loginPage = getOpcoUrl('/marketplace/multiple-billing-account');

  const paths = {
    selectAccountPage,
    prodListPage,
    officeAnywherePage,
    microsoft365ForBusinessBundlePage,
    businessBoostPage,
    basketPage,
  };

  const isSelectAccountPage = useMatch(selectAccountPage);
  const isProdListPage = useMatch(prodListPage);
  const isOfficeAnywherePage = useMatch(officeAnywherePage);
  const isBusinessBoostPage = useMatch(businessBoostPage);
  const isBasketPage = useMatch(basketPage);

  const isMicrosoft365ForBusinessBundlePage = useMatch(microsoft365ForBusinessBundlePage);
  const isLoginPage = useMatch(loginPage);

  return {
    isSelectAccountPage,
    isProdListPage,
    isOfficeAnywherePage,
    isBusinessBoostPage,
    isBasketPage,
    isMicrosoft365ForBusinessBundlePage,
    isLoginPage,
    paths,
  };
};
