import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getImage, getSrc } from 'gatsby-plugin-image';

import Icon from '@source-web/icon';
import Image from '@vfuk/core-image';
import SimpleGrid from '@source-web/simple-grid';

import {
  ServicesBannerContainer,
  ServicesBannerWrapper,
  ServicesContent,
  ServicesContentHeading,
  ServicesContentImage,
  ServicesContentText,
  ServicesTitleText,
} from './SWSectionServicesBanner.styles';

import { SWSectionServicesBannerProps } from './SWSectionServicesBanner.types';

const SWSectionServicesBanner = ({ headingRich, headingIcon, text, image, name }: SWSectionServicesBannerProps) => {
  const imageSrc = getSrc(image) || getImage(image)?.placeholder?.fallback;
  return (
    <ServicesBannerContainer>
      <ServicesBannerWrapper>
        <SimpleGrid
          columns={{
            sm: 1,
            md: 2,
          }}
          verticalAlign="stretch"
        >
          <ServicesContent>
            <ServicesContentHeading>
              {headingIcon && <Icon name={headingIcon} group="hifiLight" size={2} />}
              <ServicesTitleText>{documentToReactComponents(JSON.parse(headingRich.raw))}</ServicesTitleText>
            </ServicesContentHeading>
            <ServicesContentText>{documentToReactComponents(JSON.parse(text.raw))}</ServicesContentText>
          </ServicesContent>
          <ServicesContentImage>
            {imageSrc && (
              <Image
                alt={` ${name} image`}
                sm={{
                  src: imageSrc,
                }}
              />
            )}
          </ServicesContentImage>
        </SimpleGrid>
      </ServicesBannerWrapper>
    </ServicesBannerContainer>
  );
};

export default SWSectionServicesBanner;
