import { rem } from 'polished';
import styled from 'styled-components';

type WrapperProps = {
  backgroundColor: string;
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#F4F4F4'};
  padding: 0 ${rem('16px')} ${rem('70px')};

  h2 {
    margin: ${rem('50px')} auto;
    text-align: center;
    font-family: VodafoneLight;
    font-size: ${rem('40px')};
  }

  > div {
    max-width: ${rem('1180px')};
    margin: 0 auto;

    [class*='chakra-accordion__item'] {
      box-shadow: ${rem('0px')} ${rem('2px')} ${rem('8px')} rgba(0, 0, 0, 0.16);
      background-color: ${({ theme }) => theme.color.primary2.default};
      margin-bottom: ${rem('16px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin-bottom: ${rem('20px')};
      }
    }
    [class*='chakra-accordion__button'] {
      min-height: ${rem('80px')};
      :hover {
        background-color: ${({ theme }) => theme.color.primary2.default};
      }
    }
    [class*='chakra-accordion__panel'] {
      > * {
        margin: 0;
      }
      border-top: ${rem('1px')} solid #000;
      margin: auto ${rem('1px')};
      padding: ${rem('20px')} ${rem('16px')} ${rem('20px')} ${rem('55px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        padding: ${rem('20px')} ${rem('20px')} ${rem('20px')} ${rem('64px')};
      }
    }
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem('15px')};
  flex-grow: 1;
`;

export const Header = styled.span`
  text-align: left;
  font-size: ${rem('20px')};
  line-height: ${rem('28px')};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('24px')};
  }
`;
