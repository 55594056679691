import styled from 'styled-components';
import { rem } from 'polished';
import {Flex} from "@chakra-ui/react";

export const QuickLinksWrapper = styled.div`
  background-image: linear-gradient(#e60000, #820000);
  padding: ${rem('8px')};
`;

export const LinkWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const LinkItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${rem('10px')};
  width: ${rem('175px')};
  height: ${rem('120px')};
  color: #fff;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};

  img {
    border-radius: 100%;
    width: ${rem('64px')};
    height: ${rem('64px')};
    object-fit: cover;
    margin-bottom: ${rem('10px')};
  }
`;
