import { rem } from 'polished';
import styled from 'styled-components';
import { IFormInput } from './Form.types';

export const InputContainer = styled.div<{
  fullWidth: boolean | undefined;
  labelColor?: string;
  labelType?: IFormInput['labelType'];
  customWidth?: string;
  disabled?: boolean;
  hidden?: boolean;
  fullWidthOnMobile?: boolean;
}>`
  ${({ hidden }) => {
    if (hidden) {
      return `display: none;`;
    } else {
      return `display: initial;`;
    }
  }}

  margin: ${rem('10px')} 0;
  padding: 0 ${rem('10px')};
  [class*='Checkboxstyle__Checkbox-sc'] {
    background-color: transparent;
  }
  [class*='Checkboxstyle__CheckboxLabel-sc'] {
    font-size: ${rem('14px')};
  }

  [class*='Radiostyle__LabelText-sc'] {
    ::before {
      background-color: transparent;
    }
  }
  opacity: ${({ disabled }) => (disabled ? 60 : 100)}%;
  li {
    color: ${({ labelColor }) => labelColor};
  }
  [class*='FieldWrapperstyle__Label'] {
    ${({ labelColor, labelType }) => {
      if (labelType == 'transparent') {
        return `color: transparent;`;
      } else if (labelType == 'hidden') {
        return 'display: none';
      } else if (labelColor) {
        return `color: ${labelColor}`;
      }
      return `color: #000`;
    }}
  }

  ${({ fullWidth, customWidth, fullWidthOnMobile }) => {
    if (!fullWidthOnMobile) {
    }
    if (fullWidth) {
      return `width: 100%;`;
    } else if (customWidth) {
      return `width: ${customWidth};`;
    }
    return `width: 50%;`;
  }}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    ${({ fullWidthOnMobile }) => {
      if (fullWidthOnMobile) {
        return `width: 100%;`;
      }
    }}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    [class*='FieldWrapperstyle__FieldWrapper-sc'] {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const InputLabel = styled.div<{ labelColor?: string; labelType?: IFormInput['labelType'] }>`
  font-size: ${rem('20px')};
  margin: 0 0 ${rem('10px')} 0;

  ${({ labelColor, labelType }) => {
    if (labelType == 'transparent') {
      return `color: transparent;`;
    } else if (labelType == 'hidden') {
      return 'display: none';
    } else if (labelColor) {
      return `color: ${labelColor}`;
    }
    return `color: #000`;
  }}
`;
