import { Span } from '@source-web/span';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const QuantitySelectorStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem('5px')};
  margin-left: ${rem('15px')};
  margin-right: ${rem('20px')};

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    grid-column: 1;
    grid-row: 2;
    margin-left: 0;
    align-items: flex-start;
  }
`;

export const QuantitySelectorLabel = styled(Span)`
  font-family: 'VodafoneLight';
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('20px')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    text-align: left;
  }
`;

export const QuantitySelectorActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem('36px')};
  > button {
    background: #d8d8d880;
    svg,
    path {
      stroke: #007c92 !important;
    }

    &:hover {
      background: #d8d8d8;
    }
  }
`;

export const QuantitySelectorValue = styled(Span)`
  font-family: 'VodafoneLight';
  text-align: center;
  margin: 0 ${rem('14px')};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('20px')};
  }
`;

export default QuantitySelectorStyles;
