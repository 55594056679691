import { Box, Heading, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const StandardCardHeaderWrapper = styled.div``;
export const StandardCardHeader = styled(Stack)``;

export const StandardCardTitleWrapper = styled(Box)`
  && {
    min-height: ${rem('90px')};
  }
`;

export const StandardCardTitle = styled(Heading)`
  && {
    font-weight: bold;
    font-size: ${rem('24px')};
    line-height: ${rem('30px')};
    margin-bottom: 0;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('28px')};
      line-height: ${rem('36px')};
    }
  }
`;
