import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  [class*='CardHeadingAndIconSlotstyle__CardHeadingAndIconSlot'] {
    flex-direction: column;

    [class*='IconContainer'] {
      [class*='CardImageSlotstyle__CardImageSlot'] {
        height: ${rem('190px')};
        padding: 0;

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          height: ${rem('175px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          height: ${rem('190px')};
        }
      }
    }

    [class*='HeadingContainer'] {
      margin-left: 0;
      h2[size='2'][class*='Headingstyle__Heading'] {
        color: var(--mineShaft);
        font-size: ${rem('14px')};
        font-weight: normal;
        letter-spacing: 0;
        line-height: ${rem('18px')};
        margin: 0;

        padding: ${rem('16px')} ${rem('20px')};

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          padding: ${rem('20px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          padding: ${rem('28px')} ${rem('24px')};
        }
      }

      [class*='LinkContainer'] {
        padding: 0 ${rem('20px')};

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          padding: 0 ${rem('20px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          padding: 0 ${rem('24px')};
        }

        a {
          font-size: ${rem('28px')};
          font-weight: 400;
          letter-spacing: ${rem('-0.304px')};
          line-height: ${rem('25px')};
          text-decoration: none;

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
            letter-spacing: ${rem('-0.208px')};
            line-height: ${rem('33px')};
          }

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
            letter-spacing: 0;
            line-height: ${rem('31px')};
          }

          [class*='Iconstyle__Icon'] {
            > span {
              margin-left: ${rem('8px')};
            }
          }
        }
      }
    }
  }

  [class*='CardIconSnippetSlotstyle__CardIconSnippetSlot'] {
    background-color: transparent;
    padding: 0;

    [class*='Iconstyle__Icon'] {
      display: none;
    }

    [class*='CardIconSnippetSlotstyle__ContentContainer'] {
      padding: 0 ${rem('20px')} ${rem('45px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        padding: 0 ${rem('20px')} ${rem('40px')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        padding: 0 ${rem('24px')} ${rem('45px')};
      }
    }

    [class*='Paragraphstyle__Paragraph'] {
      line-height: ${rem('24px')};
    }
  }
`;
