import CardBuilder from '@vfuk/core-card-builder';
import CardHeadingAndIconSlot from '@vfuk/core-card-heading-and-icon-slot';
import CardIconSnippetSlot from '@vfuk/core-card-icon-snippet-slot';
import CardImageSlot from '@vfuk/core-card-image-slot';
import CardPillAndIconSlot from '@vfuk/core-card-pill-and-icon-slot';
import Icon from '@vfuk/core-icon';
import { withPrefix } from 'gatsby';
import { getImage, getSrc } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { Product } from '../../../../types/Product.types';
import usingOpcoUrl from '@/helpers/prefixHelper';
import { Wrapper } from './SWProductCard.styles';

const SWProductCard: FC<Product> = ({ id, name, thumbnailIcon, category, shortDescription, slug }: Product) => {
  const imageSlotSrc = getSrc(thumbnailIcon) || getImage(thumbnailIcon)?.placeholder?.fallback || '';
  const dataSelector = name;
  const imageSlot = {
    alt: thumbnailIcon?.title,
    backgroundImage: {
      sm: {
        src: imageSlotSrc,
      },
    },
  };

  return (
    <Wrapper>
      <CardBuilder key={id}>
        <CardHeadingAndIconSlot
          icon={{
            name: '',
            component: <CardImageSlot {...imageSlot} />,
          }}
          heading={{ name: '' }}
          link={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Component CardHeadingAndIconSlot don't accept receiving a ReactNode at the text prop
            text: (
              <>
                {name}
                <Icon name="chevron-right" group="system" size={2} appearance="secondary" />
              </>
            ),
            href: usingOpcoUrl(withPrefix(slug)) || '#',
            dataAttributes: { 'data-selector': `product card-${dataSelector}` },
          }}
        />
        <CardIconSnippetSlot headingText="" icon={{ name: '' }} text={shortDescription?.shortDescription || ''} />
        <CardPillAndIconSlot icon={{ name: '' }} pill={{ text: category?.name || '', state: 'info' }} />
      </CardBuilder>
    </Wrapper>
  );
};

export default SWProductCard;
