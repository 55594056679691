import Heading from '@vfuk/core-heading';
import Image from '@vfuk/core-image';
import Paragraph from '@vfuk/core-paragraph';
import { getImage, getSrc } from 'gatsby-plugin-image';

import { ImagesWrapper, PortfolioDisplayHeaderWrapper } from './SWPortfolioDisplayHeader.styles';
import { SWPortfolioDisplayHeaderProps } from './SWPortfolioDisplayHeader.types';

const SWPortfolioDisplayHeader = ({ heading, text, footnote, logosList }: SWPortfolioDisplayHeaderProps) => {
  return (
    <PortfolioDisplayHeaderWrapper>
      <Heading level={2} text={heading} />
      <Heading level={3} text={footnote} />
      <ImagesWrapper>
        {logosList?.map(logo => {
          return (
            <Image
              key={logo.id}
              alt={logo.description}
              sm={{
                src: getSrc(logo) || getImage(logo)?.placeholder?.fallback || '',
                width: 'auto',
              }}
            />
          );
        })}
      </ImagesWrapper>
      <Paragraph size={2}>{text?.text}</Paragraph>
    </PortfolioDisplayHeaderWrapper>
  );
};

export default SWPortfolioDisplayHeader;
