import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const Title = styled.h2`
  color: var(--mineShaft);
  font-size: ${rem('40px')};
  letter-spacing: ${rem('-0.4px')};
  line-height: ${rem('48px')};
  margin-bottom: 0;
  padding-block: ${rem('0px')} ${rem('48px')};
  text-align: center;
`;

export const AppsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-block: ${rem('0px')} ${rem('48px')};
  flex-direction: column;
`;

export const ImageWrap = styled(Flex)`
  flex-direction: column;
  margin: 0 30px;
  align-items: center;
  min-width: ${rem('130px')};
  &,
  picture {
    width: ${rem('64px')};
    height: ${rem('64px')};
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-block: ${rem('0px')} ${rem('82px')};
`;

export const ImageDesc = styled.div`
  margin: 24px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
