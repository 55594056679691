import { Icon } from '@source-web/icon';
import Button from '@vfuk/core-button';
import Heading from '@vfuk/core-heading';
import Paragraph from '@vfuk/core-paragraph';

import { FormSuccessWrapper, FormSuccessContent, SuccessIcon, FormSuccessText } from './FormSuccess.styles';

interface FormSuccessProps {
  handleCloseForm: () => void;
}

const FormSuccess = ({ handleCloseForm }: FormSuccessProps) => {
  return (
    <FormSuccessWrapper>
      <FormSuccessContent>
        <SuccessIcon>
          <Icon name="success" group="state" inverse size={5} />
        </SuccessIcon>
        <FormSuccessText>
          <Heading level={3} justify="center">
            Thanks for your request
          </Heading>
          <Paragraph justify="center">Thank you for contacting us. One of our agents will contact you soon.</Paragraph>
        </FormSuccessText>
        <Button text="Close" appearance="secondary" onClick={handleCloseForm} />
      </FormSuccessContent>
    </FormSuccessWrapper>
  );
};
export default FormSuccess;
