const { navigate } = require('gatsby');

const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  }

  return null;
};

const addParamsToUrl = (url, params) => {
  const urlObj = new URL(url);
  Object.keys(params).forEach(key => urlObj.searchParams.append(key, params[key]));
  return urlObj.toString();
};

const setPTPath = path => {
  let newPath = path;

  const shouldIncludeBusiness = newPath.includes('marketplace/') && !newPath.includes('business/marketplace');
  newPath = newPath.replace('product-list/', ''); // Removing product-list from product pages, product-list page still works
  newPath = newPath.replace('/nce-', '/'); // Removing nce from product urls
  newPath = newPath.replace('/basket', '/carrinho'); // Change basket to carrinho

  if (shouldIncludeBusiness) {
    newPath = newPath.replace('marketplace/', 'business/marketplace/');
  }

  return newPath;
};

const usingOpcoUrl = (path, shouldNavigate = false, navigateParams = {}) => {
  let newPath = path;

  if (process.env.GATSBY_OPCO === 'PT') {
    newPath = setPTPath(newPath);

    const isRelativeUrl = !newPath.startsWith('http') && !newPath.startsWith('//');

    if (isRelativeUrl) {
      let baseURL = getBaseUrl();

      if (!baseURL) {
        if (process.env.OPCO_NAME === 'portugal sit') {
          baseURL = 'https://www.vodafone.pt:449';
        }
      }

      if (baseURL) {
        newPath = new URL(newPath, baseURL).toString();
      }
    }
  }

  if (!shouldNavigate) {
    return newPath;
  }

  if (process.env.GATSBY_OPCO === 'PT') {
    newPath = addParamsToUrl(newPath, navigateParams);
    window.location.href = newPath;
  } else {
    navigate(newPath, navigateParams);
  }
};

const getOpcoUrl = path => {
  let newPath = path;

  if (process.env.GATSBY_OPCO === 'PT') {
    newPath = setPTPath(newPath);
  }

  return newPath;
};

module.exports = usingOpcoUrl;
module.exports.getOpcoUrl = getOpcoUrl;
