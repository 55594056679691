import { rem } from 'polished';

export const tooltipBoxStyles = {
  sx: {
    '[class*="injected-svg"]': {
      boxSize: { base: 4, md: 5 },
    },
  },
};

export const tooltipContentStyles = {
  sx: {
    p: {
      fontSize: rem('18px'),
      margin: `${rem('15px')} 0`,
      letterSpacing: '0',
      lineHeight: rem('18px'),
    },
  },
};

export const checkboxGroupStyles = {
  mb: { base: rem('17px'), md: rem('30px') },
  sx: {
    b: {
      letterSpacing: { base: rem('0.8px'), md: rem('1px') },
    },
    p: {
      fontSize: { base: 'lg', md: '2xl' },
      lineHeight: { base: 'shorter', md: rem('26px') },
      m: 0,
    },
  },
};
