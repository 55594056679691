import styled, { css } from 'styled-components';
import { respondTo, spacing } from '@source-web/mixins';
import { rem } from 'polished';

export const BasketNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${rem('10px')};

  * {
    width: 100%;
  }

  *:first-child {
    margin-bottom: ${rem('10px')};
  }
  *:last-child {
    margin-bottom: 0;
  }

  ${respondTo.md(css`
    flex-direction: row;
    ${spacing('margin-top', 0)}

    *:first-child {
      margin-bottom: 0;
      margin-right: ${rem('20px')};
    }
    *:last-child {
      margin-right: 0;
    }
  `)}
`;
