import styled from '@emotion/styled';
import { rem } from 'polished';

export const ServicesBannerWrapper = styled.div`
  padding: ${rem('24px')};
  background-color: #f4f4f4;
  border-radius: ${rem('6px')};
`;

export const ServicesBannerContainer = styled.div`
  margin: 0 auto;
  max-width: ${rem('1180px')};
  width: 100%;
  padding: ${rem('30px')} 0;
`;

export const ServicesContent = styled.div`
  margin-bottom: ${rem('24px')};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-right: ${rem('24px')};
    margin-bottom: 0;
  }
`;

export const ServicesContentHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem('20px')};
`;

export const ServicesTitleText = styled.div`
  padding: 0 ${rem('20px')};
  p {
    font-family: 'VodafoneRegular';
    font-size: ${rem('32px')};
    line-height: ${rem('32px')};
    font-weight: 700;
    color: #0d0d0d;
    margin: 0;
  }
`;

export const ServicesContentText = styled.div`
  color: #0d0d0d;
  *:last-child {
    margin-bottom: 0;
  }
  p {
    font-family: 'VodafoneLight';
    font-size: ${rem('20px')};
    line-height: ${rem('26px')};
    margin-bottom: ${rem('20px')};
    *:last-child {
      margin-bottom: 0;
    }
  }
  li {
    margin-bottom: ${rem('20px')};
    *:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ServicesContentImage = styled.div`
  img {
    border-radius: ${rem('6px')};
    width: 100%;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
        theme.breakpoints.lg - 1}px) {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }
`;
