import breakpoints from './breakpoints';
import colors from './colors';
import { space } from './space';
import typography from './typography';

const foundations = {
  breakpoints,
  colors,
  ...typography,
  space,
};

export default foundations;
