import { useState } from 'react';

import Toggle from '@vfuk/core-toggle';

import { BundleIncludesListProps } from '../BundleIncludes.types';
import { AddonWrapToggle, BundleIncludesItem } from './BundleIncludesList.styles';
import BundleAddon from '../BundleAddon/BundleAddon';

const BundleIncludesList = ({ item, color, showToggle }: BundleIncludesListProps) => {
  const { slug, name } = item;

  const [toggleStatus, setToggleStatus] = useState<boolean>(false);

  return (
    <BundleIncludesItem alignItems="center" color={color ?? 'mineShaft'} display="flex" fontSize="md" lineHeight={5}>
      {showToggle ? (
        <AddonWrapToggle>
          <Toggle
            align="left"
            name={`toggle-${name}`}
            isActive={toggleStatus}
            onChange={() => {
              setToggleStatus(!toggleStatus);
            }}
            id={`toggle-addon-${slug}`}
            value={`toggle-addon-${slug}`}
          >
            <BundleAddon item={item} toggle={true} />
          </Toggle>
        </AddonWrapToggle>
      ) : (
        <BundleAddon item={item} toggle={false} />
      )}
    </BundleIncludesItem>
  );
};

export default BundleIncludesList;
