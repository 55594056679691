import { rem } from 'polished';
import styled, { css } from 'styled-components';

const TwoFactorAuthStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - ${rem('58px')});
  background-color: #ffffff;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-height: calc(100vh - ${rem('108px')});
  }
`;

export const TwoFactorAuthWrapper = styled.div`
  width: 100%;
  max-width: ${rem('400px')};
  padding: ${rem('20px')};
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    text-align: center;
  }

  svg {
    width: ${rem('80px')};
    height: ${rem('80px')};
  }

  h2 {
    font-family: 'VodafoneLight';
    margin-bottom: ${rem('30px')};
  }
`;

export const TwoFactorAuthText = styled.div`
  margin-bottom: ${rem('30px')};
  p {
    font-family: 'VodafoneRegular';
    font-size: ${rem('18px')};
    line-height: ${rem('21px')};
    margin: 0;
  }
`;

export const GroupedTextWrapper = styled.div`
  margin-bottom: ${rem('16px')};
`;

export const HelperTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem('16px')};
  p {
    color: #666666;
    font-family: 'VodafoneLight';
    margin-bottom: 0;
    margin-right: ${rem('5px')};
  }
`;

export const ResendButton = styled.button`
  font-family: 'VodafoneLight';
  color: #666666;
  margin-right: ${rem('5px')};
  text-decoration: underline;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`;

export default TwoFactorAuthStyles;
