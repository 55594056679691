export enum ErrorsBlockedList {
  ALL_ACCOUNTS_ON_BLOCKLIST = 'ALL_ACCOUNTS_ON_BLOCKLIST',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
}

export type BlockedListErrorsProps = {
  code: string;
};

export type BlockedListErrorsList = {
  id: string;
  code: ErrorsBlockedList;
  title: string;
  description: string;
  buttonCTA: string;
  buttonRedirectURL?: string;
};
