import React, { FC } from 'react';
import { SWQuickLinksProps } from '@/components/pageBuilder/Sections/SWSectionQuickLinks/SWSectionQuickLinks.types';
import {
  LinkWrapper,
  QuickLinksWrapper,
  LinkItem,
} from '@/components/pageBuilder/Sections/SWSectionQuickLinks/SWSectionQuickLinks.styles';
import Link from '@vfuk/core-link';
import { getImage, getSrc } from 'gatsby-plugin-image';
import Image from '@vfuk/core-image';
import { useLocation } from '@reach/router';
import usingOpcoUrl from '@/helpers/prefixHelper';

const SWSectionQuickLinks: FC<SWQuickLinksProps> = ({ content }: SWQuickLinksProps) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const filter = params.get('filter');

  return (
    <QuickLinksWrapper>
      <LinkWrapper>
        {content.map(item => (
          <Link href={usingOpcoUrl(item.url)} key={item.id}>
            <LinkItem isSelected={filter ? item.url.includes(String(filter)) : Boolean(item.name === 'All Products')}>
              <Image
                alt="image"
                sm={{
                  src: getSrc(item.icon) || getImage(item.icon)?.placeholder?.fallback,
                  width: '80px',
                  height: '80px',
                }}
              />
              {item.name}
            </LinkItem>
          </Link>
        ))}
      </LinkWrapper>
    </QuickLinksWrapper>
  );
};
export default SWSectionQuickLinks;
