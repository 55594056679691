import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FC } from 'react';
import CookieConsent from 'react-cookie-consent';

import { BannerStyles, ButtonStyles } from './SWCookieDetect.styles';
import { SWCookieDetectProps } from './SWCookieDetect.types';

export const CONTENTFUL_SECTION_SW_COOKIE_CONSENT = 'ContentfulSectionSwCookieConsent';

const SWCookieDetect: FC<SWCookieDetectProps> = ({
  location,
  buttonText,
  declineButtonText,
  cookieName,
  richBannerText,
}) => {
  return (
    <CookieConsent
      overlay
      style={BannerStyles}
      location={location}
      cookieName={cookieName}
      buttonText={buttonText}
      buttonStyle={ButtonStyles}
      enableDeclineButton
      declineButtonText={declineButtonText}
      declineButtonStyle={ButtonStyles}
      expires={1}
    >
      {richBannerText ? documentToReactComponents(JSON.parse(richBannerText.raw)) : ''}
    </CookieConsent>
  );
};

export default SWCookieDetect;
