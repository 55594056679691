import { forwardRef, Stack, useRadioGroup } from '@chakra-ui/react';
import { rem } from 'polished';

import RadioAsCheckbox from '@/components/pageBuilder/Elements/RadioAsCheckbox';

import { RadioAsCheckboxGroupProps } from './RadioAsCheckboxGroup.types';

const RadioAsCheckboxGroup = forwardRef<RadioAsCheckboxGroupProps, 'div'>(
  ({ name, defaultValue, onChange, value, options, ...props }: RadioAsCheckboxGroupProps, ref) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
      name,
      defaultValue,
      onChange,
    });

    const group = getRootProps();

    return (
      <Stack ref={ref} spacing={{ base: rem('5px'), md: rem('34px'), lg: rem('46px') }} {...props} {...group}>
        {options.map((option, key) => {
          const radio = getRadioProps({ value: String(option.value) });

          radio['isChecked'] = String(value) === String(option.value);

          return (
            <RadioAsCheckbox key={option.name} {...radio} onCheckboxClick={radio.onChange}>
              {option.children || name}
            </RadioAsCheckbox>
          );
        })}
      </Stack>
    );
  }
);

export default RadioAsCheckboxGroup;
