import React, { FC, useEffect, useState } from 'react';
import { CardsContainer, ContentContainer } from './SWSectionTariffCards.styles';
import SWTariffCard from '../../Elements/SWTariffCard';
import { SWSectionTariffCardsProps } from './SWSectionTariffCards.types';
import { ProductBundle } from '@/types/Product.types';
import { SWComparisonTable } from '../SWComparisonTable';

const SWSectionTariffCards: FC<SWSectionTariffCardsProps> = ({
  cards,
  showComparisonTable,
  comparisonTableProductId,
  buttonsAction,
  nextStep,
  isAddon,
}) => {
  const [products, setProducts] = useState<ProductBundle[] | undefined>(undefined);
  useEffect(() => {
    const newProducts = cards.map(card => card.product);
    setProducts(newProducts);
  }, [cards]);

  const hasAnyServiceIncluded = cards.some(card => card?.servicesIncluded && card.servicesIncluded.length > 0);
  return (
    <ContentContainer>
      <CardsContainer>
        {cards.map(card => (
          <SWTariffCard
            key={card.product.id}
            {...card}
            buttonsAction={buttonsAction}
            nextStep={nextStep}
            hasService={hasAnyServiceIncluded}
            isAddon={isAddon}
          />
        ))}
      </CardsContainer>
      {showComparisonTable && (
        <SWComparisonTable
          editionsToCompare={products}
          productId={comparisonTableProductId ?? ''}
          name="TariffCardsComparisonTable"
          shouldFetchData
        />
      )}
    </ContentContainer>
  );
};

export default SWSectionTariffCards;
