import styled from 'styled-components';
import { rem } from 'polished';

export const CarouselSurround = styled.div`
  width: 100%;
  height: 23.75rem;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.lg - 1}px) {
    height: 24.75rem;
  }
  background: ${({ theme }) => theme.color.primary2.default};
`;
export const CarouselWrapper = styled.section`
  padding: 3.125rem 0;
  height: inherit;
  width: auto;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin: 0 auto;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: 0 3rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 73.75rem;
    margin: 0 auto;
  }
  h2 {
    font-size: 2.5rem;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      line-height: 3rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      line-height: 1.625rem;
    }
  }
  h3 {
    margin-top: -0.5;
    font-size: 1.5rem;
    line-height: 1.625rem;
  }
  [class*='FunctionalCarouselstyle__CarouselWrapper'] {
    margin-top: 3.125rem;
    [class*='FunctionalCarouselstyle__Carousel'] {
      [class*='slick-slider'] {
        [class*='slick-list'] {
          [class*='slick-track'] {
            width: 100% !important;
            margin: 0 auto;
            [class*='slick-slide'] {
              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
                margin: 0 1.75rem;
              }
              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                margin: 0 6.5rem;
              }
              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md + 1}px) {
                margin: 0 2.5rem;
              }
            }
          }
        }
      }
      [class*='CarouselArrowstyle__CarouselArrow'] {
        &[direction='previous'],
        &[direction='next'] {
          position: absolute;
          top: 4rem;
          z-index: 1;
        }
      }
      [class*='FunctionalCarouselstyle__CarouselDotPagerList'] {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
          margin-top: -0.5rem;
        }
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          margin-top: 1.5rem;
        }
      }
    }
  }
  [class*='FunctionalCarouselstyle__CarouselWrapper'] {
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
      padding: 0 6%;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      padding: 0 9%;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      padding: 0;
    }
  }
`;
export const CarouselIconsPictureWrapper = styled.picture`
  height: 6.25rem;
  width: 6.25rem !important;
`;
export const CarouselIconsSource = styled.source`
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 2.5rem;
`;
export const CarouselIconsImage = styled.img`
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: ${rem('100px')};
  height: ${rem('100px')};
`;
