import styled from '@emotion/styled';
import { rem } from 'polished';

export const BlockedListErrorWrapper = styled.div`
  padding: ${rem('76px')} 0 ${rem('270px')};
  background: ${({ theme }) => theme.color.primary2.default};
`;

export const BlockedListErrorCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${rem('20px')};
  > span {
    text-align: center;
    margin-bottom: ${rem('10px')};
  }
`;
