import { HeadingProps, TextProps } from '@chakra-ui/react';
import { DetailsInfoItem, DetailsTitleItem } from './DetailsAndPricing.styles';

export const DetailsInfo = ({ children, ...props }: TextProps) => (
  <DetailsInfoItem as="h6" noOfLines={2} color="mineShaft" {...props}>
    {children}
  </DetailsInfoItem>
);

export const DetailsTitle = ({ children, ...props }: HeadingProps) => (
  <DetailsTitleItem as="h5" noOfLines={2} color="mineShaft" {...props}>
    {children}
  </DetailsTitleItem>
);
