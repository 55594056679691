import { Box } from '@chakra-ui/react';
import { rem } from 'polished';
import styled from 'styled-components';

export const WrapSimpleGrid = styled.div`
  display: flex;
  flex-direction: column;

  [class*='CardBuilderstyle'] {
    [class*='SlotsWrapper'] {
      > *:first-of-type > div,
      > *:last-child > div {
        padding: 0;
      }
    }
  }
`;

export const RelatedArticlesCarouselWrapper = styled(Box)`
  width: 100%;
  max-width: ${rem('1180px')};
  margin: 0 auto;

  .slick-slider {
    user-select: auto;
  }

  .slick-slide > div {
    display: flex;
    justify-content: center;
    height: 100%;

    > div {
      max-width: ${rem('345px')};

      @media (min-width: ${props => props.theme.breakpoints.md}px) {
        max-width: ${rem('382px')};
      }
    }
  }

  [class*='CarouselArrowstyle__CarouselArrow-sc'] {
    z-index: 4;
    box-shadow: 0 ${rem('2px')} ${rem('4px')} 0 rgba(203, 203, 203, 1);
    background: var(--vodafone-colors-white);
    width: ${rem('50px')};
    height: ${rem('50px')};

    [class*='Interactionstyle__Button-sc'] {
      border: none;

      [class*='Iconstyle__Icon-sc'] {
        svg {
          width: ${rem('30px')};
          height: ${rem('30px')};

          :focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  [class*='FunctionalCarouselstyle__CarouselDotPagerList-sc'] {
    margin: 0;

    li {
      margin: ${rem('32px')} 0;

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        margin: ${rem('30px')} 0 ${rem('12px')};
      }
    }
  }
`;

export const CardWrapper = styled.div`
  [class*='Containerstyle__Container'][class*='Interactionstyle__BlankInteraction'] {
    margin-bottom: ${rem('20px')};
  }
`;

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rem('32px')} 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    margin: ${rem('50px')} 0;
  }

  a {
    color: var(--mineShaft);
    font-size: ${rem('20px')};
    line-height: ${rem('23px')};
    height: ${rem('51px')};
    width: auto;
    border: ${rem('1px')} solid #666666;
  }
`;
