import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const ContentWrapper = styled(Stack)`
  && {
    align-items: center;
  }
`;

export const ExtraInformationWrapper = styled(Flex)`
  && {
    padding-top: ${rem('12px')};
    padding-bottom: ${rem('12px')};
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: fit-content;
  }
`;

export const ExtraInformationText = styled(Text)`
  && {
    font-size: ${rem('14px')};
  }
`;

export const IconWrapper = styled(Box)`
  && {
    margin-left: ${rem('11px')};
    transition: 0.2s ease-out;
  }
`;

export const ExtraInformationButton = styled(Button)`
  && {
    background: transparent;
    text-decoration: underline;
    border-radius: none;
    line-height: ${rem('19px')};
    margin-bottom: 0;
    &:focus {
      outline: none;
      background: transparent;
    }
    &:hover {
      background: transparent;
    }
  }
`;

export const TextProps = {
  lineHeight: { base: rem('17px'), md: 6 },
  my: 0,
  px: 5,
  width: 'full',
};
