import { AxiosError } from 'axios';

import { api } from './auth.services';

type GetCountriesResponse = Country[];

export type Country = {
  name: string;
  code: string;
};

type GetCountiesResponse = County[];

export type GetAddressResponse = {
  postcode: string;
  city: string;
  countryCode: string;
  stateOrProvince: string;
  address1: string;
};

export type County = {
  name: string;
};

export type GetAddressType = 'POSTAL_CODE' | 'TEXT';

export const getCountries = async () => {
  const res = await api.get<GetCountriesResponse>(`/address/country`).catch<AxiosError>(error => error);
  return res;
};

export const getCounties = async (countryCode: string) => {
  const res = await api
    .get<GetCountiesResponse>(`/address/county?countryCode=${countryCode}`)
    .catch<AxiosError>(error => error);
  return res;
};

export const getAddress = async (value: string, addressType: GetAddressType) => {
  const res = await api
    .get<GetAddressResponse[]>(`/address?type=${addressType}&value=${value}`)
    .catch<AxiosError>(error => error);
  return res;
};
