import Button from '@vfuk/core-button';
import Divider from '@vfuk/core-divider';

import SWPortfolioCarousel from '../../Elements/SWPortfolioCarousel';
import SWPortfolioDisplayHeader from '../../Elements/SWPortfolioDisplayHeader';
import { Container, PortfolioWrapper, WrapButton } from './SWPortfolio.styles';
import { SWPortfolioProps } from './SWPortfolio.types';
import usingOpcoUrl from '@/helpers/prefixHelper';

const SWPortfolio = ({ portfolioHeader, portfolioCards, portfolioHeaderAlign, portfolioDivider }: SWPortfolioProps) => {
  return (
    <PortfolioWrapper position={portfolioHeaderAlign} data-selector="portfolio display header">
      <Container position={portfolioHeaderAlign}>
        <div>
          <SWPortfolioDisplayHeader {...portfolioHeader} />
          {portfolioHeader?.primaryButton && (
            <WrapButton>
              <Button
                dataSelectorPrefix={portfolioHeader.heading.toLowerCase()}
                text={portfolioHeader.primaryButton.label}
                href={usingOpcoUrl(portfolioHeader.primaryButton.url)}
              />
            </WrapButton>
          )}
        </div>

        <SWPortfolioCarousel
          title={`${portfolioHeader?.heading} Carousel`}
          srName={`${portfolioHeader?.heading} Carousel`}
          cards={portfolioCards}
          position={portfolioHeaderAlign}
        />

        {portfolioHeader?.primaryButton && (
          <WrapButton className="mobile">
            <Button
              dataSelectorPrefix={portfolioHeader.heading.toLowerCase()}
              text={portfolioHeader.primaryButton.label}
              href={usingOpcoUrl(portfolioHeader.primaryButton.url)}
            />
          </WrapButton>
        )}
      </Container>
      {portfolioDivider && <Divider appearance="alt3" noMargin />}
    </PortfolioWrapper>
  );
};

export default SWPortfolio;
