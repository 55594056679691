import Heading from '@vfuk/core-heading';
import Icon from '@vfuk/core-icon';
import SimpleGrid from '@vfuk/core-simple-grid';
import React, { FC } from 'react';

import { addLinkTestId } from '../../../../helpers/addTestId';
import { BusinessAppsBannerInner, BusinessAppsBannerWrapper, CardWrapper } from './SWBusinessAppsBanner.styles';
import { SWBusinessAppsBannerProps } from './SWBusinessAppsBanner.types';

const SWBusinessAppsBanner: FC<SWBusinessAppsBannerProps> = (data: SWBusinessAppsBannerProps) => {
  const { backgroundImage, mobileBackgroundImage, heading, iconCards } = data;

  return (
    <BusinessAppsBannerWrapper backgroundImage={backgroundImage} mobileBackgroundImage={mobileBackgroundImage}>
      <BusinessAppsBannerInner>
        <SimpleGrid
          layout={{
            sm: [12],
            md: [12, 4, 4, 4],
          }}
          justify="center"
          spacing={1}
        >
          <Heading
            level={2}
            justify={'center'}
            text={heading}
            data-testid={addLinkTestId('business apps banner', heading)}
          />
          {iconCards.map((card, index) => (
            <CardWrapper key={card.iconName}>
              <Icon name={card.iconName} group="system" appearance="primary" size={5} inverse />
              <Heading level={3} justify={'center'} text={card.headingText} size={2} />
            </CardWrapper>
          ))}
        </SimpleGrid>
      </BusinessAppsBannerInner>
    </BusinessAppsBannerWrapper>
  );
};

export default SWBusinessAppsBanner;
