import { useLazyQuery } from '@apollo/client';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { navigate, useLocation } from '@reach/router';
import ArticleCard from '@vfuk/core-article-card';
import Heading from '@vfuk/core-heading';
import SimpleGrid from '@vfuk/core-simple-grid';
import { navigate as navigateGatsby } from 'gatsby';
import { getImage, getSrc } from 'gatsby-plugin-image';
import path from 'path';
import { FC, useEffect, useMemo } from 'react';
import { TfiClose } from 'react-icons/tfi';
import { v4 as uuidv4 } from 'uuid';

import { GET_PRODUCT_INFO } from '@/graphql/queries/product';
import { useAllowedUris } from '@/hooks';
import { useModalContext } from '@/hooks/modal/useModal';
import {
  clearUserSession,
  deleteBasket,
  setIsFromAppDirect,
  setRedirectPath,
  setUserSession,
  useAppDispatch,
  useAppSelector,
} from '@/store';

import { ArticleCardWrapper, LoginModalWrapper } from './SWLoginModal.styles';
import { ModalCardProps, SWLoginModalProps } from './SWLoginModal.types';
import config from './SWLoginModalConfig';
import usingOpcoUrl, { getOpcoUrl } from '@/helpers/prefixHelper';

const SWLoginModal: FC<SWLoginModalProps> = ({ richTextHeading, modalCards }) => {
  const { state, scope: defaultScope, response_type } = config;
  const location = useLocation();
  const { origin, pathname } = location;

  const dispatch = useAppDispatch();
  const userBasket = useAppSelector(state => state.userBasket.data);
  const { user, isLoading, isFromAppDirect } = useAppSelector(({ userSession }) => userSession);

  const allowedUrls = useAllowedUris();
  const redirectUri = useMemo(
    () => `${origin}${path.join('/', allowedUrls.paths.selectAccountPage)}`,
    [origin, pathname]
  );
  const { isLoginModalOpen, toggleModal, isErrorModalOpen } = useModalContext();
  const lastItem = useMemo(() => userBasket.items?.slice(-1)[0], [userBasket]);
  const [getProduct, { data, loading, error }] = useLazyQuery(GET_PRODUCT_INFO);

  const scope = process.env.GATSBY_APIX_SCOPE || defaultScope;

  const loginUrl = useMemo(() => {
    return `${process.env.GATSBY_APIX_BASE_URL}/${process.env.GATSBY_AUTHORIZE_URL}?client_id=${
      process.env.GATSBY_APIX_KEY_CLIENT_ID
    }&state=${state}&redirect_uri=${getOpcoUrl(
      redirectUri
    )}&scope=${scope}&response_type=${response_type}&login_hint=OPCO:${process.env.GATSBY_OPCO}&acr_values=${
      process.env.GATSBY_ACR_VALUE_LOGIN
    }&prompt=login`;
  }, [redirectUri]);

  const handleModalClose = () => {
    if (!user) {
      dispatch(deleteBasket());
    }
    if (allowedUrls.isSelectAccountPage) {
      navigateGatsby(-1);
    } else {
      dispatch(clearUserSession());
      toggleModal(false, 'Login');
    }
  };

  const handleLoginButton = (card: ModalCardProps) => {
    if (card.primaryButton.label.toLowerCase() === 'login') {
      dispatch(clearUserSession());
      isFromAppDirect && dispatch(setIsFromAppDirect(isFromAppDirect));
      const uuid = storeUUIDs();
      navigate(`${loginUrl}&nonce=${uuid}`);
    } else {
      usingOpcoUrl(card.primaryButton.url || '#', true);
    }
  };

  const storeUUIDs = () => {
    const uuid = uuidv4();
    sessionStorage.setItem('nonce', uuid);
    sessionStorage.setItem('state', state);
    return uuid;
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !!window.document.referrer) {
      const referrer = new URL(window.document.referrer);
      const hostname = referrer.hostname.includes('www') ? referrer.hostname.slice('www.'.length) : referrer.hostname;
      const isItFromAppDirect =
        (hostname.includes('appdirect.com') || hostname.includes('marketplace.vodafone.ie')) &&
        !hostname.includes('businessmarketplace.vodafone.ie');

      if (isItFromAppDirect) {
        dispatch(setUserSession({ isLoading: false, user: null, error: null }));
        dispatch(setIsFromAppDirect(isItFromAppDirect));
      }
    }
  }, []);

  useEffect(() => {
    if (lastItem?.productId && !error) {
      getProduct({
        variables: { productId: lastItem.productId },
      });
    }
  }, [lastItem, loading, error]);

  useEffect(() => {
    (async () => {
      if (
        user?.userId &&
        !isFromAppDirect &&
        !pathname.endsWith('product-list') &&
        pathname.includes('product-list') &&
        data?.contentfulMarketplaceProductBundle?.configurationLink?.slug
      ) {
        dispatch(
          setRedirectPath(
            getOpcoUrl(`${path.join('/', data.contentfulMarketplaceProductBundle.configurationLink.slug)}`)
          )
        );
      }
    })();
  }, [data, pathname, user?.userId, isFromAppDirect]);

  return (
    <Modal
      isOpen={isLoginModalOpen && !isLoading && !isErrorModalOpen}
      size="5xl"
      onClose={handleModalClose}
      data-testid="loginModal"
    >
      <ModalOverlay bgColor="rgba(21, 21, 21, 0.9)" />
      <ModalContent bgColor="#212121" borderRadius="none" boxShadow="none">
        <ModalCloseButton
          color="white"
          sx={{
            svg: {
              boxSize: 6,
            },
          }}
        >
          <TfiClose />
        </ModalCloseButton>
        <LoginModalWrapper>
          <Heading level={3} justify="center">
            {documentToReactComponents(JSON.parse(richTextHeading?.raw))}
          </Heading>
          <SimpleGrid
            columns={{
              sm: 1,
              md: 2,
            }}
            spacing={0}
          >
            {modalCards.map((card, index) => {
              const modalCardsImageSrc =
                getSrc(card.primaryImage) || getImage(card.primaryImage)?.placeholder?.fallback;
              return (
                <ArticleCardWrapper
                  key={card.name}
                  backgroundColor={card.cardTextColor === '#ffffff' ? '#b30101' : '#ffffff'}
                  buttonStyle={card.cardTextColor == '#ffffff' ? 'primary' : 'secondary'}
                >
                  <ArticleCard
                    appearance={card.cardTextColor === '#ffffff' ? 'alt1' : 'primary'}
                    level={card.cardTextColor === '#ffffff' ? 2 : 3}
                    primaryImage={{
                      sm: {
                        src: modalCardsImageSrc,
                      },
                    }}
                    primaryButton={{
                      text: card.primaryButton.label,
                      onClick: () => handleLoginButton(card),
                    }}
                    headingText={card.headingText}
                    text={card.text}
                    dataSelectorPrefix={`login card-${card.headingText.toLowerCase()}`}
                  />
                </ArticleCardWrapper>
              );
            })}
          </SimpleGrid>
        </LoginModalWrapper>
      </ModalContent>
    </Modal>
  );
};

export default SWLoginModal;
