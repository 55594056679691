import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import path from 'path';
import { useTranslation } from 'react-i18next';
import { withPrefix } from 'gatsby';
import { useReadLocalStorage } from 'usehooks-ts';

import { InteractionEvent } from '@source-web/interaction';
import { useModalContext } from '@/hooks/modal/useModal';

import {
  UserBasketItem,
  addToBasket,
  isInBasket,
  itemQuantityInBasket,
  removeFromBasket,
  setBasketSummaryOpen,
  setRedirectPath,
  updateUserBasket,
  useAppDispatch,
  useAppSelector,
} from '@/store';

import { ProductBundle } from '@/types/Product.types';
import { SWItemProduct } from '@/components/pageBuilder/Elements/SWPricingCard/SWPricingCard.types';
import { useAllowedUris } from './useAllowedUris';
import usingOpcoUrl, { getOpcoUrl } from '@/helpers/prefixHelper';

interface IUseBasket {
  initialQty?: number;
  item: SWItemProduct;
  nextStep?: string;
  pathname: string;
}

type HandleBasketProps = (itemStepName: UserBasketItem['configTab'], mainProduct: ProductBundle | undefined) => void;

interface IHandleButtonOptions {
  itemStepName?: UserBasketItem['configTab'];
  mainProduct?: ProductBundle;
}

export const useBasket = ({ item, pathname, nextStep, initialQty = 1 }: IUseBasket) => {
  const { buttonsAction } = item;

  const allowedUris = useAllowedUris();

  const { toggleModal } = useModalContext();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ userSession }) => userSession);
  const userBasket = useAppSelector(({ userBasket }) => userBasket.data);

  const [quantity, setQuantity] = useState<number>(initialQty);

  const stackableProduct = useReadLocalStorage<boolean>('vf_stackable');

  const minAmount = initialQty;
  const addToBasketActs = ['addToBasket', 'addToBasketWithQty', 'updateBasketWithQty'];
  const isWithQty = ['addToBasketWithQty', 'updateBasketWithQty'].includes(String(buttonsAction));

  const editionIdsToCheck: string[] = [];

  for (let i = 0; i < userBasket.items?.length; i++) {
    const editionIdsInBasket = userBasket.items.filter(edition => edition)[i].productId;
    editionIdsToCheck.push(editionIdsInBasket);
  }

  const notSelectedEditionIds = item.editionIds?.filter(editionId => editionId !== item.productId) || [];
  const editionToRemove = notSelectedEditionIds.filter(editionId => editionIdsToCheck.includes(editionId)) || [];

  const inBasket = isInBasket(userBasket, item);

  const nextStepSlug = useMemo(() => {
    let step;
    const { slug } = item;
    const baseSlug = slug ?? pathname;
    const stepNumber = nextStep ?? 1;
    const stepString = 'step-';

    if (pathname.includes(stepString)) {
      step = pathname.substring(pathname.length - stepString.length - 1);
    } else {
      step = `${baseSlug}/${stepString}${stepNumber}`;
    }
    return (
      step &&
      withPrefix(
        pathname.includes(stepString)
          ? `${pathname.substring(0, pathname.indexOf(step))}${stepString}${stepNumber}`
          : `${step}`
      )
    );
  }, [pathname]);

  const insideConfigPage = useMemo(() => {
    return !pathname.includes('/marketplace/office-anywhere/configuration');
  }, [pathname]);

  const handleDecreaseQty = () => {
    setQuantity(prev => {
      if ((buttonsAction === 'addToBasketWithQty' || buttonsAction === 'updateBasketWithQty') && quantity > minAmount) {
        return prev - 1;
      }
      if (
        (buttonsAction === 'addToBasketWithQty' || buttonsAction === 'updateBasketWithQty') &&
        quantity <= minAmount
      ) {
        return 0;
      }
      return prev;
    });
  };

  const handleIncreaseQty = () => {
    setQuantity(prev => {
      if (quantity === 0) {
        return prev + minAmount === 5 ? 5 : 1;
      }
      if (buttonsAction === 'addToBasket' && quantity >= 0) {
        return prev + minAmount;
      }
      if (
        (buttonsAction === 'addToBasketWithQty' || buttonsAction === 'updateBasketWithQty') &&
        quantity >= minAmount
      ) {
        return prev + 1;
      }
      if (buttonsAction === 'updateBasketWithQty' && quantity < minAmount) {
        return minAmount;
      }
      return prev;
    });
  };

  const handleNoStackableProducts = () => {
    if (!stackableProduct) {
      dispatch(removeFromBasket(editionToRemove.toString()));
    }
  };

  const openBasketSummary = () => {
    dispatch(setBasketSummaryOpen(true));
  };

  const handleNextStep = (qty: number) => {
    if (pathname.includes('/step-')) {
      openBasketSummary();
    } else if (
      item?.msftProduct &&
      nextStepSlug &&
      insideConfigPage &&
      !item.slug.includes('apphelp-microsoft-365-migration') &&
      !pathname.includes('business-boost')
    ) {
      if (user?.userId && qty > 0) {
        usingOpcoUrl(nextStepSlug, true);
      } else {
        dispatch(setRedirectPath(getOpcoUrl(nextStepSlug)));
      }
    } else {
      openBasketSummary();
    }
  };

  const handleConfigureYourPack = () => {
    if (pathname.includes('business-boost')) {
      const redirectPath = withPrefix(
        path.join(allowedUris.paths.businessBoostPage, `/configuration/${item.slug}/step-0`)
      );

      if (user?.userId) {
        usingOpcoUrl(redirectPath, true);
      } else {
        dispatch(setRedirectPath(getOpcoUrl(redirectPath)));
      }
    } else if (!pathname.includes('business-boost')) {
      const redirectPath = item?.msftProduct
        ? withPrefix(path.join(allowedUris.paths.officeAnywherePage, `/configuration/${item.slug}/step-1`))
        : withPrefix(path.join(allowedUris.paths.officeAnywherePage, `/configuration/${item.slug}/step-0`));

      if (user?.userId) {
        usingOpcoUrl(redirectPath, true);
      } else {
        dispatch(setRedirectPath(getOpcoUrl(redirectPath)));
      }
    }
  };

  const handleAddToBasket: HandleBasketProps = (itemStepName, mainProduct) => {
    handleNoStackableProducts();

    const qty = quantity === 0 ? initialQty : quantity;
    setQuantity(qty);

    dispatch(
      addToBasket({
        ...item,
        quantity: qty,
        ...(itemStepName && mainProduct && { configTab: itemStepName, mainProduct }),
      })
    );

    handleNextStep(qty);
  };

  const handleUpdateUserBasket: HandleBasketProps = (itemStepName, mainProduct) => {
    handleNoStackableProducts();

    const qtyMinAmout = quantity < 1 ? 0 : minAmount;
    const qty = quantity >= minAmount && quantity !== 1 ? quantity : qtyMinAmout;

    if (qty > 0) {
      setQuantity(qty);
      dispatch(
        updateUserBasket({
          ...item,
          quantity: qty,
          ...(itemStepName && mainProduct && { configTab: itemStepName, mainProduct }),
        })
      );
      dispatch(setBasketSummaryOpen(true));
    } else {
      isInBasket(userBasket, item) && dispatch(removeFromBasket(item));
    }

    handleNextStep(qty);
  };

  const handleButtonAction = (event: InteractionEvent, options: IHandleButtonOptions = {}) => {
    const { itemStepName, mainProduct } = options;
    event.preventDefault();

    const isAddToBasket = buttonsAction && addToBasketActs.includes(buttonsAction);
    const itemInBasket = isInBasket(userBasket, item);
    const isUpdateBasket = buttonsAction && ['addToBasketWithQty', 'updateBasketWithQty'].includes(buttonsAction);

    if (!itemInBasket && isAddToBasket) {
      handleAddToBasket(itemStepName, mainProduct);
    } else if (
      itemInBasket &&
      isAddToBasket &&
      (quantity === 0 || itemQuantityInBasket(userBasket, item) === quantity)
    ) {
      dispatch(removeFromBasket(item.productId));
    } else if (itemInBasket && isUpdateBasket && user?.userId) {
      handleUpdateUserBasket(itemStepName, mainProduct);
    }

    if (buttonsAction === 'configureYourPack') {
      handleConfigureYourPack();
    }

    if (!user?.userId) {
      toggleModal(true, 'Login');
    }
  };

  const handleChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const number = Number(value);
    setQuantity(number ?? 0);
  };

  const isButtonDisabled = () => {
    if (item.productType !== 'addon') {
      return false;
    }
    const bundleExists = userBasket.items.some(
      basketItem => basketItem.productType === 'bundle' && basketItem.solutionType === item.solutionType
    );

    // Disable addon buttons if a bundle with the same solutionType doesn't exist doesn't exist
    return !bundleExists;
  };

  const buttonText = useCallback(() => {
    if (!isInBasket(userBasket, item) && buttonsAction && addToBasketActs.includes(buttonsAction)) {
      return t('AddToBasket');
    }

    if (buttonsAction === 'configureYourPack') {
      return t('ConfigureYourPack');
    }

    if (isInBasket(userBasket, item) && buttonsAction && addToBasketActs.includes(buttonsAction)) {
      const itemQuantity = itemQuantityInBasket(userBasket, item);

      if (quantity === 0 || itemQuantity === quantity) {
        return t('RemoveFromBasket');
      }

      return t('UpdateBasket');
    }

    return t('AddToBasket');
  }, [userBasket, item, quantity, buttonsAction]);

  useEffect(() => {
    if (isInBasket(userBasket, item)) {
      const { quantity } = userBasket.items.filter(i => i.productId === item.productId && i)[0] || 0;
      setQuantity(quantity ?? (minAmount === 5 ? 5 : 1));
    }
  }, [userBasket]);

  return {
    inBasket,
    quantity,
    isWithQty,
    buttonText,
    handleDecreaseQty,
    handleIncreaseQty,
    handleButtonAction,
    handleChangeQuantity,
    isButtonDisabled,
  };
};
