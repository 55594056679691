import { graphql, useStaticQuery } from 'gatsby';

import { SWTopNavigationProps } from '@/components/Global/SWTopNavigation/SWTopNavigation.types';

export const useSWTopNavigation: () => SWTopNavigationProps = () => {
  const {
    allContentfulSectionSwTopNavigation: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSectionSwTopNavigation {
        nodes {
          id
          contentful_id
          __typename
          name
          topNav {
            id
            srText
            leftLinks {
              id
              text
              href
              active
              url
            }
          }
          primaryNav {
            id
            srText
            links {
              id
              text
              href
              url
              links {
                id
                text
                href
                url
              }
            }
          }
          logoNav {
            id
            text
            href
            url
          }
          secondaryNav {
            id
            name
            iconNavLinks {
              id
              text
              href
              icon
              url
              links {
                id
                url
              }
            }
            user {
              id
              srText
              login {
                id
                text
                href
                url
              }
              logout {
                id
                text
                href
                url
              }
              links {
                id
                text
                href
                url
              }
            }
          }
        }
      }
    }
  `);

  return nodes[0] || {};
};
