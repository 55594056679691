import { TextStack, TextStackProps } from '@source-web/text-stack';

import { BasketPricesProps } from '../BasketSummary.types';
import { PricesWrapper } from './BasketPrices.styles';

const BasketPrices = ({
  price,
  unit = '€',
  priceSuffix,
  monthlyPricePrefix,
  upfrontPricePrefix,
}: BasketPricesProps) => {
  const textStackDefaultProps: Partial<TextStackProps> = {
    size: 1,
    level: 2,
    currency: {
      symbol: unit,
      justify: 'left',
    },
  };

  return (
    <PricesWrapper>
      {price?.upfront && (
        <TextStack
          {...textStackDefaultProps}
          text={{
            prefix: upfrontPricePrefix,
            suffix: priceSuffix,
            main: price?.upfront?.gross ?? '0.00',
          }}
        />
      )}
      <TextStack
        {...textStackDefaultProps}
        text={{
          prefix: monthlyPricePrefix,
          suffix: priceSuffix,
          main: price.monthly?.gross ?? '0.00',
        }}
      />
    </PricesWrapper>
  );
};

export default BasketPrices;
