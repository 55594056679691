import BundleIncludesList from './BundleIncludesList/BundleIncludesList';
import { IncludesHeading, IncludesList, IncludesWrapper } from './BundleIncludes.styles';
import { SWBundleIncludesProps } from './BundleIncludes.types';

const BundleIncludes = ({ title, items, backgroundColor, color, showToggle, ...props }: SWBundleIncludesProps) => {
  return (
    <IncludesWrapper
      backgroundColor={backgroundColor || 'monochrome2.default'}
      direction="column"
      pt={4}
      pb={title === 'Add-On' ? 0 : 6}
      px={5}
      {...props}
    >
      <IncludesHeading as="h6" color={color || 'mineShaft'}>
        {title}
      </IncludesHeading>
      <IncludesList styleType="none" m={0} mt={4} p={0} spacing={3}>
        {items?.map(item => (
          <BundleIncludesList key={item.id} item={item} color={color} showToggle={showToggle} />
        ))}
      </IncludesList>
    </IncludesWrapper>
  );
};

export default BundleIncludes;
