import { HLocation } from '@reach/router';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { RedirectWithAuthCodeError } from '@/services/auth.services';
import { AddToBasketEvent } from '@/helpers/GlobalEvents';

export type UserAccount = {
  barId: string;
  customerAccountId: string;
  customerAccountName: string;
  customerId: string;
  name: string;
  segment: string;
  companyName: string;
};

export type UserSessionProps = {
  username?: string;
  accounts: UserAccount[];
  userId?: string;
  organizationId?: string;
  selectedAccount?: UserAccount;
  csrfToken?: string;
};

export type UserBlocklistError = {
  error: string;
  errorCode: string;
  message: string;
  timestamp: string;
};

export type UserSessionSliceProps = {
  error?: AxiosError | RedirectWithAuthCodeError | UserBlocklistError | null;
  isFromAppDirect: boolean;
  isLoading: boolean;
  isFetching: boolean;
  redirectPath: HLocation['pathname'];
  redirectUri?: HLocation['pathname'];
  postLoginEvent?: Pick<AddToBasketEvent, 'detail'> | null;
  user?: UserSessionProps | null;
};

export const initialState: UserSessionSliceProps = {
  error: null,
  isFromAppDirect: false,
  isLoading: false,
  isFetching: false,
  redirectPath: '/',
  user: null,
};

const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {
    setIsFromAppDirect: (state, action: PayloadAction<UserSessionSliceProps['isFromAppDirect']>) => {
      state.isFromAppDirect = action.payload;
    },
    setRedirectPath: (state, action: PayloadAction<UserSessionSliceProps['redirectPath']>) => {
      state.redirectPath = action.payload;
    },
    setRedirectUri: (state, action: PayloadAction<UserSessionSliceProps['redirectUri']>) => {
      state.redirectUri = action.payload;
    },
    setPostLoginEvent: (state, action: PayloadAction<UserSessionSliceProps['postLoginEvent']>) => {
      state.postLoginEvent = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<UserSessionSliceProps['isFetching']>) => {
      state.isFetching = action.payload;
    },
    setUserSession: (
      state,
      action: PayloadAction<
        Pick<UserSessionSliceProps, 'isLoading' | 'user'> & Partial<Pick<UserSessionSliceProps, 'error'>>
      >
    ) => {
      state.isLoading = action.payload.isLoading;
      if (action.payload.user) {
        state.user = action.payload.user;
      }
      state.error = action.payload.error;
    },
    setLoadingUserSession: (state, action: PayloadAction<UserSessionSliceProps['isLoading']>) => {
      state.isLoading = action.payload;
    },
    clearUserSession: state => {
      state.user = null;
      state.isFromAppDirect = false;
      state.isLoading = false;
      state.error = null;
    },
    clearPostLoginEvent: (state, action: PayloadAction<UserSessionSliceProps['postLoginEvent']>) => {
      state.postLoginEvent = null;
    },
  },
});

export const {
  setIsFromAppDirect,
  setLoadingUserSession,
  setRedirectPath,
  setRedirectUri,
  clearUserSession,
  setUserSession,
  setPostLoginEvent,
  clearPostLoginEvent,
  setIsFetching,
} = userSessionSlice.actions;

export const userSessionReducer = userSessionSlice.reducer;

const persistConfig = {
  key: `vf_${userSessionSlice.name}`,
  version: 1,
  storage: storageSession,
};

export const userSessionPersistReducer = persistReducer<UserSessionSliceProps>(persistConfig, userSessionReducer);
