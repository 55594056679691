import { v4 as uuidv4 } from 'uuid';

import { LoginModalConfigProps } from './SWLoginModal.types';

const config: LoginModalConfigProps = {
  state: uuidv4(),
  scope:
    'openid offline_access phone email profile USERINFO_GET_PROFILE address subscriptions OPENID_TOKEN_SHARING_PRODUCER local_sub',
  response_type: 'code',
};

export default config;
