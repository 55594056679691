import { rem } from 'polished';
import styled from 'styled-components';

type WrapperProps = {
  hasOptions?: boolean;
  isProductListPage?: boolean;
};

export const SelectAndSearchBarWrapper = styled.div<WrapperProps>`
  padding: ${({ isProductListPage }) => (isProductListPage ? `${rem('30px')} 0` : 0)};
  [class*='TextInputstyle__TextInput'] {
    box-sizing: border-box;
    border-right: ${rem('1px')};
    position: relative;
    z-index: 1;
  }
  [id*='selectAndSearchBar'] {
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      max-width: ${({ isProductListPage }) => (isProductListPage ? `${rem('1210px')}` : `${rem('400px')}`)};
      padding: ${({ isProductListPage }) => (isProductListPage ? `${rem('30px')} 0` : 0)};
      margin: 0 auto;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      max-width: ${({ isProductListPage }) => (isProductListPage ? `${rem('1210px')}` : `${rem('500px')}`)};
      padding: ${({ isProductListPage }) => (isProductListPage ? `${rem('30px')} 0` : 0)};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
      max-width: ${({ isProductListPage }) => (isProductListPage ? `${rem('1210px')}` : `${rem('500px')}`)};
      padding: ${({ isProductListPage }) => (isProductListPage ? `${rem('30px')} 0` : 0)};
    }

    div {
      margin: 0;
      :nth-of-type(3) {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          margin-bottom: 0.2rem;
        }

        div:nth-of-type(1) {
          margin-right: ${({ hasOptions }) => (hasOptions ? `1rem` : `0rem`)};
          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            margin-bottom: 1rem;
            margin-right: 0;
          }
        }
      }
      h2 {
        margin-bottom: 1rem;
        font-size: 2.25rem;
        line-height: 2.438rem;
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
      h3 {
        margin-bottom: 2rem;
        font-size: 1.25rem;
        line-height: 1.688rem;
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          font-size: 1.5rem;
          line-height: 1.875rem;
        }
      }
      [class*='SelectInputstyle__SelectInput'] {
        height: 3.125rem;
        font-size: 1.125rem;
      }
      [class*='SearchInput'] {
        max-width: fit-content;
        [class*='TextInputWrapper'] {
          padding-right: 7rem;
          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            padding-right: 0;
          }
          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
            margin-left: 1rem;
          }
          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
            padding-right: 8rem;
          }
          input {
            height: 3.125rem;
            padding-right: 6rem;
            font-size: 1.125rem;
            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              box-sizing: border-box;
            }
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              box-sizing: content-box;
              height: 2rem;
            }
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
              padding-right: 7rem;
            }
          }
        }
        [class*='InteractionIcons'] {
          [class*='CancelAndDivider'] {
            align-items: center;
          }
          svg {
            width: 1.875rem;
            height: 2rem;
          }
        }
      }
    }
  }
`;

export const SelectAndSearchBarAndImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary2.default};
  [class*='homeSearch'] {
    height: auto;
    position: relative;
    > div {
      position: relative;
      z-index: 3;
      padding: 2rem 1rem;
      width: 100%;
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        padding: 3.125rem 0;
      }
    }
    picture {
      position: absolute;
      width: 100%;
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
  [class*='narrowSearch'] {
    > div {
      position: relative;
      z-index: 3;
      padding: 2rem 1rem;
      width: 100%;
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
        padding: 2rem 1rem;
      }
      [id*='selectAndSearchBar'] {
        max-width: 73.75rem;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: nowrap;
        div {
          margin: 0;
          :nth-of-type(2) {
            width: auto;
          }
          :nth-of-type(3) {
            margin-left: 1rem;
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              margin-left: 0;
            }
          }
          [class*='TextInputstyle__TextInput'] {
            font-size: 0.875rem;
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              font-size: 1.125rem;
            }
          }
          [class*='ButtonWithIconstyle__ButtonWithIcon-sc'] {
            min-width: auto;
            width: 5rem;
            height: 3.125rem;
            font-size: 0.875rem;
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              width: 7rem;
              font-size: 1.125rem;
            }
            svg {
              width: 1.875rem;
              height: 1.875rem;
            }
          }
        }
      }
    }
  }
`;
