import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { ContentWrapper, Wrapper } from './SWProductIntro.styles';
import { SWProductIntroProps } from './SWProductIntro.types';

const SWProductIntro = ({ introText, backgroundColor }: SWProductIntroProps) => {
  return (
    <Wrapper backgroundColor={backgroundColor} data-testid="productIntro-wrapper">
      <ContentWrapper>{introText?.raw && documentToReactComponents(JSON.parse(introText.raw))}</ContentWrapper>
    </Wrapper>
  );
};

export default SWProductIntro;
