import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const FooterWrapper = styled(Box)`
  width: 100%;
  padding: ${rem('50px')} ${rem('16px')} ${rem('20px')};

  > [class*='Paragraphstyle__Paragraph'] {
    color: ${({ theme }) => theme.color.primary2.default};
    margin-bottom: 0;
    font-size: ${rem('18px')};
    line-height: ${rem('18px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: ${rem('24px')};
      line-height: ${rem('28px')};
    }
  }

  [class*='Footerstyle__IconsWrapper'],
  [class*='Footerstyle__DividerWrapper'] {
    display: none;
  }

  [class*='Footerstyle__Footer-sc'] {
    background: none;
    padding: 0;
    [class*='SimpleGridstyle__SimpleGrid-sc'] {
      max-width: ${rem('1180px')};
      margin: 0 auto;
      [class*='SimpleGridstyle__SimpleGridColumn'] {
        h2 {
          font-size: ${rem('28px')};
          font-weight: 400;
          line-height: ${rem('32px')};
          margin-bottom: ${rem('24px')};
        }
      }
    }

    [class*='LegalLinksstyle__LinksWrapper'] {
      max-width: ${rem('1180px')};
      margin: ${rem('50px')} 0 ${rem('20px')};
      [class*='LegalLinksstyle__LinksRow'] {
        width: 100%;
        overflow: hidden;
        margin-top: 0;
        [class*='LegalLinksstyle__RowListGroup'] {
          width: 100%;
          a {
            line-height: ${rem('24px')};
            color: ${({ theme }) => theme.color.primary2.default};
          }
        }
      }
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin: 0 auto;
      }
    }

    [class*='CompanyInfostyle__CompanyInfo'] {
      max-width: ${rem('1180px')};
      margin: 0 auto;
      margin-top: ${rem('16px')};
      p {
        text-align: left;
        font-size: ${rem('13px')};
        line-height: ${rem('15px')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin-top: 0;
        position: relative;
        top: ${rem('-10px')};
        display: flex;
        justify-content: right;
        p {
          text-align: right;
          line-height: 0px;
        }
      }
    }

    a:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.color.primary2.default};
    }
  }
`;

export const IconsWrapper = styled(Flex)`
  gap: ${rem('84px')};
  margin: ${rem('16px')} 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: ${rem('18px')} 0;
  }
`;

export const TitleWrapper = styled(Box)`
  max-width: ${rem('1180px')};
  margin: ${rem('20px')} 0;
  display: none;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: 0 auto;
    display: block;
  }

  [class*='Paragraphstyle__Paragraph-sc'] {
    color: ${({ theme }) => theme.color.primary2.default};
    margin-bottom: 0;
    font-size: ${rem('18px')};
    line-height: ${rem('18px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: ${rem('24px')};
      line-height: ${rem('28px')};
    }
  }
`;

export const IconsWrapperSocial = styled(Flex)`
  gap: ${rem('24px')};
  margin: ${rem('16px')} 0;
  align-items: center;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: ${rem('18px')} 0;
  }
`;

export const LinksWrapper = styled(Box)`
  max-width: ${rem('1180px')};
  margin: ${rem('20px')} 0;
  border-bottom: ${rem('1px')} solid ${({ theme }) => theme.color.primary3.default};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: 0 auto ${rem('16px')};
  }
  [class*='Paragraphstyle__Paragraph-sc'] {
    color: ${({ theme }) => theme.color.primary2.default};
    margin-bottom: 0;
    font-size: ${rem('18px')};
    line-height: ${rem('18px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: ${rem('24px')};
      line-height: ${rem('28px')};
      display: none;
    }
  }
`;

export const LogoWrapper = styled(Box)`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    display: none;
  }
`;

export const LogoWrapperMobile = styled(Box)`
  margin: 0 0 0 auto;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;
