import styled from '@emotion/styled';
import { rem } from 'polished';

export const SuccessPageWrapper = styled.div`
  width: 100%;
  display: flex;
  height: ${rem('660px')};
  background-color: #f4f4f4;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'VodafoneLight';
`;

export const RedCheckmarkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem('100px')};
  height: ${rem('100px')};
`;

export const AbsoluteIcon = styled.div<{ x?: number; y?: number }>`
  position: absolute;
  transform: translate(${props => props.x || 0}px, ${props => props.y || 0}px);
`;

export const SuccessPageTitle = styled.div`
  display: flex;
  font-size: ${rem('40px')};
  justify-content: center;
  align-items: center;
  color: #000;
`;

export const SuccessPageSubtitle = styled.div`
  display: flex;
  font-size: ${rem('24px')};
  justify-content: center;
  align-items: center;
  color: #000;
`;

export const SuccessPageButtonWrapper = styled.div`
  width: ${rem('360px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
