import { rem } from 'polished';
import styled from 'styled-components';

import { SWProductIntroProps } from './SWProductIntro.types';

export const Wrapper = styled.div<Pick<SWProductIntroProps, 'backgroundColor'>>`
  padding: ${rem('70px')} ${rem('10px')} ${rem('80px')};
  background-color: ${({ backgroundColor }) => backgroundColor || `#f8f8f8`};
`;

export const ContentWrapper = styled.div`
  margin: auto;
  max-width: ${rem('1180px')};
  p {
    font-size: ${rem('24px')};
  }
`;
