import CardBuilder from '@vfuk/core-card-builder';
import CardDeviceNameAndRatingSlot from '@vfuk/core-card-device-name-and-rating-slot';
import CardImageSlot from '@vfuk/core-card-image-slot';
import CardSandwichSlot from '@vfuk/core-card-sandwich-slot';
import { CardSandwichSlotProps } from '@vfuk/core-card-sandwich-slot/dist/CardSandwichSlot.types';
import axios from 'axios';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { useEffect, useMemo, useState } from 'react';

import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { retrieveProduct } from '@/services/product.services';

import * as Styles from './SWPortfolioCard.styles';
import { SWPortfolioCardProps } from './SWPortfolioCard.types';

const SWPortfolioCard = ({ heading, product, text, footnote, primaryImage }: SWPortfolioCardProps) => {
  const primaryImageSrc = getSrc(primaryImage) || getImage(primaryImage)?.placeholder?.fallback || '';
  const [prodWithDxlInfo, setProdWithDxlInfo] = useState<SWPortfolioCardProps['product']>();
  const { setErrorInfo, toggleModal } = useModalContext();

  const cardImageSlotProps = {
    alt: heading,
    backgroundImage: {
      sm: {
        src: primaryImageSrc,
        height: '24.75rem',
      },
    },
  };

  const mainPrice = useMemo(() => {
    return product?.dxlInformation?.price.find(price => price.name === 'Cost1' || !!price.price)?.price;
  }, [prodWithDxlInfo]);

  const sandwichSlotProps = {
    primaryPrice: {
      unit: mainPrice ? '€' : '',
      text: {
        main: mainPrice || '',
        mainSuffix: mainPrice ? footnote : '',
      },
    },
    subText: text.text,
  } as CardSandwichSlotProps;

  const withWrap = !!mainPrice && !!footnote;

  useEffect(() => {
    (async () => {
      if (product) {
        product.dxlInformation = undefined;
        const res = await retrieveProduct(product.productId);
        if (!axios.isAxiosError(res) && res.status === 200 && res.data && res.data.id) {
          product.dxlInformation = res.data.dxlInformation;
          setProdWithDxlInfo(product);
        } else {
          setErrorInfo({
            ...(axios.isAxiosError(res) && {
              statusType: res.response?.data?.error,
              statusCode: res.response?.data?.code || res.response?.status,
            }),
            requestUrl: 'catalogues',
          });
          toggleModal(true, 'Error');
          isEnvDevelopment() && console.error({ error: res });
        }
      }
    })();
  }, [product]);
  return (
    <Styles.PortfolioCardWrapper withWrap={withWrap}>
      <CardBuilder>
        <CardImageSlot {...cardImageSlotProps} />
        <CardDeviceNameAndRatingSlot
          heading={{
            text: heading,
            level: 4,
          }}
        />
        <CardSandwichSlot {...sandwichSlotProps} />
      </CardBuilder>
    </Styles.PortfolioCardWrapper>
  );
};

export default SWPortfolioCard;
