import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { Flex } from '@chakra-ui/react';

type TContentPosition = 'left' | 'right' | 'center' | 'top';

type WrapperProps = {
  position?: string;
  textAlign?: string;
  isPropositionVideo?: boolean;
  isVerticalLayout?: boolean;
  backgroundColor?: string;
  contentPosition?: TContentPosition;
};

const getBackgroundColor = (
  backgroundColor: string | undefined,
  isVerticalLayout: boolean | undefined,
  theme: Theme,
  contentPosition: TContentPosition | undefined
) => {
  if (isVerticalLayout) {
    if (contentPosition === 'top') return '#fff';
    return '#f4f4f4';
  }
  if (backgroundColor) return backgroundColor;
  return theme.color.primary2.default;
};

export const Wrapper = styled.div<WrapperProps>`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: ${({ isPropositionVideo, isVerticalLayout }) => (isPropositionVideo || isVerticalLayout ? '' : 'grid')};
    grid-auto-rows: ${({ isPropositionVideo, isVerticalLayout }) =>
      isPropositionVideo || isVerticalLayout ? '' : '1fr'};
  }

  [class*='Blockstyle__Block'] {
    height: ${({ isVerticalLayout, isPropositionVideo }) => (isVerticalLayout || isPropositionVideo ? '' : '100%')};

    background: ${({ backgroundColor, isVerticalLayout, theme, contentPosition }) =>
      getBackgroundColor(backgroundColor, isVerticalLayout, theme, contentPosition)};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      padding: ${({ isPropositionVideo, isVerticalLayout, contentPosition }) => {
        if (isVerticalLayout) {
          if (contentPosition === 'top') return `${rem('70px')} 0`;
          return `${rem('32px')} ${rem('16px')}`;
        }
        if (isPropositionVideo) return `${rem('32px')} ${rem('16px')}`;
        return `0 ${rem('16px')} ${rem('16px')}`;
      }};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      padding: ${({ isPropositionVideo, isVerticalLayout, contentPosition }) => {
        if (isVerticalLayout) {
          if (contentPosition === 'top') return `${rem('70px')} 0`;
          return `${rem('50px')} 0`;
        }
        if (isPropositionVideo) return `${rem('50px')} 0`;
        return `0 0 ${rem('40px')}`;
      }};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      padding: ${({ isPropositionVideo, isVerticalLayout }) =>
        isPropositionVideo || isVerticalLayout ? `${rem('70px')} 0` : `0 0 ${rem('40px')}`};
    }

    > [class*='Blockstyle__ContentWrapper'] {
      display: ${({ contentPosition, isVerticalLayout }) =>
        contentPosition === 'top' && isVerticalLayout ? 'flex' : 'inherit'};
      justify-content: ${({ contentPosition, isVerticalLayout }) =>
        contentPosition === 'top' && isVerticalLayout ? 'center' : 'none'};
      box-shadow: ${({ isPropositionVideo, isVerticalLayout }) =>
        isPropositionVideo || isVerticalLayout ? 'none' : `0 ${rem('2px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.16)`};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        max-width: ${({ isPropositionVideo, isVerticalLayout }) =>
          isPropositionVideo || isVerticalLayout ? `${rem('1180px')}` : `${rem('980px')}`};
        min-height: ${({ isPropositionVideo, isVerticalLayout }) =>
          isPropositionVideo || isVerticalLayout ? '' : `${rem('302px')}`};
        height: 100%;
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        height: ${({ isPropositionVideo, isVerticalLayout }) =>
          isPropositionVideo || isVerticalLayout ? '' : `${rem('270px')}`};
      }

      > [class*='FunctionalContentBlockstyle__FunctionalContentBlock'] {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
          display: flex;
          flex-direction: ${({ isPropositionVideo }) => (isPropositionVideo ? 'column-reverse' : 'column')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          height: 100%;
          display: flex;
          align-items: ${({ isVerticalLayout }) => (isVerticalLayout ? 'center' : 'normal')};
          margin: 0 auto;
        }

        > [class*='ContentVideostyle__VideoWrapper'] {
          margin: 0;

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
            width: ${({ isVerticalLayout, isPropositionVideo }) => {
              if (isVerticalLayout) {
                return `${rem('688px')}`;
              }
              if (isPropositionVideo) {
                return '50%';
              } else {
                return '49%';
              }
            }};
            padding: ${({ isPropositionVideo }) => (isPropositionVideo ? `0 ${rem('16px')} 0 0` : '0')};
            align-self: auto;
          }

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
            max-width: none;
            width: ${({ isVerticalLayout, isPropositionVideo }) => {
              if (isVerticalLayout) {
                return `${rem('780px')}`;
              }
              if (isPropositionVideo) {
                return '50%';
              } else {
                return '49%';
              }
            }};
            padding: 0;
          }

          > [class*='YoutubeVideostyle__YoutubeVideoWrapper'] {
            height: ${({ isPropositionVideo }) => (isPropositionVideo ? `${rem('326px')}` : '100%')};
            padding-bottom: ${({ isPropositionVideo }) => (isPropositionVideo ? '0' : '')};
          }
        }

        > [class*='FunctionalContentBlockstyle__ContentWrapper'] {
          background: ${({ isVerticalLayout, theme, contentPosition }) =>
            getBackgroundColor(undefined, isVerticalLayout, theme, contentPosition)};
          justify-content: flex-start;
          width: 100%;
          padding: 0;

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
            width: ${({ isVerticalLayout, isPropositionVideo }) => {
              if (isVerticalLayout) {
                return `${rem('688px')}`;
              }
              if (isPropositionVideo) {
                return '50%';
              } else {
                return '51%';
              }
            }};
            padding: ${({ isPropositionVideo, isVerticalLayout, contentPosition }) => {
              if (isVerticalLayout) {
                if (contentPosition === 'top') return '0';
                return `${rem('24px')} 0 0`;
              }
              if (isPropositionVideo) {
                return '0';
              } else {
                return `${rem('16px')} ${rem('4px')} ${rem('32px')}`;
              }
            }};
          }
          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
            width: ${({ isVerticalLayout, isPropositionVideo }) => {
              if (isVerticalLayout) {
                return `${rem('780px')}`;
              }
              if (isPropositionVideo) {
                return '50%';
              } else {
                return '51%';
              }
            }};
          }

          h1[size='3'] {
            color: var(--mineShaft);
            font-size: ${rem('28px')};
            line-height: ${rem('33px')};
            padding: ${rem('24px')} ${rem('20px')} 0;

            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
              margin-bottom: ${rem('-10px')};
              text-align: center;
            }

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              text-align: ${({ textAlign }) => textAlign || 'left'};
              margin-bottom: ${rem('-21px')};
              padding: ${rem('4px')} ${rem('20px')} 0;
            }
          }

          > [class*='ContentRendererstyle__ContentText'] {
            color: var(--mineShaft);
            font-size: ${rem('18px')};
            line-height: ${({ isVerticalLayout, isPropositionVideo }) => {
              if (isVerticalLayout) {
                return `${rem('20px')}`;
              }
              if (isPropositionVideo) {
                return `${rem('26px')}`;
              } else {
                return `${rem('24px')}`;
              }
            }};
            padding: ${({ isPropositionVideo, isVerticalLayout, contentPosition }) => {
              if (isVerticalLayout) {
                if (contentPosition === 'top') return '0';
                return `${rem('16px')} 0 0`;
              }
              if (isPropositionVideo) {
                return `0 0 ${rem('16px')}`;
              } else {
                return `0 ${rem('20px')} ${rem('24px')}`;
              }
            }};

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              padding: ${({ isPropositionVideo, isVerticalLayout }) => {
                if (isVerticalLayout) {
                  return '0';
                }
                if (isPropositionVideo) {
                  return `0 ${rem('16px')}`;
                } else {
                  return `0 ${rem('20px')}`;
                }
              }};
            }
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              h2 {
                font-size: ${rem('24px')};
                line-height: ${rem('32px')};
                margin-bottom: ${rem('20px')};
              }
            }
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
              padding: ${({ isPropositionVideo, isVerticalLayout }) => {
                if (isVerticalLayout) {
                  return '0';
                }
                if (isPropositionVideo) {
                  return `0 ${rem('20px')} 0 0`;
                } else {
                  return `0 ${rem('20px')}`;
                }
              }};
              line-height: ${({ isPropositionVideo, isVerticalLayout }) =>
                isVerticalLayout || isPropositionVideo ? `${rem('26px')}` : `${rem('24px')}`};
              h2 {
                font-size: ${rem('40px')};
                line-height: ${rem('48px')};
              }
            }

            p {
              letter-spacing: ${rem('-0.18px')};
              b {
                font-size: ${rem('25px')};
              }
              &:first-of-type {
                margin: ${rem('2px')} 0 0;
                @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const WrapItem = styled.div<WrapperProps>`
  [class*='Blockstyle__Block'] {
    background-color: #f4f4f4;
    > [class*='Blockstyle__ContentWrapper'] {
      background-color: #f4f4f4;
      > [class*='FunctionalContentBlockstyle__FunctionalContentBlock'] {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          flex-direction: ${({ position, isVerticalLayout }) => {
            switch (position) {
              case 'left':
                if (isVerticalLayout) {
                  return 'column';
                } else {
                  return 'row';
                }
              case 'right':
                if (isVerticalLayout) {
                  return 'column';
                } else {
                  return 'row-reverse';
                }
              default:
                return 'row';
            }
          }};
        }
      }
      [class*='FunctionalContentBlockstyle__ContentWrapper'] {
        background-color: #f4f4f4 !important;
      }
    }
  }
`;

export const WrapText = styled(Flex)`
  background-color: var(--white);
  padding-top: ${rem('21px')};
  padding-bottom: ${rem('21px')};
  align-items: ${({ contentPosition }) => contentPosition || 'center'};
  justify-content: ${({ contentPosition }) => contentPosition || 'center'};
  h3 {
    text-align: center;
  }
  p {
    max-width: ${rem('780px')};
    width: 100%;
    text-align: center;
    font-size: ${rem('18px')};
    font-family: VodafoneLight;
    font-weight: 400;
  }
`;
