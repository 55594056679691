import { ModalCloseButton } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const FormModalCloseButton = styled(ModalCloseButton)``;

export const SWFormModalHeader = styled.div`
  position: relative;
  padding: ${rem('8px')};
  border-top-left-radius: ${rem('6px')};
  border-top-right-radius: ${rem('6px')};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(#e60000, #820000);
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: ${rem('16px')};
  }
  h3[class*='Headingstyle__Heading-sc'] {
    color: #ffffff;
    font-weight: 700;
    margin: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-family: 'VodafoneLight';
      font-size: ${rem('56px')};
      line-height: ${rem('56px')};
      font-weight: 400;
    }
  }
`;

export const SWFormModalHeaderIcon = styled.div`
  position: absolute;
  top: -${rem('16px')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem('48px')};
  height: ${rem('48px')};
  border-radius: 100%;
  border: 1px solid #ffffff;
  background-image: linear-gradient(to top right, #820000, #e60000);
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`;

export const SWFormModalCloseBtn = styled.div`
  [class*='chakra-modal__close-btn'] {
    top: 50%;
    right: ${rem('8px')};
    transform: translateY(-50%);
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      right: ${rem('16px')};
    }
  }
`;

export const SWFormModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem('16px')};

  @media (max-height: 740px) {
    overflow: scroll;
  }

  h4[class*='Headingstyle__Heading-sc'] {
    font-family: 'VodafoneLight';
    font-size: ${rem('18px')};
    line-height: ${rem('26px')};
    margin-bottom: ${rem('12px')};
  }

  [class*='Buttonstyle__Button-sc'] {
    margin-top: ${rem('20px')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    align-items: center;
    padding: ${rem('30px')};

    h4[class*='Headingstyle__Heading-sc'] {
      font-size: ${rem('28px')};
      line-height: ${rem('34px')};
      margin-bottom: ${rem('30px')};
    }

    [class*='Buttonstyle__Button-sc'] {
      min-width: ${rem('220px')};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    height: fit-content;
    overflow: scroll;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-items: center;
  display: flex;
`;
