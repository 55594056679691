const stringifyContent = (obj: any): string => {
  const content: string[] = Object.keys(obj)
    .map(key => {
      if (obj[key][0] !== undefined && typeof obj[key][0] !== 'string') {
        return obj[key][0].content.map((item: any) => item.value).join('');
      }
      return '';
    })
    .filter(item => typeof item === 'string');

  return content.join('');
};

export default stringifyContent;
