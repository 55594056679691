import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const ExpandingCardHeader = styled(Stack)``;

export const ExpandingCardTitleWrapper = styled(Box)`
  && {
    min-height: ${rem('90px')};
  }
`;

export const ExpandingCardTitle = styled(Heading)`
  && {
    font-weight: bold;
    font-size: ${rem('24px')};
    line-height: ${rem('30px')};
    margin-bottom: 0;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('28px')};
      line-height: ${rem('36px')};
    }
  }
`;

export const FCWrapper = styled.div<{ isExpanded?: boolean }>`
  width: 100%;
  display: flex;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  flex-direction: column;
  align-items: center;
  ${({ isExpanded }) => {
    if (isExpanded) {
      return 'height: auto';
    }
  }};
  [class*='Interactionstyle'] {
    width: 90%;
    border-radius: ${rem('6px')};

    &[id='FlipButton'] {
      position: absolute;
      bottom: ${rem('10px')};
    }
    &[id='VisitProductButton'] {
      position: absolute;
      bottom: ${rem('80px')};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
`;

export const ExpandableWrapper = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  flex-direction: column;
  background-color: #f2f2f2;
  color: var(--black);
  display: flex;
  align-items: center;
  padding: ${rem('20px')} 0;
  ${({ isExpanded }) => (isExpanded ? 'height: auto' : `height: ${rem('210px')}`)};

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    height: inherit;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
  [class*='Interactionstyle__Button-sc'] {
    &[id='ExpandButton'] {
      color: var(--black);
      border-color: var(--black);
      background: none;
    }
  }
`;

export const ExpandableDescription = styled(Text)`
  && {
    color: black;
    line-height: ${rem('18px')};
  }
`;

export const SelectWrapper = styled.div`
  margin: 0 ${rem('20px')} ${rem('20px')};
`;
