import { FC, useState, useEffect, useMemo } from 'react';

import axios from 'axios';
import { startCase } from 'lodash';
import { useLocation } from '@reach/router';

import { Icon } from '@vfuk/core-icon';
import { Heading } from '@source-web/heading';
import { Paragraph } from '@vfuk/core-paragraph';

import { TError } from '@/services/error.types';
import { retrieveProduct } from '@/services/product.services';
import { useModalContext } from '@/hooks/modal/useModal';
import { costOnePrice } from '@/helpers/findPrices';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { ProductBundleDetailAndPricing } from '@/types/Product.types';

import ProductCta from './ProductCta';
import CardGrid from './ProductGrid';

import { SWTariffCardProps, TariffCardProduct } from './SWTariffCard.types';

import { CardContainer, IconDescriptionWrapper, Label, RowWrapper } from './SWTariffCard.styles';
import { DetailsAndPricingProps } from '../SWPricingCard/CardElements/DetailsAndPricing/DetailsAndPricing.types';

const SWTariffCard: FC<SWTariffCardProps> = ({
  cardLabel,
  servicesTitle,
  productsTitle,
  product,
  productsIncluded,
  servicesIncluded,
  extraInfos,
  buttonsAction,
  nextStep,
  hasService,
  __typename,
  isAddon,
}) => {
  const { pathname } = useLocation();
  const { setErrorInfo, toggleModal } = useModalContext();

  const shouldMergeProducts = pathname.includes('business-boost');

  const [productBundle, setProductBundle] = useState<TariffCardProduct | undefined>();
  const [selectedEdition, setSelectedEdition] = useState<DetailsAndPricingProps['selectedEdition']>();

  const detailAndPricing = product?.detailAndPricing as ProductBundleDetailAndPricing;

  const checkedItem = useMemo(() => {
    if (product.dxlInformation?.periods && product.dxlInformation?.periods.length > 0 && selectedEdition) {
      return product.dxlInformation?.periods.find(period => period.uuid === selectedEdition);
    } else if (product.dxlInformation?.periods && product.dxlInformation?.periods.length > 0 && !selectedEdition) {
      setSelectedEdition(product.dxlInformation?.periods[0].uuid);
      return product.dxlInformation?.periods[0];
    } else {
      return undefined;
    }
  }, [product?.dxlInformation, selectedEdition]);

  const thirdEntry = useMemo(() => {
    if (checkedItem && !detailAndPricing?.useCustomThirdEntryValue) {
      const text = detailAndPricing?.thirdEntry?.split(' ').slice(1).join(' ');
      return `${startCase(checkedItem.type.toLowerCase())} ${text}`;
    } else {
      return detailAndPricing?.thirdEntry;
    }
  }, [product, checkedItem]);

  const thirdEntryPrice = useMemo(() => {
    if (detailAndPricing?.useCustomThirdEntryValue) return detailAndPricing?.thirdEntryValue;
    if (checkedItem) {
      return costOnePrice(checkedItem.prices);
    } else if (shouldMergeProducts) {
      return product.dxlInformation?.price[0].price;
    } else {
      return costOnePrice(product.dxlInformation?.price);
    }
  }, [shouldMergeProducts, product, checkedItem]);

  const fetchProductBundle = async () => {
    try {
      product.dxlInformation = undefined;
      const retrievedProdutc = await retrieveProduct(product.productId);
      if (
        !axios.isAxiosError(retrievedProdutc) &&
        retrievedProdutc.status === 200 &&
        retrievedProdutc.data &&
        retrievedProdutc.data.id
      ) {
        product.dxlInformation = retrievedProdutc.data.dxlInformation;
        setProductBundle(product);
      } else {
        setErrorInfo({
          ...(axios.isAxiosError(retrievedProdutc) && {
            statusType: retrievedProdutc.response?.data?.error,
            statusCode: retrievedProdutc.response?.data?.code || retrievedProdutc.response?.status,
          }),
          requestUrl: 'catalogues',
        });
        toggleModal(true, 'Error');
      }
    } catch (err) {
      const error = err as TError;
      isEnvDevelopment() && console.log(error?.response?.data.error);
    }
  };

  useEffect(() => {
    fetchProductBundle();
  }, []);

  return (
    <CardContainer hasService={hasService} isAddon={isAddon} hasLabel={!!cardLabel}>
      {cardLabel && <Label>{cardLabel}</Label>}
      <Heading text={productBundle?.dxlInformation?.name} justify="center" size={2} weight={3} />

      <Heading justify="center" size={3} weight={3}>
        <span>{detailAndPricing?.currencySymbol}</span>
        <span>{thirdEntryPrice}</span>
      </Heading>

      <Paragraph justify="center" size={1}>
        {thirdEntry}
      </Paragraph>

      <ProductCta product={product} buttonsAction={buttonsAction} nextStep={nextStep} />

      <IconDescriptionWrapper isAddon={isAddon}>
        {extraInfos?.map(({ iconName, itemDescription }) => (
          <RowWrapper key={itemDescription}>
            <Icon name={iconName} size={1} />
            <Paragraph size={1}>{itemDescription}</Paragraph>
          </RowWrapper>
        ))}
      </IconDescriptionWrapper>
      <div>
        {productsIncluded && <CardGrid title={productsTitle ?? ''} items={productsIncluded} />}
        {servicesIncluded && <CardGrid title={servicesTitle ?? ''} items={servicesIncluded} />}
      </div>
    </CardContainer>
  );
};

export default SWTariffCard;
