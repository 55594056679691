import styled from '@emotion/styled';
import { rem } from 'polished';

type ContainerProps = {
  position?: string;
};

export const PortfolioWrapper = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.color.primary2.default};
  overflow: hidden;
  padding: 0 ${rem('16px')};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.lg - 1}px) {
    padding: ${({ position }) => (position === 'left' ? `0 0 0 ${rem('16px')}` : `0 ${rem('16px')} 0 0`)};
  }

  hr {
    max-width: ${rem('1180px')};
    margin: 0 auto;
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto ${rem('12px')};
  max-width: ${rem('1180px')};
  padding: ${rem('32px')} 0 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin: 0 auto;
    flex-direction: ${({ position }) => (position === 'left' ? 'row' : `row-reverse`)};
    padding: ${rem('50px')} 0;
    gap: ${({ position }) => (position === 'left' ? `0` : `${rem('16px')}`)};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg + 1}px) {
    gap: 0;
    padding: ${rem('70px')} 0;
  }

  > div {
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      width: 54%;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg + 1}px) {
      width: 41%;
    }
  }
`;

export const WrapButton = styled.div`
  display: none;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block;
    margin-top: ${rem('32px')};
    width: ${rem('280px')};
    height: ${rem('50px')};
  }

  &.mobile {
    display: block;
    margin-top: ${rem('20px')};
    height: ${rem('50px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      display: none;
    }
  }

  a {
    width: 100%;
    height: 100%;
    font-size: ${rem('20px')};
    line-height: ${rem('22px')};
  }
`;
