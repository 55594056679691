import { IconStepperProps } from '@vfuk/core-icon-stepper';

import { Tab, Tabs } from '@vfuk/core-tabs';
import axios from 'axios';
import { rem } from 'polished';
import { FC, useEffect, useState } from 'react';

import PricingCardCarousel from '@/components/pageBuilder/Sections/PricingCardCarousel';
import { TPricingCardCarousel } from '@/components/pageBuilder/Sections/PricingCardCarousel/PricingCardCarousel.types';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { TError } from '@/services/error.types';
import { retrieveProduct } from '@/services/product.services';

import { TabsWrapper, Wrapper } from './SWConfigureBundle.styles';
import { SWConfigureBundleProps } from './SWConfigureBundle.types';
import ConfigureSteps from './ConfigureSteps';
import SWSectionTariffCards from '../SWSectionTariffCards';
import { useAppDispatch, useAppSelector, setMaxSteps, setCurrentStep, resetStep } from '@/store';

const SWConfigureBundle: FC<SWConfigureBundleProps> = ({
  id,
  contentful_id,
  backgroundColor,
  title,
  bundleContent,
  __typename,
}) => {
  const dispatch = useAppDispatch();
  const { currentStep } = useAppSelector(({ userConfigureBundle }) => userConfigureBundle);
  const { setErrorInfo, toggleModal } = useModalContext();

  const [productsWithDxlInfo, setProductsWithDxlInfo] = useState<TPricingCardCarousel['products']>([]);
  const [itemStepName, setItemStepName] = useState<TPricingCardCarousel['itemStepName']>();
  const [steps, setSteps] = useState<IconStepperProps['steps']>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const { disableSteps, items, isUsingTariffCards } = bundleContent;
  const hasItems = items && items.length > 0;
  const content = items.map(item => item.content);

  const handleChangeTab = (index: number) => {
    const { id, contentful_id, name } = items[index];
    setActiveTab(`${id}-${contentful_id}`);
    setItemStepName(name); // temporary solution for displaying notification message
  };

  const handleChangeStep = (index: number) => {
    if (hasItems) {
      dispatch(setCurrentStep(index + 1));
      handleChangeTab(index);
    }
  };

  useEffect(() => {
    dispatch(resetStep());
    try {
      const productsIds = content?.flatMap(item => item.map(i => i.productId));
      const productsWithEditions = content?.flatMap(product =>
        product.map(async item => {
          if (productsIds?.includes(item.productId)) {
            const retrievedProduct = await retrieveProduct(item.productId);
            if (
              !axios.isAxiosError(retrievedProduct) &&
              retrievedProduct.status === 200 &&
              retrievedProduct.data &&
              retrievedProduct.data.id
            ) {
              item.dxlInformation = retrievedProduct.data.dxlInformation;
            } else {
              setErrorInfo({
                ...(axios.isAxiosError(retrievedProduct) && {
                  statusType: retrievedProduct.response?.data?.error,
                  statusCode: retrievedProduct.response?.data?.code || retrievedProduct.response?.status,
                }),
                requestUrl: 'catalogues',
              });
              toggleModal(true, 'Error');
            }

            if (item.productsFreeOfCharge && item.productsFreeOfCharge.length > 0) {
              const productsFreeOfCharge = item.productsFreeOfCharge.map(async i => {
                // NOSONAR
                const retrievedSubProduct = await retrieveProduct(i.productId);

                if (
                  !axios.isAxiosError(retrievedSubProduct) &&
                  retrievedSubProduct.data &&
                  retrievedSubProduct.data.id &&
                  item.productsFreeOfCharge
                ) {
                  i.dxlInformation = retrievedSubProduct.data.dxlInformation;
                } else {
                  setErrorInfo({
                    ...(axios.isAxiosError(retrievedSubProduct) && {
                      statusType: retrievedSubProduct.response?.data?.error,
                      statusCode: retrievedSubProduct.response?.data?.code || retrievedSubProduct.response?.status,
                    }),
                    requestUrl: 'catalogues',
                  });
                  toggleModal(true, 'Error');
                }
              });

              return {
                ...item,
                productsFreeOfCharge,
              };
            }

            return item;
          }
        })
      ) as TPricingCardCarousel['products'];

      setProductsWithDxlInfo(productsWithEditions);
    } catch (err) {
      const error = err as TError;
      isEnvDevelopment() && console.log(error?.response?.data.error);
    }
  }, []);

  useEffect(() => {
    if (hasItems) {
      dispatch(setMaxSteps(items.length));
      handleChangeTab(0);
    }
  }, [items]);

  useEffect(() => {
    if (hasItems) {
      setSteps(
        items.map(({ name, iconName }, index) => ({
          onClick: () => handleChangeStep(index),
          title: iconName === 'bundles' ? 'Bundle' : name,
          icon: { name: iconName },
          ...(index + 1 < currentStep && { state: 'success' }),
        })) as IconStepperProps['steps']
      );
      handleChangeTab(currentStep - 1);
    }
  }, [currentStep]);

  return (
    <Wrapper
      as="section"
      id={`${id}-${contentful_id}`}
      backgroundColor={backgroundColor}
      py={{ base: rem('30px'), md: 13 }}
    >
      <ConfigureSteps title={title} steps={steps} currentStep={currentStep} disableSteps={disableSteps} />

      <TabsWrapper
        as="section"
        pt={disableSteps ? { base: 8, md: `${rem('90px')}` } : { base: `${rem('40px')}`, md: `${rem('50px')}` }}
        step-number={currentStep}
        step-name={steps[currentStep]?.title || ''}
      >
        {productsWithDxlInfo && !!itemStepName && items && (
          <Tabs appearance="secondary" externallySetTabId={activeTab}>

            {items.map(
              ({ id, contentful_id, name, content, tariffContent, buttonsAction, buttonsAppearance, iconName }) => (
                <Tab key={`${id}-${contentful_id}`} id={`${id}-${contentful_id}`}>
                  {isUsingTariffCards ? (
                    <SWSectionTariffCards
                      __typename="ContentfulSectionSwTariffCards"
                      id={id}
                      name={name as string}
                      cards={tariffContent ?? []}
                      buttonsAction={buttonsAction}
                      isAddon={iconName === 'add-ons'}
                    />
                  ) : (
                    <PricingCardCarousel
                      typename={__typename}
                      id={id}
                      contentful_id={contentful_id}
                      mainItem={items[0].content[0]}
                      name={name}
                      buttonsAction={buttonsAction}
                      buttonsAppearance={buttonsAppearance}
                      products={content}
                      itemStepName={itemStepName}
                    />
                  )}
                </Tab>
              )
            )}

          </Tabs>
        )}
      </TabsWrapper>
    </Wrapper>
  );
};

export default SWConfigureBundle;
