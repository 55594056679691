import { getImage, getSrc } from 'gatsby-plugin-image';

import Icon from '@vfuk/core-icon';
import Image from '@vfuk/core-image';
import Tooltip from '@vfuk/core-tooltip';

import { BundleAddonProps } from '../BundleIncludes.types';
import { LabelWrapper, ThumbnailWrapper } from './BundleAddon.styles';

const BundleAddon = ({ item, toggle }: BundleAddonProps) => {
  const { id, name, slug, includeDescription, shortDescription, thumbnailIcon } = item;
  const thumbnailIconSrc = getSrc(thumbnailIcon) ?? getImage(thumbnailIcon)?.placeholder?.fallback;

  return (
    <>
      {thumbnailIconSrc && (
        <ThumbnailWrapper>
          <Image
            alt={thumbnailIcon?.title ?? ''}
            sm={{
              src: thumbnailIconSrc,
              width: '34px',
              height: '34px',
            }}
          />
        </ThumbnailWrapper>
      )}

      {toggle ? <LabelWrapper>{includeDescription || name}</LabelWrapper> : <>{includeDescription || name}</>}

      {shortDescription?.shortDescription && (
        <Tooltip
          id={`trigger-${id}-${slug}`}
          appearance="secondary"
          size={1}
          text={shortDescription?.shortDescription || ''}
          srText={shortDescription?.shortDescription || ''}
        >
          <Icon name="info-circle" group="system" size={1} />
        </Tooltip>
      )}
    </>
  );
};

export default BundleAddon;
