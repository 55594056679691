import { Box, Flex, Skeleton, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { TRibbonWrapper, WrapperProps, WrapToggleProps, TRibbon } from './SWPricingCard.types';

export const CardSkeleton = styled(Skeleton)`
  && {
    height: ${rem('552px')};
  }
`;

export const PricingCardWrapper = styled(Stack)`
  && {
    max-width: ${rem('345px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      max-width: ${rem('382px')};
    }
  }
`;

export const CustomiseBundleText = styled(Box)``;

export const PricingCardInner = styled.div<WrapperProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: ${rem('2px')};
  max-width: ${rem('345px')};
  width: 100%;
  height: 100%;
  position: relative;
  [class*='Paragraphstyle__Paragraph-sc'] {
    text-align: left;
    padding: 0 ${rem('20px')};
    line-height: ${rem('18px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: ${rem('382px')};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    width: 70vw;
    min-width: ${rem('270px')};
    align-self: center;
  }

  [class*='ContainerWithLabelstyle__ContainerWithLabel-sc'] {
    height: 100%;
  }

  [class*='Labelstyle__LabelContainer-sc'] {
    margin-right: 0;
  }

  [class*='Labelstyle__LabelContainer-sc'] {
    margin-right: 0;
  }

  [class*='Labelstyle__LabelContainer-sc'] {
    margin-right: 0;
  }

  [class*='Labelstyle__LabelContainer-sc'] {
    margin-right: 0;
  }

  [class*='ContainerWithLabelstyle__Header'] {
    height: ${rem('34px')};

    [class*='ContainerWithLabelstyle__LabelContainer'] {
      padding: 0 ${rem('20px')};

      > p {
        margin-bottom: ${rem('-14px')};

        b {
          font-size: ${rem('16px')};
          font-weight: 400;
          letter-spacing: ${rem('0.7px')};
        }
      }
    }
  }

  [class*='Interactionstyle__BlankInteraction'][class*='Containerstyle__Container'] {
    box-shadow: 0 ${rem('2px')} ${rem('10px')} 0 rgba(0, 0, 0, 0.16);
    height: 100%;
    padding: 0;
    margin-bottom: ${rem('10px')};
    background-color: ${props => props.isMiddleOne && '#00697C'};

    &::before {
      border-color: ${({ theme, containerAppearance }) => {
        if (containerAppearance && containerAppearance !== 'none') {
          return theme.color[`${containerAppearance}1`].default;
        } else {
          return 'transparent';
        }
      }};
      border-style: solid;
      border-width: ${rem('1px')};
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 1;
    }

    &::after {
      display: none;
    }

    [class*='Containerstyle__ContainerContent'] {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: ${rem('410px')};

      width: 100%;
      z-index: auto;

      [class*='Iconstyle__ErrorPinkBox'] {
        display: none;
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        flex-direction: column;
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        max-width: ${rem('540px')};
      }
    }
  }
`;

export const CardHeading = styled(Box)`
  && {
    padding-bottom: ${rem('14px')};
    padding-top: ${rem('27px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      padding-top: ${rem('21px')};
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem('30px')};
`;

export const ImageWrap = styled(Flex)`
  &,
  picture {
    width: ${rem('64px')};
    height: ${rem('64px')};
  }
`;

export const WrapToggle = styled.div<WrapToggleProps>`
  display: flex;
  flex-direction: column;
  padding: ${rem('5px')} ${rem('23px')} ${rem('22px')};

  [class*='Togglestyle__ToggleWrapper'] {
    padding: 0;

    label::before,
    input {
      width: ${rem('27px')};
      height: ${rem('20px')};
      top: 0;
    }

    label {
      padding-left: ${rem('38px')};

      span {
        color: ${({ theme, toggleStatus }) => {
          return toggleStatus ? 'var(--vodafone-colors-mineShaft)' : theme.color.disabled.default;
        }};
        line-height: ${rem('20px')};
        opacity: ${({ toggleStatus }) => (toggleStatus ? 1 : 0.7)};

        &,
        small {
          display: block;
        }

        small {
          font-size: ${rem('12px')};
          line-height: ${rem('13px')};
        }
      }

      [class*='Togglestyle__ToggleSlider'] {
        width: ${rem('18px')};
        height: ${rem('18px')};
        top: ${rem('1px')};
        transform: translateX(${({ toggleStatus }) => (toggleStatus ? `${rem('9px')}` : 0)});
      }

      svg {
        * {
          stroke: var(--white);
        }
      }
    }
  }
`;

export const FCWrapper = styled.div<{ isFlipped?: boolean }>`
  width: 100%;
  display: flex;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  flex-direction: column;
  align-items: center;
  ${({ isFlipped }) => {
    if (isFlipped) {
      return 'transform: rotateY(90deg);';
    }
  }};
  [class*='Interactionstyle'] {
    width: 90%;
    border-radius: ${rem('6px')};

    &[id='FlipButton'] {
      position: absolute;
      bottom: ${rem('10px')};
    }
    &[id='VisitProductButton'] {
      position: absolute;
      bottom: ${rem('80px')};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
`;

export const CTAWrapper = styled.div`
  margin-top: ${rem('-20px')};

  [class*='Interactionstyle__Button-sc'] {
    border-radius: ${rem('6px')};
    &[id='basketButtonAction'] {
      background-color: #0096ad;
      border-color: #0096ad;
    }
  }
  [class*='TextInputstyle__TextInput-sc'] {
    border-radius: ${rem('6px')};
  }

  div > div {
    border-top: 0;
  }
`;

export const RibbonWrapper = styled.div<TRibbonWrapper>`
  --f: 0; /* control the folded part */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.8;
  width: ${rem('184px')};
  height: ${rem('55px')};
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51%/var(--f);
  clip-path: polygon(
          100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px));
  transform: translate(calc((1 - cos(45deg))*100%), -100%) rotate(45deg);
  transform-origin: 0% 100%;
  background-color: ${({ promotionBgColor }) => promotionBgColor || `#f8f8f8`}; /* the main color  */
`;

export const CardContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Ribbon = styled.div<TRibbon>`
  width: 50%;
  text-align: center;
  line-height: normal;
  font-weight: 700;
  color: ${({ promotionTextColor }) => promotionTextColor};
`;