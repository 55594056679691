const breakpoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  '1lg': '64em', // 1024px
  xl: '80em', // 1280px
  '1xl': '90em', // 1440px
  '2xl': '96em', // 1536px
};

export default breakpoints;
