import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: ${rem('80px')} 0;
`;

export const FormContainer = styled.div`
  background-color: var(--white);
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: ${rem('800px')};
  margin: ${rem('30px')} 0;
  height: fit-content;
  justify-content: space-between;
  justify-items: center;
  display: flex;
`;

export const InputContainer = styled.div<{ fullWidth: boolean | undefined }>`
  width: ${props => (props.fullWidth ? 100 : 50)}%;
  margin: ${rem('10px')} 0;
  padding: 0 ${rem('10px')};
  [class*='Checkboxstyle__CheckboxLabel-sc'] {
    font-size: ${rem('14px')};
  }
`;

export const VerticalSpacing = styled.div<{ size: number }>`
  margin-bottom: ${props => props.size}px;
`;
