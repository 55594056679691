import styled from '@emotion/styled';
import { rem } from 'polished';

export const CardsContainer = styled.div`
  display: flex;
  gap: ${rem('64px')};
  flex-wrap: wrap;
  margin: 0 auto;
  padding: ${rem('64px')} 0;
  justify-content: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: ${rem('64px')} 0;
    gap: ${rem('16px')};
    justify-content: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
