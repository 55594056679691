import { ListItem } from '@chakra-ui/react';
import { rem } from 'polished';
import styled from '@emotion/styled';

export const BundleIncludesItem = styled(ListItem)`
  [class*='Tooltipstyle__TooltipTrigger'] {
    margin-left: ${rem('9px')};
    margin-top: ${rem('-2px')};
  }

  [class*='Tooltipstyle__TooltipWrapper'] {
    max-width: none;
  }

  [class*='Tooltipstyle__TooltipBody'],
  [class*='Tooltipstyle__TooltipArrow']::before {
    background-color: var(--vodafone-colors-mineShaft);
  }

  [class*='Tooltipstyle__TooltipBody'] {
    border-radius: ${rem('6px')};
    box-shadow: 0 ${rem('4px')} ${rem('10px')} 0 rgba(0, 0, 0, 0.32);
    font-size: ${rem('18px')};
    line-height: ${rem('24px')};
    max-width: ${rem('341px')};
    padding: ${rem('20px')};
    white-space: pre-wrap;
    z-index: 2;
  }
  [class*='Togglestyle__LabelText'] {
    display: flex;
    align-items: center;
  }
`;

export const AddonWrapToggle = styled.div<{ toggleStatus?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${rem('5px')} ${rem('23px')} ${rem('22px')};
  [class*='Togglestyle__ToggleWrapper'] {
    padding: 0;
    label::before,
    input {
      width: ${rem('27px')};
      height: ${rem('20px')};
      margin-top: ${rem('1px')};
    }
    [class*='Togglestyle__ToggleSlider'] {
      height: ${rem('18px')};
      width: ${rem('18px')};
      transform: translateX(${({ toggleStatus }) => (toggleStatus ? `${rem('9px')}` : 0)});
    }

    label {
      padding-left: ${rem('38px')};
      [class*='Togglestyle__IconWrapper'] {
        svg {
          * {
            stroke: var(--white);
          }
        }
      }
    }
  }
`;
