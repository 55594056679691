import { getSrc } from 'gatsby-plugin-image';
import { FC, useState } from 'react';

import { UploadBannerWrapper, UploadSession, ButtonWrapper } from './SWSectionHeroBannerUpload.styles';
import { SWSectionHeroBannerUploadProps } from './SWSectionHeroBannerUpload.types';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Paragraph from '@vfuk/core-paragraph';
import ButtonWithIcon from '@vfuk/core-button-with-icon';
import Image from '@vfuk/core-image';
import { Heading } from '@vfuk/core-heading';
import handleUploadFile from '@/services/upload.services';


const SWSectionHeroBannerUpload: FC<SWSectionHeroBannerUploadProps> = (data: SWSectionHeroBannerUploadProps) => {
  const [file, setFile] = useState<File | undefined>(undefined)

  const {
    upSessionTitle,
    upSessionText,
    mainBackgroundImage,
    mobileBackgroundImage,
    tabletBackgroundImage,
  } = data;

  const desktopBackgroundImageSrc = getSrc(mainBackgroundImage);
  const mobileBackgroundImageSrc = getSrc(mobileBackgroundImage);
  const tabletBackgroundImageSrc = getSrc(tabletBackgroundImage);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement & {
      files: FileList;
    }

    setFile(target.files[0])
  }

  const handleOnClick = () : void => {
    handleUploadFile(file)
  }

  return (
    <UploadBannerWrapper>
      <Image alt="Vodafone banner" md={{ src: tabletBackgroundImageSrc || " " }} sm={{ src: mobileBackgroundImageSrc || "" }} xl={{ src: desktopBackgroundImageSrc || "" }} />
      <UploadSession>
        <Heading level={3} weight={2}>{upSessionTitle}</Heading>
        <Paragraph>{documentToReactComponents(JSON.parse(upSessionText.raw))}</Paragraph>

        <input accept='.csv' type='file' name='csv' onChange={handleOnChange} />
        <ButtonWrapper>
          <ButtonWithIcon width='auto' text='Upload' appearance='primary' icon={{ name: 'upload', justify: 'right' }} onClick={handleOnClick} />
        </ButtonWrapper>

      </UploadSession>
    </UploadBannerWrapper>
  );
};

export default SWSectionHeroBannerUpload;
