import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem('30px')} 0;
  background-color: var(--grey);
`;

export const TopContainer = styled.div<{ sticky?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ sticky }) => {
    if (sticky) {
      return `position: sticky; top: 100px; z-index: 3;`;
    }
  }}
`;

export const ContentContainer = styled.div`
  width: ${rem('1400px')};
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  position: relative;
`;

export const HeaderRow = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: center;
  margin: ${rem('20px')} 0;
`;

export const HPRowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 ${rem('20px')};
`;
export const ProductHeaderContainer = styled.div<{ justifyContent?: string }>`
  width: 30%;
  max-width: ${rem('255px')};
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 30%);
  border-radius: ${rem('6px')};
  padding: ${rem('15px')} ${rem('10px')};
  background-color: var(--white);
  &:not(:last-of-type) {
    margin-right: ${rem('35px')};
  }
`;

export const PHPriceDescription = styled.p`
  font-size: ${rem('14px')};
  align-self: center;
`;

export const TableCaptionContainer = styled.div<{ absolutePosition?: boolean }>`
  width: 25%;
  height: ${rem('232px')};
  display: flex;
  flex-direction: column;
  ${({ absolutePosition }) => {
    if (absolutePosition) {
      return `position: absolute; top: 30px; left: 5%; z-index: 4;`;
    }
  }}
`;

export const FeaturesRow = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: ${rem('10px')} 0;
`;

export const RowHeaderContainer = styled.div`
  display: flex;
  width: 25%;
  min-height: ${rem('85px')};
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: var(--white);
  box-shadow: ${rem('0px')} ${rem('2px')} ${rem('8px')} ${rem('0px')} #00000029;
  border-radius: ${rem('6px')};
  z-index: 4;
`;

export const RowTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
export const AllSpecsWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: ${rem('85px')};
  flex-direction: column;
  align-items: center;
  background: var(--white);
  box-shadow: ${rem('0px')} ${rem('2px')} ${rem('8px')} ${rem('0px')} #00000029;
  border-radius: ${rem('6px')};
  margin: 0 ${rem('20px')};
`;

export const RowSpecsContainer = styled.div<{ customHeight?: string }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  height: ${rem('85px')};
  ${({ customHeight }) => {
    if (customHeight) {
      return `height:${customHeight};`;
    }
  }}
`;

export const IconWrapper = styled.div<{ isGrey?: boolean; noMargin?: boolean }>`
  &:not(:last-of-type) {
    margin-right: ${rem('35px')};
  }
  width: 22%;
  max-width: ${rem('205px')};

  ${({ noMargin }) => {
    if (noMargin) {
      return 'margin: 0; width: fit-content;';
    }
  }}
  svg {
    path {
      ${({ isGrey }) => {
        if (isGrey) {
          return 'fill: #BEBEBE;';
        }
      }};
    }
  }
`;

export const RowTitle = styled.div<{ subSpec?: boolean; customHeight?: string }>`
  flex-direction: row;
  display: flex;
  align-items: center;
  text-align: left;
  width: 70%;
  padding: ${rem('10px')} 0;
  min-height: ${rem('85px')};
  font-size: ${rem('28px')};
  margin-left: ${rem('10px')};
  ${({ subSpec }) => {
    if (subSpec) {
      return 'font-family: VodafoneLight; font-size: 20px; margin-left: 20px;';
    }
  }};

  ${({ customHeight }) => {
    if (customHeight) {
      return `height:${customHeight};`;
    }
  }}
`;

export const HorizontalDivider = styled.div<{ invisible?: boolean }>`
  width: 95%;
  height: ${rem('1px')};
  background-color: ${props => (props.invisible ? '#0000' : '#0008')};
`;

export const RowIcon = styled.div`
  margin-left: ${rem('25px')};
`;

export const RowContent = styled.div``;

export const TableElementContainer = styled.div<{ positionAtBottom?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${rem('20px')};
  ${({ positionAtBottom }) => {
    if (positionAtBottom) {
      return 'margin-top: auto; margin-bottom: 30px;';
    }
  }};
  span {
    margin-right: ${rem('3px')};
  }
`;
