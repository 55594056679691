import BaseGrid from '@/components/Global/BaseGrid';
import { Flex, Heading, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const DetailsAndPricingWrapper = styled(BaseGrid)``;

export const DetailsItem = styled(Flex)`
  flex-direction: column;

  h5 {
    font-weight: normal;
    font-size: ${rem('14px')};
    line-height: ${rem('16px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      line-height: ${rem('20px')};
    }
  }

  h6 {
    letter-spacing: normal;
  }

  &:nth-of-type(1n + 3) {
    margin-block: ${rem('16px')} 0;

    h5 {
      font-size: ${rem('16px')};
      line-height: ${rem('15px')};

      &:last-of-type {
        height: auto;
      }
    }

    h6 {
      font-size: ${rem('46px')};
      letter-spacing: ${rem('2.2px')};
      line-height: ${rem('36px')};

      small {
        margin-right: ${rem('-4px')};
        margin-left: ${rem('-2px')};
      }
    }
    small {
      display: contents;
    }
  }
`;

export const DetailsInfoItem = styled(Text)`
  && {
    font-size: ${rem('22px')};
    font-weight: bold;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    line-height: ${rem('24px')};
    small {
      font-size: var(--vodafone-fontSizes-7);
    }
  }
`;

export const DetailsTitleItem = styled(Heading)`
  height: ${rem('32px')};
  font-size: ${rem('14px')};
  letter-spacing: ${rem('-0.5px')};
  line-height: ${rem('20px')};
  margin-bottom: ${rem('-4px')};
`;
