import { AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Radio as CKRadio } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import ContentfulRichText from '@source-web/contentful-rich-text';
import YoutubeVideo from '@vfuk/core-youtube-video';
import Radio from '@vfuk/core-radio';

import { rem } from 'polished';
import { SWConfigureBundleOptionProps } from '../SWConfigureBundleOptions.types';
import BundleSecondaryOption from '../BundleSecondaryOption';

import {
  accordionPanelStyles,
  contentBoxStyles,
  infoTextBoxStyles,
  infoTextBoxImportantStyles,
  RadioWrapper,
  radioWrapperStyles,
  radioBoxStyles,
} from './BundleOption.styles';

interface IBundleOption {
  option: SWConfigureBundleOptionProps;
  sectionId: string;
  selectedOption: string;
  selectedSecondaryOption: string;
  setSelectedOption: (option: string) => void;
  setSelectedSecondaryOption: (option: string) => void;
}

const BundleOption = ({
  option,
  sectionId,
  selectedOption,
  selectedSecondaryOption,
  setSelectedOption,
  setSelectedSecondaryOption,
}: IBundleOption) => {
  const {
    id,
    contentful_id,
    name,
    optionTitle,
    secondaryOptions,
    videoId,
    content,
    infoTextIsImportant,
    infoText,
    tooltipText,
  } = option;

  const optionId = `${id}-${contentful_id}`;

  return (
    <AccordionItem key={optionId} borderWidth="unset">
      <AccordionButton textAlign="left" px={0} py={0} borderRadius="md" data-selector="bundle options">
        <RadioWrapper onClick={() => setSelectedOption(optionId)} {...radioWrapperStyles(optionId, selectedOption)}>
          <CKRadio
            as={Radio}
            groupName={sectionId}
            value={optionId}
            checked={selectedOption === optionId}
            alignItems="flex-start"
            spacing={{ base: 4, md: 7 }}
          >
            <Box as="span" {...radioBoxStyles}>
              {documentToReactComponents(JSON.parse(optionTitle.raw))}
            </Box>
          </CKRadio>
        </RadioWrapper>
      </AccordionButton>

      {(secondaryOptions || videoId || content || infoText) && (
        <AccordionPanel {...accordionPanelStyles}>
          {secondaryOptions && (
            <BundleSecondaryOption
              secondaryOptions={secondaryOptions}
              tooltipText={tooltipText}
              selectedSecondaryOption={selectedSecondaryOption}
              setSecondaryOption={setSelectedSecondaryOption}
            />
          )}

          {videoId && (
            <Box mb={{ base: 3, md: rem('18px') }}>
              <YoutubeVideo title={name} videoId={videoId} />
            </Box>
          )}

          {content && (
            <Box {...contentBoxStyles}>
              <ContentfulRichText document={JSON.parse(content.raw)} />
            </Box>
          )}

          {infoText && (
            <Flex
              sx={{
                p: {
                  lineHeight: { base: rem('18px') },
                },
              }}
            >
              <Box mr={{ base: rem('9px'), md: 3 }} visibility={infoTextIsImportant ? 'visible' : 'hidden'}>
                <Box as="span" {...infoTextBoxImportantStyles}>
                  *
                </Box>
              </Box>
              <Box {...infoTextBoxStyles}>
                <ContentfulRichText document={JSON.parse(infoText.raw)} />
              </Box>
            </Flex>
          )}
        </AccordionPanel>
      )}
    </AccordionItem>
  );
};

export default BundleOption;
