import { useState, useEffect, useContext } from 'react';
import { FormEvents, IFormInput, FormEventChange } from '@/components/pageBuilder/Elements/Form/Form.types';
import axios from 'axios';
import { getAddress, getCounties, County, GetAddressType, GetAddressResponse } from '@/services/address.services';
import { generateDays } from './SelectOptions';
import { FormStateContext } from './FormContext';
import { current } from '@reduxjs/toolkit';

const customAddressNames = ['address1', 'address2', 'postcode', 'city', 'stateOrProvince'];
const autoAddressNames = ['AddressOption', 'address'];

export const useFormHandler = (formType: 'contact' | 'company' | 'billing') => {
  const context = useContext(FormStateContext);
  if (!context) {
    throw new Error('useFormHandler must be used within a FormStateProvider');
  }

  const { contactForm, companyForm, billingForm, setContactForm, setCompanyForm, setBillingForm } = context;

  const getInitialFormConfig = () => {
    switch (formType) {
      case 'company':
        return companyForm;
      case 'contact':
        return contactForm;
      case 'billing':
        return billingForm;
      default:
        return contactForm;
    }
  };

  const [form, setForm] = useState<IFormInput[]>(getInitialFormConfig());
  let currentForm = form;
  const [year, setYear] = useState<string>();
  const [month, setMonth] = useState<string>();
  const [addressOption, setAddressOption] = useState<GetAddressType>();
  const [addressSearchValue, setAddressSearchValue] = useState<string>('');

  useEffect(() => {
    if (formType === 'contact') {
      setContactForm(form);
    } else if (formType === 'company') {
      setCompanyForm(form);
    } else if (formType === 'billing') {
      setBillingForm(form);
    }
  }, [form, formType, setContactForm, setCompanyForm, setBillingForm]);

  const handleInputChange = (e: FormEvents) => {
    const { name, value } = e.target;
    if (name === 'Enter your address manually') {
      const checked = (e.target as FormEventChange<HTMLInputElement>['target']).checked;
      toggleAddressFields(checked);
      if (checked && value === 'addressManually') {
        cleanCustomAddressFields();
        toggleAddressFields(false, 'custom');
      }
      if (value === 'sameAsContact') {
        if (checked) {
          copyAddressFromContactForm();
        } else {
          // Need to identify bug origin, source-web or configuration from our side.
          // When the inputfield has value and is disabled, when enabled back the user is blocked from typing.
          // cleanCustomAddressFields(true);
        }
      }
    } else if (name === 'Country') {
      fillCountiesOptions(value);
    } else if (name === 'Date of birth') {
      const selectedYear = value;
      setYear(selectedYear);
      if (month) {
        updateDays(selectedYear, month);
      }
    } else if (name === 'month') {
      const selectedMonth = value;
      setMonth(selectedMonth);
      updateDays(year || '2024', selectedMonth);
    } else if (name === 'AddressOption') {
      setAddressOption(value as GetAddressType);
      if (addressSearchValue.length > 3 && value) {
        getAddressValues(addressSearchValue, value as GetAddressType);
      }
    }

    if (name === 'Type of Business') {
      const selfEmployedInputName = 'companyRegistrationNumber';

      if (value === 'selfEmployed') {
        const updatedForm = currentForm.map(input => {
          if (input.name == selfEmployedInputName) {
            return {
              ...input,
              hidden: false,
              requiredField: true,
            };
          } else if (input.name == 'typeOfBusiness') {
            return {
              ...input,
              customValue: value,
            };
          }
          return input;
        });
        currentForm = updatedForm;
      } else if (value !== 'selfEmployed') {
        const updatedForm = currentForm.map(input => {
          if (input.name == selfEmployedInputName) {
            return {
              ...input,
              hidden: true,
              requiredField: false,
            };
          } else if (input.name == 'typeOfBusiness') {
            return {
              ...input,
              customValue: value,
            };
          }
          return input;
        });
        currentForm = updatedForm;
      }
    }
    setForm(currentForm);
  };

  const handleInputBlur = async (e: FormEvents) => {
    const { name, value } = e.target;
    setForm(prevForm =>
      prevForm.map(input => (input.name === name || input.label === name ? { ...input, customValue: value } : input))
    );
    if (e.target.name === 'Address') {
      const value: string = e.target.value;
      setAddressSearchValue(value);
      if (value.length > 3 && addressOption) {
        getAddressValues(value, addressOption);
      }
    }
  };

  const updateDays = (selectedYear: string, selectedMonth: string) => {
    const days = generateDays(parseInt(selectedMonth), parseInt(selectedYear));
    const updatedForm = currentForm.map(input => {
      if (input.name === 'daySelect') {
        return { ...input, options: days };
      }
      return input;
    });
    currentForm = updatedForm;
  };

  const toggleAddressFields = (disabled: boolean, addressType: 'auto' | 'custom' = 'auto') => {
    const addressToUse = addressType === 'auto' ? autoAddressNames : customAddressNames;
    const updatedForm = currentForm.map(input => {
      if (addressToUse.includes(input.name)) {
        return { ...input, disabled: disabled };
      }
      return input;
    });

    currentForm = updatedForm;
  };

  const cleanCustomAddressFields = (enable: boolean = false) => {
    const updatedForm = currentForm.map(input => {
      if (customAddressNames.includes(input.name) && input.name != 'stateOrProvince') {
        return { ...input, customValue: '', disabled: enable ? false : input.disabled };
      }
      return input;
    });
    currentForm = updatedForm;
  };

  const getAddressValues = async (addressValue: string, addressType: GetAddressType) => {
    toggleAddressFields(true);
    const res = await getAddress(addressValue, addressType);
    toggleAddressFields(false);
    if (!axios.isAxiosError(res) && res.status === 200) {
      const address = res.data[0];
      const updatedForm = currentForm.map(input => {
        if (customAddressNames.includes(input.name)) {
          const newValue = address[input.name as keyof GetAddressResponse];
          const newOptions = (input.options && [...input.options, { text: newValue, value: newValue }]) || [
            {
              text: newValue,
              value: newValue,
            },
          ];
          return {
            ...input,
            customValue: newValue,
            options: newOptions,
          };
        }
        return input;
      });
      currentForm = updatedForm;
    }
  };

  const fillCountiesOptions = async (countryCode: string) => {
    setFieldDisableStatus('stateOrProvince', true);
    const res = await getCounties(countryCode);
    setFieldDisableStatus('stateOrProvince', false);

    if (!axios.isAxiosError(res) && res.status === 200) {
      const counties: County[] = res.data;
      const updatedForm = currentForm.map(input => {
        if (input.name === 'stateOrProvince' && counties.length > 0) {
          return {
            ...input,
            options: [
              ...counties.map(county => {
                return { text: county.name, value: county.name };
              }),
            ],
            customValue: counties[0].name,
          };
        }
        return input;
      });
      currentForm = updatedForm;
    }
    setForm(currentForm);
  };

  const setFieldDisableStatus = (fieldName: string, disabled: boolean) => {
    const updatedForm = currentForm.map(input => {
      if (input.name === fieldName) {
        return {
          ...input,
          disabled,
        };
      }
      return input;
    });
    currentForm = updatedForm;
  };

  const copyAddressFromContactForm = () => {
    const updatedForm = currentForm.map(input => {
      if (customAddressNames.includes(input.name)) {
        const contactInput = contactForm.find(contactInput => contactInput.name === input.name);
        return contactInput ? { ...input, customValue: contactInput.customValue, disabled: false } : input;
      }
      return input;
    });
    currentForm = updatedForm;
  };

  useEffect(() => {
    const countryInput = form.find(input => input.name === 'countryCode');
    if (countryInput && countryInput.options?.length) {
      fillCountiesOptions(countryInput.options[0].value);
    }
  }, []);

  return {
    form,
    handleInputChange,
    handleInputBlur,
  };
};
