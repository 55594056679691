import styled from '@emotion/styled';
import { getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';
import { SWGuidedSellingBannerProps } from '../types/SWGuidedSellingBanner.types';

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey);
  padding: ${rem('30px')} 0;


  @media (max-width: 440px) {

    padding: 0;
  }
`;

export const GSButton = styled.div<{ customIndex?: number }>`
  display: flex;
  position: absolute;
  margin-top: ${rem('-120px')};
  margin-left: ${rem('1040px')};
  width: 'max-content';

  z-index: ${({ customIndex }) => customIndex || 1};

  @media (max-width: 1439px) {
    margin-left: ${rem('850px')};
  }

  @media (max-width: 1120px) {
    margin-left: ${rem('750px')};
  }


  @media (max-width: 820px) {
    margin-left: ${rem('600px')};
  }

  @media (max-width: 768px) {
    margin-left: ${rem('575px')};
  }

  @media (max-width: 440px) {
    margin: calc(74vw - 50vw);
    margin-top: ${rem('-64px')};
  }

  @media (max-width: 320px) {
    margin: calc(70vw - 50vw);
    margin-top: ${rem('-64px')};
  }

  @media (max-width: 320px) {
    margin: calc(70vw - 50vw);

    margin-top: ${rem('-64px')};
  }
`;

export const GuidedSellingBannerWrapper = styled.div<SWGuidedSellingBannerProps>`
  width: ${rem('1250px')};

  @media (max-width: 1439px) {
    width: 65rem;
  }

  @media (max-width: 1120px) {
    width: 90vw;
  }

  @media (max-width: 1020px) {
    width: 95vw;
  }


  @media (max-width: 820px) {
    width: 98vw;
  }

  @media (max-width: 440px) {

    width: 90vw;
  }

  margin: 2rem auto;
  h2[size='3'] {
    color: ${({ backgroundColor }) => backgroundColor};
    font-size: ${rem('40px')};
    line-height: ${rem('46px')};
    margin-bottom: ${rem('16px')};

    @media (max-width: 1439px) {
      font-size: ${rem('38px')};
    }

    @media (max-width: 1120px) {
      font-size: ${rem('34px')};
    }

    @media (max-width: 1020px) {

      margin-left: ${rem('-70px')};
      font-size: ${rem('34px')};
    }

    @media (max-width: 820px) {
      margin-left: ${rem('90px')};
      font-size: ${rem('30px')};
      width: 60vw;
    }

    @media (max-width: 768px) {
      margin-left: ${rem('120px')};
      font-size: ${rem('25px')};
      font-weight: 700;
    }

    @media (max-width: 440px) {
      margin: 0 0 ${rem('12px')} 0;
      font-size: ${rem('28px')};
      line-height: ${rem('32px')};
      max-width: max-content;
      width: 80vw;

    }

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      text-align: center;
      margin-top: ${rem('80px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: ${rem('1px')};
    }

    @media (max-width: 320px) {
      text-align: center;
      margin-top: ${rem('50px')};
    }
  }

  p {
    font-size: ${rem('20px')};
    line-height: ${rem('24px')};
    font-family: VodafoneLight;
    width: ${rem('820px')};
    color: ${({ backgroundColor }) => backgroundColor};
    margin-top: 1rem;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      text-align: center;
    }
    @media (max-width: 1439px) {
      width: 40rem;
    }
    @media (max-width: 1120px) {
      width: 35rem;
    }

    @media (max-width: 1020px) {

      margin-left: ${rem('-70px')};
    }

    @media (max-width: 820px) {
      margin-left: ${rem('90px')};
      font-size: ${rem('18px')};
      width: 55vw;
    }

    @media (max-width: 768px) {
      margin-left: ${rem('120px')};
      font-size: ${rem('16px')};
      width: 53vw;
    }

    @media (max-width: 440px) {
      margin: 0 auto;
      font-size: ${rem('18px')};
      line-height: ${rem('26px')};
      width: 75vw;

    }
  }

  [class*='FiftyFiftyBannerstyle__FiftyFiftyBanner'] {
    overflow: hidden;
    background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${getSrc(backgroundImage)})` : 'none')};
    background-size: contain;
    background-color: transparent;
    background-repeat: round;

    @media (max-width: 768px) {
      background-size: inherit;
      background-repeat: round;
    }

    @media (max-width: 440px) {

      background-color: white;
      background-repeat: round;
      display: flex;
      align-items: center;
      margin: auto;
      background-image: ${({ backgroundImageMobileView }) =>
        backgroundImageMobileView ? `url(${getSrc(backgroundImageMobileView)})` : 'none'};
    }

    @media (max-width: 320px) {
      background-size: contain;
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      min-height: ${rem('200px')};
      flex-direction: ${({ bodyPosition }) => {
        if (bodyPosition === 'left') {
          return 'row';
        } else if (bodyPosition === 'right') {
          return 'row-reverse';
        } else {
          return 'row';
        }
      }};
    }

    > [class*='BannerContent'] {
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        justify-content: ${({ bodyPosition }) => (bodyPosition === 'right' ? 'flex-start' : 'flex-end')};
        transform: ${({ bodyPosition }) => (bodyPosition === 'right' ? 'skewX(-22.7deg)' : 'skewX(22.7deg)')};
        z-index: 2;
        margin-right: ${({ bodyPosition }) => (bodyPosition === 'right' ? '0' : '-5.063rem')};
        margin-left: ${({ bodyPosition }) => (bodyPosition === 'right' ? '-0.063rem' : '0')};
        width: ${({ bodyPosition }) => (bodyPosition === 'right' ? '20%' : '25%')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin-right: ${({ bodyPosition }) => (bodyPosition === 'right' ? '-3rem' : '-5.063rem')};
        margin-left: ${({ bodyPosition }) => (bodyPosition === 'right' ? '-5.063rem' : '0')};
        width: 100%;
      }
    }

    > [class*='BannerContent'] {
      > [class*='ContentWrapper'] {
        left: ${rem('50px')};
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          align-self: stretch;
          margin: ${rem('24px')} 0 ${rem('32px')};

          [class*='BannerBody'] {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          width: ${rem('1200px')};
          margin: ${({ bodyPosition }) => (bodyPosition === 'right' ? '0 3rem 0 0' : '0 2rem 0 0')};
          padding: ${({ bodyPosition }) => (bodyPosition === 'right' ? '0 1rem 0 1rem' : '0 0 0 1rem')};
          transform: ${({ bodyPosition }) => (bodyPosition === 'right' ? 'skewX(22.7deg)' : 'skewX(-22.7deg)')};
          position: ${({ bodyPosition }) => (bodyPosition === 'right' ? 'relative' : '')};
          left: ${rem('-40px')};
        }
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          padding: 0;

          width: ${rem('1200px')};
          position: unset;
        }

        > [class*='BannerBodystyle__BannerBody'] {
          display: flex;
          > [class*='BannerContent'] {
            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              width: 90vw;
              margin-bottom: ${rem('50px')};
            }
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              margin-bottom: ${rem('23px')};
              margin-right: ${rem('20px')};
            }
          }

          > [class*='BannerButtons'] {
            margin-top: ${rem('40px')};
            width: max-content;

            @media (max-width: 440px) {
              margin-top: ${rem('-30px')};
            }

            a {
              font-size: ${rem('18px')};
              width: max-content;
              border: 0;
              height: ${rem('50px')};
              background-color: ${({ backgroundColor }) => backgroundColor};
              color: ${({ primaryButton }) => primaryButton && primaryButton};
            }
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              width: ${rem('0px')};
            }
          }
        }
      }
    }

    > [class*='BannerImage'] {
      width: 300px !important;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        min-height: ${rem('20px')};
        width: -900px !important;
      }
    }

    > [class*='BannerContent'] {
      @media (max-width: 1024px) {
        width: ${rem('750px')};
      }
    }

    > [class*='BannerContent'],
    > [class*='BannerImage'] {
      padding: 0;

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        min-height: ${rem('50px')};
        height: 200px;
      }
    }

    [class*='Interactionstyle__Interaction'] {
      font-size: ${rem('20px')};
      line-height: ${rem('18px')};
      padding: ${rem('24px')} ${rem('45px')};
      top: ${rem('3px')};
    }
  }

  &.vhubKnowledgeCentreBanner {
    [class*='FiftyFiftyBannerstyle__FiftyFiftyBanner']
      > [class*='BannerContent']
      > [class*='ContentWrapper']
      > [class*='BannerBodystyle__BannerBody']
      > [class*='BannerButtons'] {
      width: fit-content;

      a {
        padding: ${rem('24px')};
      }
    }
  }

  &.centreBanner {
    width: 100%;
    background: linear-gradient(
      #f4f4f4 0%,
      #f4f4f4 50%,
      ${({ theme }) => theme.color.primary2.default} 50%,
      ${({ theme }) => theme.color.primary2.default} 100%
    );

    [class*='FiftyFiftyBannerstyle__FiftyFiftyBanner'] {
      width: 100%;
      max-width: ${rem('1180px')};
      margin: 0 auto;
      border-radius: ${rem('6px')};
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        border-radius: unset;
      }

      > [class*='BannerContent'] {
        width: 50%;
        transform: none;

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          margin: 0;
        }

        h2,
        p {
          text-align: left;

          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            text-align: center;
          }
        }

        h2 {
          font-family: 'VodafoneRegular';
          margin-bottom: ${rem('1px')};
          padding: 0;
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          width: 100%;
        }

        > [class*='ContentWrapper'] {
          transform: none;
          margin: ${rem('64px')} ${rem('132px')} ${rem('64px')} ${rem('56px')};

          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            margin: ${rem('24px')} 0 ${rem('32px')};
          }
        }
      }
    }
  }
`;
