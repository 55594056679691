import { Box } from '@chakra-ui/react';
import { Icon } from '@source-web/icon';
import Button from '@vfuk/core-button';
import SimpleGrid from '@vfuk/core-simple-grid';
import Spacing from '@vfuk/core-spacing';
import { Span } from '@vfuk/core-span';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockedListErrorCard, BlockedListErrorWrapper } from './BlockedListErrors.styles';
import { BlockedListErrorsList, BlockedListErrorsProps, ErrorsBlockedList } from './BlockedListErrors.types';
import { navigate } from 'gatsby';
import usingOpcoUrl from '@/helpers/prefixHelper';

const errorList: BlockedListErrorsList[] = [
  {
    id: 'no-account',
    code: ErrorsBlockedList.ENTITY_NOT_FOUND,
    title: 'billingPlataform.noAccounts.title',
    description: 'billingPlataform.noAccounts.description',
    buttonCTA: 'billingPlataform.noAccounts.buttonCTA',
    buttonRedirectURL: '/',
  },
  {
    id: 'blocklist',
    code: ErrorsBlockedList.ALL_ACCOUNTS_ON_BLOCKLIST,
    title: 'billingPlataform.blocklist.title',
    description: 'billingPlataform.blocklist.description',
    buttonCTA: 'billingPlataform.blocklist.buttonCTA',
    buttonRedirectURL: '/',
  },
];

const BlockedListErrors: FC<BlockedListErrorsProps> = ({ code }) => {
  const { t } = useTranslation();

  const getError = (error: BlockedListErrorsList) => {
    return error && error.code === (code as ErrorsBlockedList);
  };

  const blockedListError = errorList.find(getError);

  return (
    blockedListError && (
      <Box>
        <BlockedListErrorWrapper>
          <SimpleGrid
            columns={{
              sm: 1,
              md: 2,
            }}
            spacing={0}
            justify="center"
          >
            <BlockedListErrorCard>
              <Icon name="business" group="hifiLight" />
              <Span weight={3}>{t(blockedListError.title)}</Span>
              <Span>{t(blockedListError.description)}</Span>
            </BlockedListErrorCard>
          </SimpleGrid>
          <Spacing
            spacingLevel={{
              top: 8,
              right: 8,
              left: 8,
            }}
          >
            <SimpleGrid
              columns={{
                sm: 1,
                md: 3,
                lg: 5,
              }}
              spacing={4}
              justify="center"
            >
              <Button
                appearance="primary"
                width="full"
                text={t(blockedListError.buttonCTA)}
                onClick={() => {
                  blockedListError.buttonRedirectURL && usingOpcoUrl(blockedListError.buttonRedirectURL, true);
                }}
              />
            </SimpleGrid>
          </Spacing>
        </BlockedListErrorWrapper>
      </Box>
    )
  );
};

export default BlockedListErrors;
