import { graphql, useStaticQuery } from 'gatsby';

export const useSiteConfig = () => {
  const { contentfulSiteConfig } = useStaticQuery<Queries.SiteTitleQuery>(graphql`
    query SiteTitle {
      contentfulSiteConfig {
        favouriteIcon {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        language
        metaData {
          attributes {
            name
            value
          }
        }
        scripts {
          __typename
          ... on ContentfulSeoInlineScript {
            contentful_id
            scriptLocation
            script {
              script
            }
          }
          ... on ContentfulSeoExternalScript {
            contentful_id
            scriptLocation
            runType
            src
          }
          ... on ContentfulSeoPageChangeScript {
            contentful_id
            script {
              script
            }
          }
          ... on ContentfulSeoInteractionScript {
            contentful_id
            script {
              script
            }
          }
          ... on ContentfulDynamicHeightScript {
            contentful_id
            script {
              script
            }
          }
        }
      }
    }
  `);

  return contentfulSiteConfig;
};
