import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Wrapper = styled(Box)``;

export const TabsWrapper = styled(Box)`
  [class*='FunctionalTabsstyle__Tabs-sc'] {
    > [class*='Tabsstyle__TabListContainer-sc'] {
      display: none;
    }
  }
`;
