import styled from '@emotion/styled';
import { rem } from 'polished';

interface CardContainerProps {
  hasService: boolean;

  hasLabel?: boolean;
  isAddon?: boolean;
}

export const CardContainer = styled.div<CardContainerProps>`
  background-color: white;
  min-height: ${({ hasService }) => (hasService ? `${rem('889px')}` : `${rem('754spx')}`)};
  width: 100%;
  max-width: ${rem('340px')};
  padding: ${rem('20px')};
  box-shadow: 0 ${rem('2px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.16);
  position: relative;
  border-radius: ${rem('4px')};
  box-sizing: content-box;

  ${({ isAddon }) => {
    if (isAddon) {
      return `
        height: min-content;
        min-height: unset;
      `;
    }
  }}

  ${({ hasLabel }) => {
    if (hasLabel) {
      return `
        border-top-left-radius: 0;
        border: ${rem('1px')} solid #00697c;
      `;
    }
  }}


  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: ${rem('380px')};
  }

  [id*='buy-button'] {
    margin: ${rem('18px')} 0;
    max-width: ${rem('197px')};
  }
`;

export const Label = styled.div`
  position: absolute;
  font-weight: 700;
  padding: ${rem('6px')} ${rem('20px')};
  font-size: ${rem('18px')};
  background-color: #00697c;
  color: white;
  width: min-content;
  border-radius: ${rem('4px')} ${rem('4px')} 0 0;
  top: -${rem('1px')};
  margin-left: ${rem('-21px')};
  transform: translateY(-100%);
`;

export const IconDescriptionWrapper = styled.div<{ isAddon?: boolean }>`
  display: flex;
  gap: ${rem('2px')};
  flex-direction: column;
  height: min-content;
  min-height: ${({ isAddon }) => (isAddon ? `unset` : `${rem('174px')}`)};
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: ${rem('12px')};
  align-items: center;
  height: fit-content;

  img {
    object-fit: cover;
    height: fit-content;
  }

  p {
    width: fit-content;
    height: fit-content;
  }
`;
