import React from 'react';

import { addLinkTestId } from '../../../../helpers/addTestId';
import * as Styles from './PriceTag.styles';
import { PriceTagProps } from './PriceTag.types';

const PriceTag: React.FC<PriceTagProps> = ({ currencySymbol, price }: PriceTagProps) => {
  return (
    <Styles.PriceTagWrapper data-testid={addLinkTestId(`priceTag_${price}`, 'price tag')}>
      <p className="startingAt">Starting at</p>
      <p>
        <span className="currencySymbol">{currencySymbol}</span>
        <span className="price">{price}</span>
      </p>
      <div className="vat">
        <p>/ month (ex VAT)</p>
      </div>
    </Styles.PriceTagWrapper>
  );
};

export default PriceTag;
