import styled from 'styled-components';
import { rem } from 'polished';

const BasketItemDeleteStyles = styled.div`
  display: flex;
  align-items: center;
  > button {
    border: none;
    border-radius: ${rem('4px')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    grid-column: 2;
    grid-row: 2;
    justify-content: flex-end;
  }
`;

export default BasketItemDeleteStyles;
