import { useTranslation } from 'react-i18next';
import { Heading } from '@source-web/heading';
import formatCurrency from '@/helpers/formatCurrency';
import { NULL_PRICE_VALUE } from '../SWBasket.helper';

import BasketPriceStyles, { CurrencyPrice, HeadingPrice, LabelPrice, SuffixPrice } from './BasketPrice.styles';

interface BasketPriceProps {
  label: string;
  labelAlign?: 'left' | 'right' | 'center';
  value?: number | string;
}

const BasketPrice = ({ label, labelAlign, value }: BasketPriceProps) => {
  const { t } = useTranslation();

  const formatValuePrice = (value: number | string) => {
    const possiblyNumber = String(value).split('.').length > 1;
    const isNullValue = String(value) === NULL_PRICE_VALUE;

    if (possiblyNumber || !isNullValue) {
      const numberValue = typeof value === 'number' ? value : Number(value);
      return formatCurrency(numberValue);
    }

    return value;
  };

  return (
    <BasketPriceStyles>
      <LabelPrice labelAlign={labelAlign}>{label}</LabelPrice>
      <HeadingPrice>
        <Heading level={4} size={2} weight={3}>
          <CurrencyPrice>&euro;</CurrencyPrice>
          {value && formatValuePrice(value)}
          <SuffixPrice>{t('priceSuffix')}</SuffixPrice>
        </Heading>
      </HeadingPrice>
    </BasketPriceStyles>
  );
};

export default BasketPrice;
