import styled from '@emotion/styled';
import { rem } from 'polished';

type PortfolioCardWrapperProps = {
  withWrap?: boolean;
};

export const PortfolioCardWrapper = styled.div<PortfolioCardWrapperProps>`
  [class*='CardBuilder'] {
    [class*='Containerstyle__Container'] {
      box-shadow: none;
    }

    [class*='ContainerContent'] {
      background: var(--white);
      height: ${rem('396px')};
      > [class*='SlotsWrapper'] {
        > [class*='CardSlots']:nth-last-child(-n + 2) {
          position: absolute;
          height: 100%;
          width: 100%;
        }
        > [class*='CardSlots']:nth-last-child(1) {
          top: ${rem('130px')};
          height: 50%;

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
            top: ${rem('114px')};
          }
        }
        [class*='CardImageSlot'] {
          padding: 0;
        }
        [class*='CardDeviceNameAndRatingSlot'] {
          display: block;
          padding: 0;
          height: 100%;
          background: linear-gradient(270deg, rgba(51, 51, 51, 0) 30%, var(--mineShaft) 100%);
          > [class*='CopyContainer'] {
            margin: 0;
            padding: ${rem('20px')};
            h4 {
              width: 70%;
              font-size: ${rem('28px')};
              line-height: ${rem('33px')};
              color: ${({ theme }) => theme.color.primary2.default};
            }
          }
        }
        [class*='CardSandwichSlotstyle__CardSandwichSlot'] {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-end;
          align-items: flex-start;
          padding: ${rem('20px')};
          [class*='SandwichContainer'] {
            [class*='PriceWrapper'] > div > span {
              margin: 0;
              display: flex;
              flex-wrap: ${({ withWrap }) => (withWrap ? 'wrap' : 'nowrap')};
              align-items: baseline;
              span {
                color: ${({ theme }) => theme.color.primary2.default};
              }
              span:nth-of-type(1) {
                font-size: 1.5rem;
                align-self: baseline;
              }
              span:nth-of-type(2) {
                flex-basis: 90%;
                font-size: ${rem('46px')};
              }
              span:nth-of-type(3) {
                font-size: ${rem('12px')};
                line-height: ${rem('15px')};
                flex-basis: 45%;
                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
                  flex-basis: 42%;
                }
              }
            }
          }
          [class*='Paragraph'] {
            width: ${rem('176px')};
            height: ${rem('112px')};
            font-size: ${rem('16px')};
            line-height: ${rem('21px')};
            color: ${({ theme }) => theme.color.primary2.default};
          }
        }
      }
    }
  }
`;
