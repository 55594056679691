import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { SWBreadcrumbWrapper } from './SWBreadcrumb.types';

export const BreadcrumbWrapper = styled<SWBreadcrumbWrapper>(Box)`
  [class*='Breadcrumbsstyle__BreadcrumbsWrapper-sc'] {
    border: none;
    background: ${({ isBlackTheme }) => (isBlackTheme ? '#222222' : 'none')};

    [class*='ListItemstyle__ListItem-sc'] {
      span {
        ${({ isBlackTheme }) => isBlackTheme && 'color: white;'};

        font-size: ${rem('18px')};
      }
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      // font-size: ${rem('14px')};
      line-height: ${rem('18px')};
      padding: ${rem('19px')} 0 ${rem('19px')} ${rem('20px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
      line-height: inherit;
      font-size: inherit;
      margin: 0 auto;
      max-width: ${rem('1180px')};
      padding: ${rem('19px')} 0;
    }

    [class*='BreadcrumbsIcon'] {
      ${({ isBlackTheme }) => isBlackTheme && 'color: white;'};
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        margin: 0 ${rem('8px')};
        padding: 0;
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin: 0 ${rem('16px')};
      }
    }

    [class*='ListItemstyle'] {
      [class*='Interactionstyle'] {
        ${({ isBlackTheme }) => isBlackTheme && 'text-decoration: none;'};
      }
      [class*='InteractionListItem'] {
        letter-spacing: ${rem('-0.6px')};
      }
    }
    [class*='Iconstyle'] {
      svg {
        ${({ isBlackTheme }) => isBlackTheme && 'stroke: white;'};
      }
    }
    [class*='ListGroupstyle__ListGroup'] {
      &:last-child {
        font-weight: bold;
      }
    }
  }
`;
