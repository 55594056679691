import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  [class*='Containerstyle__Container'][class*='Interactionstyle__BlankInteraction'] {
    padding: ${rem('20px')};
    max-width: ${rem('345px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      margin-bottom: 0;
      max-width: ${rem('320px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
      max-width: ${rem('380px')};
      padding: ${rem('23px')} ${rem('30px')} ${rem('30px')};
    }

    [class*='Containerstyle__ContainerContent'] {
      [class*='CardBuilderstyle__CardBuilderInnerGridCompositionRoot-sc'] {
        min-height: ${rem('280px')};
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          min-height: ${rem('305px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
          min-height: ${rem('305px')};
        }

        [class*='CardSlots']:last-of-type {
          margin-top: auto;
        }

        [class*='CardHeadingAndIconSlotstyle__CardHeadingAndIconSlot'] {
          padding: 0;
          [class*='IconContainer'] {
            [class*='CardImageSlotstyle__CardImageSlot'] {
              width: ${rem('80px')};
              height: ${rem('80px')};
              padding: 0;

              &:before {
                background-size: contain;
              }
            }
          }

          [class*='HeadingContainer'] {
            margin-left: ${rem('26px')};

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              margin-left: ${rem('24px')};
            }

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
              margin-left: ${rem('29px')};
            }

            [class*='LinkContainer'] {
              a {
                text-decoration: underline;
                font-size: ${rem('28px')};
                line-height: ${rem('33px')};
                span {
                  @media only screen and (min-width: ${({ theme }) =>
                      theme.breakpoints.md + 1}px) and (max-width: 919px) {
                  }
                }
              }
            }
          }
        }

        [class*='CardIconSnippetSlotstyle__CardIconSnippetSlot'] {
          background-color: transparent;
          padding: 0;
          margin-top: ${rem('20px')};

          [class*='Iconstyle__Icon'] {
            display: none;
          }

          [class*='CardIconSnippetSlotstyle__ContentContainer'] {
            margin-top: 0;
            [class*='Paragraphstyle__Paragraph'] {
              color: var(--mineShaft);
              font-size: ${rem('16px')};
              line-height: ${rem('24px')};

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                text-align: center;
              }

              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                font-size: ${rem('18px')};
              }
            }
          }
        }

        [class*='CardPillAndIconSlotstyle__CardPillAndIconSlot'] {
          justify-content: normal;
          padding: 0;

          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            justify-content: center;
          }

          [class*='Pillstyle__Pill'] {
            border: ${rem('1px')} solid var(--DustyGray);
            color: var(--mineShaft);
            display: block;
            font-size: ${rem('16px')};
            font-weight: normal;
            padding: ${rem('8px')} ${rem('16px')} ${rem('11px')};
          }
        }
      }
    }
  }
`;
