import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Button from '@vfuk/core-button';
import RadioButtonList from '@vfuk/core-radio-button-list';
import SimpleGrid from '@vfuk/core-simple-grid';
import Spacing from '@vfuk/core-spacing';
import UtilityCard from '@vfuk/core-utility-card';
import { InteractionEvent } from '@vfuk/core-interaction';
import { RadioButtonListProps } from '@vfuk/core-radio-button-list/dist/RadioButtonList.types';

import usingOpcoUrl from '@/helpers/prefixHelper';
import { OPCOOptions } from '@/types/Services.types';
import { useModalContext } from '@/hooks/modal/useModal';
import { users } from '@/services/user.services';
import {
  setLoadingUserSession,
  setUserSession,
  useAppDispatch,
  useAppSelector,
  UserBlocklistError,
  UserSessionSliceProps,
} from '@/store';

import BlockedListErrors from '../../Elements/BlockedListErrors';
import { SWBillingAccountsProps } from './SWBillingAccounts.types';
import { ErrorsBlockedList } from '../../Elements/BlockedListErrors/BlockedListErrors.types';
import { BillingAccountsInner, BillingAccountsWrapper } from './SWBillingAccounts.styles';

const OPCO = process.env.GATSBY_OPCO;
const SWBillingAccounts = ({ iconCard }: SWBillingAccountsProps) => {
  const dispatch = useAppDispatch();
  const { user, isLoading, error, redirectPath } = useAppSelector(state => state.userSession);
  const { toggleModal, setErrorInfo } = useModalContext();

  const accountItemPrefix = iconCard?.listItemPrefix ? `${iconCard.listItemPrefix}:` : '';

  const accountItems = user?.accounts.map(account => {
    const accountId = OPCO === OPCOOptions.PORTUGAL ? account.customerId : account.customerAccountId;
    return {
      label: `${accountItemPrefix} ${accountId}`,
      value: account.customerAccountId,
    };
  }) as RadioButtonListProps['radioButtons']['items'];

  const [selectedOption, setSelectedOption] = useState<string | undefined>();

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = async (event: InteractionEvent) => {
    event.preventDefault();
    handleAccountAction();
  };

  const handleAccountAction = async () => {
    dispatch(setLoadingUserSession(true));
    const accountInfo = user?.accounts.find(account => account.customerAccountId === selectedOption);

    if (accountInfo) {
      dispatch(setUserSession({ isLoading: true, error: null }));

      const res = await users({
        customerAccountId: accountInfo.customerAccountId,
        customerId: accountInfo.customerId,
        companyName: accountInfo.companyName,
        barId: accountInfo.barId,
        segment: accountInfo.segment,
      });

      if (!axios.isAxiosError(res) && res.status === 200) {
        dispatch(
          setUserSession({
            user: {
              ...user,
              ...res.data,
              selectedAccount: accountInfo,
            },
            isLoading: false,
            error: null,
          })
        );
        usingOpcoUrl(redirectPath, true);
      } else {
        dispatch(
          setUserSession({
            isLoading: false,
            ...(axios.isAxiosError(res) && { error: res.toJSON() as UserSessionSliceProps['error'] }),
          })
        );
        if (axios.isAxiosError(res)) {
          setErrorInfo({
            statusType: res.response?.data?.error,
            statusCode: res.response?.data?.code || res.response?.status,
            requestUrl: 'users',
          });
        }
        toggleModal(true, 'Error');
      }
    }
  };

  const validateLoggedUser = () => {
    if (!isLoading && !error && !user?.accounts) {
      toggleModal(true, 'Login');
    } else if (!isLoading && error && !user?.organizationId) {
      if (
        (error as UserBlocklistError).errorCode === ErrorsBlockedList.ALL_ACCOUNTS_ON_BLOCKLIST ||
        (error as UserBlocklistError).errorCode === ErrorsBlockedList.ENTITY_NOT_FOUND
      ) {
        toggleModal(false, 'Login');
        toggleModal(false, 'Error');
      } else {
        toggleModal(true, 'Error');
      }
    } else if (!isLoading && !error && user?.accounts) {
      toggleModal(false, 'Login');
      toggleModal(false, 'Error');

      if (user.accounts.length === 1) {
        setSelectedOption(user.accounts[0].customerAccountId);
      }
    }
  };

  useEffect(() => {
    validateLoggedUser();
  }, [user, isLoading, error]);

  return (
    (!isLoading && user && user?.accounts.length > 0 && (
      <BillingAccountsWrapper>
        <BillingAccountsInner>
          <SimpleGrid
            columns={{
              sm: 1,
            }}
            spacing={0}
            justify="center"
          >
            <UtilityCard
              heading={{ text: iconCard.headingText }}
              icon={{ name: iconCard.iconName }}
              text={iconCard.text}
            />
            {user?.accounts && user.accounts.length >= 1 && (
              <RadioButtonList
                fieldWrapper={{
                  label: '',
                  showLabel: false,
                }}
                radioButtons={{
                  groupName: 'Preselected',
                  id: 'preselected',
                  items: accountItems.map(item =>
                    !error
                      ? {
                          ...item,
                          dataAttributes: {
                            'data-selector': `multiple account-${iconCard.headingText.toLowerCase()}`,
                          },
                        }
                      : {
                          ...item,
                          state: 'disabled',
                          htmlAttributes: {
                            dataAttributes: {
                              disabled: true,
                            },
                          },
                        }
                  ),
                  checked: selectedOption,
                  onChange: handleOptionChange,
                }}
              />
            )}
          </SimpleGrid>
          <Spacing
            spacingLevel={{
              top: 8,
              right: 8,
              left: 8,
            }}
          >
            <SimpleGrid
              columns={{
                sm: 1,
                md: 3,
                lg: 5,
              }}
              spacing={4}
              justify="center"
            >
              {user?.accounts && user.accounts.length > 1 && iconCard?.secondaryButton?.label && (
                <Button
                  text={iconCard.secondaryButton.label}
                  appearance="alt1"
                  href={usingOpcoUrl(iconCard.secondaryButton.url)}
                  width="full"
                  dataSelectorPrefix={`multiple account-${iconCard.headingText.toLowerCase()}`}
                />
              )}
              <Button
                {...(!selectedOption && {
                  state: 'disabled',
                })}
                appearance="primary"
                text={iconCard.primaryButton.label}
                width="full"
                onClick={handleButtonClick}
                dataSelectorPrefix={`multiple account-${iconCard.headingText.toLowerCase()}`}
              />
            </SimpleGrid>
          </Spacing>
        </BillingAccountsInner>
      </BillingAccountsWrapper>
    )) ||
    (!isLoading && error && <BlockedListErrors code={(error as UserBlocklistError).errorCode} />)
  );
};

export default SWBillingAccounts;
